import {
  type ChangeEvent,
  type ComponentProps,
  type FocusEvent,
  type ReactNode,
  useState,
} from 'react'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Checkbox } from 'bl-common/src/form/Checkbox/Checkbox'
import { StyledInput } from 'bl-common/src/form/Input/Input'
import { ErrorMessage } from 'bl-common/src/units/Form/styles'

import {
  CheckboxContainer,
  CheckboxSectionContainer,
  InputGroup,
  Label,
  TopWrapper,
} from './styles'
import { formatTextWithLines, hasErrors } from './utils'

type ValidateInputProps = ComponentProps<typeof StyledInput> & {
  validate?: (value: unknown) => void
  error: unknown
  labelText: ReactNode
  submitted: unknown
}

const useTouched = (validate?: (value: unknown) => void) => {
  const [touched, setTouched] = useState(false)

  const triggerValidation = (event: ChangeEvent<HTMLInputElement>) => {
    if (validate) {
      validate(event.target)
    }
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    triggerValidation(event)
    setTouched(true)
  }

  return { touched, triggerValidation, handleBlur }
}

const renderErrors = (
  error: unknown,
  alignment: 'left' | 'right' = 'right'
) => (
  <ErrorMessage style={{ textAlign: alignment }}>
    {error &&
      Object.keys(error).map((e, i) => (
        <span key={`error-${i}`}>{error[e]}</span>
      ))}
  </ErrorMessage>
)

export const Input = ({
  labelText,
  error,
  submitted,
  validate,
  ...props
}: ValidateInputProps) => {
  const { touched, triggerValidation, handleBlur } = useTouched(validate)

  return (
    <InputGroup>
      <TopWrapper>
        <Label as="label" htmlFor={props.id} size={{ xs: 14, md: 14 }}>
          {labelText}
        </Label>
        {hasErrors(error, submitted, touched) && renderErrors(error)}
      </TopWrapper>
      <StyledInput
        className={hasErrors(error, submitted, touched) ? 'error' : ''}
        onChange={triggerValidation}
        onBlur={handleBlur}
        type={props.type || 'text'}
        hasError={hasErrors(error, submitted, touched)}
        {...props}
        required
      />
    </InputGroup>
  )
}

export const CheckboxInput = ({
  id,
  error,
  submitted,
  labelText,
}: ValidateInputProps) => {
  const { touched, triggerValidation } = useTouched()

  return (
    <CheckboxSectionContainer>
      {hasErrors(error, submitted, touched) && renderErrors(error, 'left')}
      <CheckboxContainer>
        <Checkbox
          id={id}
          name={id}
          error={hasErrors(error, submitted, touched)}
          onChange={triggerValidation}
          fillWidth={false}
          required
        >
          <Type
            as="span"
            preset="text"
            dangerouslySetInnerHTML={{
              __html: formatTextWithLines(labelText),
            }}
          />
        </Checkbox>
      </CheckboxContainer>
    </CheckboxSectionContainer>
  )
}
