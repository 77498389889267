import { math } from 'polished'
import styled, { css } from 'styled-components'
import { map } from 'styled-components-breakpoint'
import Grid from 'styled-components-grid'

import { gutter } from '../constants/sizes'

type BreakpointValues<T> = T | { [name: string]: T }

function offset({
  $offsetLeft,
  $offsetRight,
}: {
  $offsetLeft?: BreakpointValues<number>
  $offsetRight?: BreakpointValues<number>
}) {
  return css`
    ${map($offsetRight, (value = 0) => {
      const pct =
        Math.round((typeof value === 'number' ? value : 1) * 100 * 10000) /
        10000 //round to 4 decimal places
      return `
          margin-right: ${pct}%;
        `
    })}
    ${map($offsetLeft, (value = 0) => {
      const pct =
        Math.round((typeof value === 'number' ? value : 1) * 100 * 10000) /
        10000 //round to 4 decimal places
      return `
          margin-left: ${pct}%;
        `
    })}
  `
}

export const Column = styled(Grid.Unit)`
  padding-left: ${math(`${gutter}px / 2`)};
  padding-right: ${math(`${gutter}px / 2`)};
  ${props => offset(props)}
`
