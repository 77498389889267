import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { fromZonedTime } from 'date-fns-tz'
import styled from 'styled-components'

import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { Input } from '../Input/Input'
import { CalendarIcon } from './Icon'

const DynamicCalendar = dynamic(() => import('../../units/Calendar/Calendar'), {
  ssr: false,
})

type DatepickerProps = {
  showCalendar?: boolean
  handleDismiss?: () => any
  onFocus?: () => any
  onChange?: (props?: any) => any
  minDate?: any
  maxDate?: any
  dateFormat?: string
  placeholder?: string
  value?: any
  hasError?: boolean
  name?: string
  id?: string
  isDateDisabled?: any
}

const Container = styled.div`
  position: relative;
  width: 100%;
`

const NOOP = () => {
  /* intentionally empty */
}
const DATE_FORMAT = 'dd-MM-yyyy'

export const Datepicker = ({
  showCalendar,
  handleDismiss = NOOP,
  onFocus = NOOP,
  onChange = NOOP,
  minDate,
  maxDate,
  dateFormat = DATE_FORMAT,
  placeholder = 'Pick a date',
  value,
  hasError,
  name,
  id,
  isDateDisabled,
}: DatepickerProps) => {
  const handleKeyDown = e => {
    if (e.key === 'Escape') {
      handleDismiss()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Container>
      <Input
        id={id}
        name={name}
        type="text"
        onFocus={onFocus}
        value={value}
        placeholder={placeholder}
        hasError={hasError}
        disableEditing
        autoComplete="off"
      />
      <DynamicCalendar
        onChange={(date: Date | string) => {
          if (date instanceof Date) {
            const parsedDate = new Date(date)
            const utcDate = fromZonedTime(parsedDate, 'UTC')
            onChange(formatDateInUTC(utcDate, dateFormat))
          } else {
            onChange(date)
          }
        }}
        isVisible={showCalendar}
        onClose={handleDismiss}
        minDate={
          minDate
            ? fromZonedTime(formatDateInUTC(new Date(minDate)), 'UTC')
            : undefined
        }
        maxDate={
          maxDate
            ? fromZonedTime(formatDateInUTC(new Date(maxDate)), 'UTC')
            : undefined
        }
        isDateDisabled={isDateDisabled}
      />
      <CalendarIcon />
    </Container>
  )
}
