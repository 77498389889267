/* eslint-disable @typescript-eslint/unbound-method */
import { PureComponent, type ReactElement } from 'react'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { media } from 'bl-common/src/utils/media'

type HeroTextScrollerProps = {
  sentenceDuration?: number
  sentences?: string[]
  color?: string
  children(hascompleted?: boolean): ReactElement
}

type HeroTextScrollerState = {
  hasCompleted?: boolean
  activeIndex?: number
  loopCount?: number
}

const Container = styled.div`
  align-items: center;
  color: ${({ color }) => color || colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  li {
    opacity: 0;
    position: absolute;
    text-align: center;
    max-width: 85%;
    left: 0;
    right: 0;
    opacity: 0;
    top: 0;
    bottom: 50vh;
    height: 1ch;
    margin: auto;
    transition:
      transform 1000ms ease-in-out,
      opacity 500ms ease;

    ${media.md(css`
      bottom: 33vh;
    `)};

    &.shouldAnimate {
      animation: textScroll 3000ms;
    }

    @keyframes textScroll {
      0% {
        opacity: 0;
        transform: translateY(100%);
      }
      40% {
        opacity: 1;
        transform: translateY(0%);
      }
      50% {
        opacity: 1;
        transform: translateY(0%);
      }
      100% {
        opacity: 0;
        transform: translateY(-100%);
      }
    }
  }
`

export class HeroTextScroller extends PureComponent<
  HeroTextScrollerProps,
  HeroTextScrollerState
> {
  constructor(props) {
    super(props)
    this.state = {
      loopCount: 0,
      activeIndex: 0,
    }
    this.handleAnimate = this.handleAnimate.bind(this)
  }

  handleAnimate() {
    const { sentences = ['Experience the Wonder', 'Blue Lagoon Iceland'] } =
      this.props

    this.setState(({ activeIndex, loopCount }) => ({
      activeIndex: (activeIndex + 1) % sentences.length,
      loopCount: loopCount + 1,
      hasCompleted: loopCount >= 1,
    }))
  }

  render() {
    const { hasCompleted, activeIndex } = this.state
    const {
      sentences = ['Experience the Wonder', 'Blue Lagoon Iceland'],
      children,
      color,
    } = this.props

    return (
      <Appear styleChild>
        <Container color={color}>
          <Type
            as="ul"
            onAnimationEnd={this.handleAnimate}
            size={{ xs: 36, md: 50 }}
            style={{
              textShadow: '4px 2px 12px rgba(0,0,0,0.16)',
            }}
          >
            {sentences.map((sentence, index) => {
              return (
                <li
                  key={sentence}
                  className={index === activeIndex ? 'shouldAnimate' : ''}
                >
                  {sentence}
                </li>
              )
            })}
          </Type>
          {children?.(hasCompleted)}
        </Container>
      </Appear>
    )
  }
}

export default HeroTextScroller
