import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'next/router'
import { AnimatePresence, motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import type { INavigationItemFields } from 'contentful-shared'
import {
  getMyBookingLink,
  myBookingUrl,
} from 'bl-utils/src/routing/getMyBookingLink'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { LinkArrow } from '../../elements/Arrow/Arrow'
import { Button } from '../../elements/Button/Button'
import { BagIcon } from '../../elements/Icons/BagIcon'
import { CloseIcon } from '../../elements/Icons/CloseIcon'
import { PersonIcon } from '../../elements/Icons/PersonIcon'
import { Link, NavLink } from '../../elements/Link'
import { Type } from '../../elements/Typography/Typography'
import { Drawer } from '../../units/Drawer'
import { media, mediaMax } from '../../utils/media'
import DrawerNav from './DrawerNav'
import { Logo } from './Logo'
import { PortalNav } from './PortalNav'

function getIcon(icon) {
  switch (icon) {
    case 'Person':
      return PersonIcon
    case 'Bag':
      return BagIcon
    default:
      return null
  }
}

const whiteNavPreset = {
  transparentWithBlueBorder: 'transparentWithBlueBorderOnDark',
}

const MotionComponent = styled(motion.div)`
  margin-bottom: ${({ theme }) => theme.spacing[2.5]};

  &:nth-last-child(2) {
    margin-top: auto;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const Item = styled(NavLink)`
  display: block;
  position: relative;
  color: ${({ color }) => (color ? color : 'inherit')};

  ${() => css`
    display: flex;

    svg {
      margin-right: 8px;
      transition:
        opacity ${durations.medium}ms,
        color ${durations.short}ms linear;
    }
  `}

  &::before {
    display: block;
    width: 2px;
    background: white;
    background: var(--color-background, ${colors.white});
    position: absolute;
    left: -16px;
    top: 12px;
    bottom: 15px;
  }

  &[data-active='true'] {
    color: var(--color-accent, ${colors.deepBlue});

    &::before {
      content: '';
    }

    & > * {
      color: var(--color-accent, ${colors.deepBlue});
    }
  }
`

const _Logo = styled(Logo)`
  width: 93px;
  overflow: visible;
`

const Top = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[2.5]} 0`};
  background: var(--color-background, ${colors.midGrey});
  z-index: 12;
  display: none;

  ${mediaMax.mlg(css`
    display: flex;
  `)};
`

const Content = styled(motion.div)`
  position: fixed;
  top: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  padding: 26px 40px 80px;
  background: var(--color-background, ${colors.midGrey});
  display: flex;
  flex-direction: column;
`

const SubContent = styled(motion.div)`
  position: fixed;
  top: 82px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;
  padding: 0 0 80px;
  background: var(--color-background, ${colors.white});
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ${media.md(css`
    padding: ${({ theme }) => `${theme.spacing[2.5]} ${theme.spacing[5]} 80px`};
  `)}

  ${media.mlg(css`
    padding: 0 80px;
  `)}
`

const CloseButton = styled.button`
  flex: 0 0 40px;
  height: 44px;
  width: 44px;
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-right: -13px;
  margin-top: -12px;
  justify-content: center;
`

const ItemWithIcon = ({ to, icon, children }) => (
  <Item to={to} withIcon color={colors.deepBlue}>
    {icon}
    <Type preset="text" weight="bold">
      {children}
    </Type>
  </Item>
)

const Explore = ({
  withIcon,
  spaced,
  to,
  children,
  preset,
  isWhiteColored,
  ...rest
}) => (
  <Button
    to={to}
    weight="medium"
    paddingSize="menu"
    preset={isWhiteColored ? whiteNavPreset[preset] || preset : preset}
    {...rest}
  >
    {children}
  </Button>
)

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const Back = styled(motion.button)`
  position: absolute;
  color: var(--color-accent, ${colors.deepBlue});
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 14px;

  svg {
    position: absolute;
    right: 100%;
    margin-right: 14px;
  }
`

const Background = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color-background, ${colors.white});
  z-index: 12;
`

type NavData = {
  url: string
  pages: any[]
  content: {
    title: string
    description: string
  }
  promoBox?: INavigationItemFields['promoBox']
}

const MobileNavBase = ({
  navigationData = [],
  isOpen,
  router,
  onClose,
  homeUrl,
  isWhiteColored,
}) => {
  const { t } = useTranslation()
  const [subNavOpen, setSubNavOpen] = useState(false)
  const [subNav, setSubNav] = useState<NavData | undefined>()
  const [drawerNav, setDrawerNav] = useState<NavData | undefined>()

  const loadSubNav = (nav, data) => {
    if (subNav) {
      setSubNavOpen(true)
    }

    setSubNav({
      url: nav,
      ...data,
    })
  }

  const closeSubNav = () => {
    setSubNav(null)
    setTimeout(() => setSubNavOpen(false), 100)
  }

  const closeNav = () => {
    setSubNavOpen(false)
    setTimeout(() => {
      setSubNav(null)
      onClose()
    }, 100)
  }

  const loadDrawerNav = (nav, data) => {
    if (drawerNav) {
      setDrawerNav(null)
      return
    }

    setDrawerNav({
      url: nav,
      ...data,
    })
  }

  const closeDrawerNav = () => {
    setDrawerNav(undefined)
  }

  return (
    <>
      <AnimatePresence>
        {isOpen ? (
          <>
            <Background
              key="background"
              initial="closed"
              animate="open"
              exit="closed"
              variants={{
                open: {
                  opacity: 1,
                },
                closed: {
                  opacity: 0,
                },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            />
            <Top
              key="top"
              initial="closed"
              animate="open"
              exit="closed"
              variants={{
                open: {
                  opacity: 1,
                },
                closed: {
                  opacity: 0,
                },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div style={{ position: 'relative' }}>
                <AnimatePresence>
                  {subNav ? (
                    <Back
                      key="back"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: { opacity: 1 },
                        closed: { opacity: 0 },
                      }}
                      transition={{
                        duration: 0.8,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                      onClick={closeSubNav}
                    >
                      <LinkArrow width={10} rotate={-180} y="-50%" />
                      {t('back')}
                    </Back>
                  ) : null}
                </AnimatePresence>
                <AnimatePresence>
                  {!subNav ? (
                    <motion.div
                      key="logo"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: { opacity: 1 },
                        closed: { opacity: 0 },
                      }}
                      transition={{
                        duration: 0.8,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                      style={{ position: 'absolute' }}
                    >
                      <Link aria-label="Home" to={homeUrl || '/'}>
                        <_Logo />
                      </Link>
                    </motion.div>
                  ) : null}
                </AnimatePresence>
              </div>
              <CloseButton onClick={closeNav}>
                <CloseIcon
                  height={12}
                  color={
                    isWhiteColored && !isOpen
                      ? colors.white
                      : 'var(--color-text)'
                  }
                />
              </CloseButton>
            </Top>
          </>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && subNav ? (
          <SubContent
            key={(subNav as any).url}
            initial="closed"
            animate="open"
            exit="closed"
            variants={{
              open: { opacity: 1, x: 0 },
              closed: { opacity: 0, x: 80 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <PortalNav
              withAnimation={false}
              pages={subNav.pages}
              content={subNav.content}
              promoBox={subNav.promoBox}
              closeMenu={closeNav}
              currentPath={router.asPath}
              isSubmenu
            />
          </SubContent>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && !subNav ? (
          <Content
            key="content"
            initial="closed"
            animate="open"
            exit="closed"
            variants={{
              open: {
                opacity: 1,
                x: 0,
                transition: {
                  staggerChildren: 0.07,
                },
              },
              closed: subNavOpen
                ? {
                    opacity: 0,
                    x: -100,
                  }
                : {
                    opacity: 0,
                  },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {navigationData.map((item, index) => {
              const { hasIcon, icon, isButton, isDrawer, link } = item.fields
              let Component: any = Item
              let Icon = null
              if (hasIcon) {
                Component = ItemWithIcon as any
              }
              if (isButton) {
                Component = Explore as any
              }
              if (hasIcon && icon) {
                Icon = getIcon(icon)
              }
              const to = isButton ? link.fields.link : link.fields.url
              const myBooking = to.includes(myBookingUrl)
                ? getMyBookingLink()
                : null
              const text = isButton ? link.fields.text : link.fields.label
              const subData: Partial<NavData> = item.fields.subLinks
                ?.length && {
                pages: item.fields.subLinks.map(page => page.fields),
                content: {
                  title: item.fields.subTitle,
                  description: item.fields.subDescription,
                },
                promoBox: item.fields.promoBox,
              }
              return (
                <MotionComponent variants={itemVariants} key={index}>
                  <Component
                    to={myBooking || to}
                    onClick={e => {
                      if (isDrawer) {
                        e.preventDefault()
                        loadDrawerNav(to, subData)
                        return
                      }

                      if (!subData) {
                        return
                      }

                      e.preventDefault()

                      loadSubNav(to, subData)
                    }}
                    preset={isButton ? link.fields.color : null}
                    isWhiteColored={isButton ? isWhiteColored : null}
                    icon={hasIcon ? <Icon /> : null}
                    data-cy={text}
                  >
                    {hasIcon || isButton ? (
                      text
                    ) : (
                      <Type preset="headline">{text}</Type>
                    )}
                  </Component>
                </MotionComponent>
              )
            })}
          </Content>
        ) : null}
      </AnimatePresence>
      <Drawer
        show={!!drawerNav}
        onDismiss={closeDrawerNav}
        noPadding
        closeButton={
          <CloseButton onClick={closeDrawerNav}>
            <CloseIcon height={12} color="var(--color-text)" />
          </CloseButton>
        }
      >
        {drawerNav && (
          <DrawerNav pages={drawerNav.pages} content={drawerNav.content} />
        )}
      </Drawer>
    </>
  )
}

export const MobileNav = withRouter(MobileNavBase)
