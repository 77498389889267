import { addDays } from 'date-fns/addDays'
import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays'
import { eachDayOfInterval } from 'date-fns/eachDayOfInterval'
import { isBefore } from 'date-fns/isBefore'
import { fromZonedTime, toZonedTime } from 'date-fns-tz'

import type { FlowControl } from 'bl-flows-core'
import { Property, type RoomOffer } from 'bl-graphql'

import {
  HIGHLAND_STANDARD_PACKAGE_RATECODE,
  RETREAT_STANDARD_PACKAGE_RATECODE,
  SILICA_STANDARD_PACKAGE_RATECODE,
} from '../flows/hotel/constants'
import type { RoomSelection } from '../flows/hotel/types'

type Guests = {
  adults: number
  children: number
  infants: number
}

function eachDayOfIntervalInTimezone({ start, end }) {
  const days = eachDayOfInterval({
    start: toZonedTime(start, 'UTC'),
    end: toZonedTime(end, 'UTC'),
  })

  return days.map(day => fromZonedTime(day, 'UTC'))
}

export const isSameDayUTC = (date1: Date, date2: Date) => {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  )
}

export const calculatePackagePrice = (
  guests: Guests,
  rateCode: string,
  roomOffer?: RoomOffer
) => {
  const roomRateCodes = roomOffer?.rateCodes?.[rateCode]

  const numberOfGuests = (guests.adults ?? 0) + (guests.children ?? 0)

  const price =
    roomRateCodes?.reduce((acc: number, roomRateCode) => {
      const extraChildPrice = roomRateCode?.childPrice ?? 0
      const extraAdultPrice = roomRateCode?.extraAdult ?? 0

      // Always fill pax with adults first, then charge for additional adults and children
      // Example: If pax is 10 and there are 12 adults and 2 children: Charge extra for 2 adults and all of the children
      // Example: If pax is 10 and there are 8 adults and 4 children: Charge extra for 2 children
      if (
        roomRateCode?.pax &&
        roomRateCode.extraAdult != null &&
        numberOfGuests > roomRateCode?.pax
      ) {
        // Check how many extra adults need to be charged
        if (guests.adults > roomRateCode.pax) {
          const extraAdults = guests.adults - roomRateCode.pax
          // Number of adults does not fit in the pax, so charge for the extra adults + // Charge for all of the children since pax is already full
          return (
            acc +
            Number(roomRateCode.price) +
            extraAdults * extraAdultPrice +
            guests.children * extraChildPrice
          )
        } else {
          // The adults fit in the pax, so charge for the remaining extra children
          return (
            acc +
            Number(roomRateCode.price) +
            (numberOfGuests - roomRateCode.pax) * extraChildPrice
          )
        }
      }

      return acc + Number(roomRateCode.price)
    }, 0) || 0

  return price
}

export const calculateRoomPrice = (guests: Guests, roomOffer: RoomOffer) => {
  const roomRateCode = roomOffer.rateCodes?.[roomOffer.fields?.rateCode]?.[0]

  const extraChildPrice = roomRateCode?.childPrice ?? 0
  const extraAdultPrice = roomRateCode?.extraAdult ?? 0

  const numberOfGuests = (guests.adults ?? 0) + (guests.children ?? 0)

  let extraPrices = 0

  // Always fill pax with adults first, then charge for additional adults and children
  // Example: If pax is 10 and there are 12 adults and 2 children: Charge extra for 2 adults and all of the children
  // Example: If pax is 10 and there are 8 adults and 4 children: Charge extra for 2 children
  if (
    roomRateCode?.pax &&
    roomRateCode.extraAdult != null &&
    numberOfGuests > roomRateCode?.pax
  ) {
    // Check how many extra adults need to be charged
    if (guests.adults > roomRateCode.pax) {
      const extraAdults = guests.adults - roomRateCode.pax
      // Number of adults does not fit in the pax, so charge for the extra adults
      extraPrices += extraAdults * extraAdultPrice
      // Charge for all of the children since pax is already full
      extraPrices += guests.children * extraChildPrice
    } else {
      // The adults fit in the pax, so charge for the remaining extra children
      extraPrices += (numberOfGuests - roomRateCode.pax) * extraChildPrice
    }
  }

  return roomOffer?.price + extraPrices * roomOffer.lengthOfStay
}

export const calculateTotalPrice = (roomSelection: RoomSelection) => {
  return roomSelection?.reduce((total: number, { offer, adults, children }) => {
    if (!offer) return total

    return total + calculateRoomPrice({ adults, children, infants: 0 }, offer)
  }, 0)
}

export const calculateNumberOfGuests = (roomSelection: RoomSelection) => {
  return roomSelection?.reduce(
    (total: number, { adults, children, infants }) => {
      return total + (adults + children + infants)
    },
    0
  )
}

export const calculateNumberOfNights = (startDate: Date, endDate: Date) => {
  return differenceInCalendarDays(
    new Date(
      endDate.getUTCFullYear(),
      endDate.getUTCMonth(),
      endDate.getUTCDate()
    ),
    new Date(
      startDate.getUTCFullYear(),
      startDate.getUTCMonth(),
      startDate.getUTCDate()
    )
  )
}

/**
 * Checks if a given date is included in an array of dates.
 *
 * @param {Date} date - The date to check for.
 * @param {Date[]} dates - An array of dates to check against.
 * @returns {boolean} - Returns true if the date is included in the array, otherwise false.
 */
export const isDateIncludedInArray = (date: Date, dates: Date[]): boolean => {
  return dates?.some(d => isSameDayUTC(d, date)) ?? false
}

/**
 * Returns true if the given date is a checkout-only date, meaning there is no availability on that date
 * but there is availability on the previous days and not the next day.
 *
 * @param {Date} date - The date to check.
 * @param {Date[]} datesWithNoAvailability - An array of dates with no availability.
 * @returns {boolean} - True if the date is a checkout-only date, false otherwise.
 */
export function isDateCheckoutOnly(
  date: Date,
  datesWithNoAvailability: Date[]
) {
  const dayHasNoAvailability = isDateIncludedInArray(
    date,
    datesWithNoAvailability
  )
  if (!dayHasNoAvailability) {
    return false
  }

  const dayBefore = addDays(date, -1)
  const dayBeforeHasAvailability = !isDateIncludedInArray(
    dayBefore,
    datesWithNoAvailability
  )
  if (!dayBeforeHasAvailability) {
    return false
  }

  const dayAfter = addDays(date, 1)
  const dayAfterHasNoAvailability = isDateIncludedInArray(
    dayAfter,
    datesWithNoAvailability
  )
  if (!dayAfterHasNoAvailability) {
    return false
  }

  return true
}

// Check if the date is available for checkout
// If the date is before the selected date, it is not available
// If the date is the day after, it is available
// If the date is after the selected date, it is available if all the days between the selected date and the date are available
export function isDateAvailableAsCheckout(
  date: Date,
  datesWithAvailability: Date[],
  selectedDate: Date
) {
  const isSameOrBeforeSelectedDate =
    isBefore(date, selectedDate) || isSameDayUTC(date, selectedDate)

  if (isSameOrBeforeSelectedDate) {
    return false
  } else if (isSameDayUTC(date, addDays(selectedDate, 1))) {
    return true
  } else {
    const allDaysFromStartDateAreValid = eachDayOfIntervalInTimezone({
      start: selectedDate,
      end: addDays(date, -1),
    }).every(d => {
      return !datesWithAvailability?.some(hd => {
        return isSameDayUTC(hd, d)
      })
    })

    return allDaysFromStartDateAreValid
  }
}

export function isValidDateRange(
  startDate: Date,
  endDate: Date,
  datesWithNoAvailability: Date[]
) {
  return (
    isSameDayUTC(startDate, endDate) ||
    isSameDayUTC(endDate, addDays(startDate, 1)) ||
    eachDayOfIntervalInTimezone({
      start: startDate,
      end: addDays(endDate, -1), // -1 because the checkout date can have no availability
    }).every(d => !datesWithNoAvailability?.some(hd => isSameDayUTC(hd, d)))
  )
}

export const isValidDateRangeV2 = (
  startDate: Date,
  endDate: Date,
  availableDates: Date[]
) => {
  if (!startDate || !endDate) {
    return false
  }

  if (isBefore(endDate, startDate) || isSameDayUTC(startDate, endDate)) {
    return false
  }

  const interval = eachDayOfIntervalInTimezone({
    start: startDate,
    end: addDays(endDate, -1),
  })

  for (const date of interval) {
    const isAvailable = availableDates.some(availableDate =>
      isSameDayUTC(availableDate, date)
    )

    if (!isAvailable) {
      return false
    }
  }

  return true
}

/**
 * Checks if the start date have been selected and not the end dates.
 *
 * @param {Date} startDate - The start date of the range to check.
 * @param {Date} endDate - The end date of the range to check.
 * @returns {boolean} - Returns `true` if only `startDate` are truthy value, and `endDate` falsely; otherwise, returns `false`.
 */
export function isSelectingEndDate(startDate: Date, endDate: Date) {
  return Boolean(startDate && !endDate)
}

// Reset the offer for each room in the selection
export function resetRoomSelection(roomSelection: RoomSelection[]) {
  return roomSelection?.map(room => ({
    ...room,
    offer: undefined,
  }))
}

export function createDynamicUrlSegment(parts: (string | undefined)[]) {
  return parts.filter(part => typeof part !== 'undefined').join('/')
}

export function createDynamicHotelScreenRoute(
  property: string | undefined,
  screenNameEn: string,
  screenNameIs
) {
  return {
    en: createDynamicUrlSegment([property, screenNameEn]),
    is: createDynamicUrlSegment([property, screenNameIs]),
  }
}

export function getHotelImageLayoutPropsImg(
  screenId: string,
  control: FlowControl
) {
  const flowImage = control.context.flowSettings?.flowImage?.fields?.file?.url
  const screenImage = control.context.flowSettings?.flowScreens?.find(
    screen => screen.fields.screenId === screenId
  )?.fields?.image?.fields?.file?.url

  return screenImage
    ? `${screenImage}?w=1600`
    : flowImage
      ? `${flowImage}?w=1600`
      : undefined
}

export function getSpaImageLayoutProps(
  admissionType: string,
  screen: string,
  control: FlowControl
) {
  const flowSettings = control.context.spaFlowSettings?.find(
    settings => settings.fields?.admissionType === admissionType
  )?.fields
  const flowImage = flowSettings?.mainFlowImage?.fields?.file?.url
  const screenImage = flowSettings?.flowScreens?.find(
    settings => settings?.fields?.screenId === screen
  )?.fields?.image?.fields?.file?.url

  const fallbackImage =
    admissionType === 'retreat'
      ? 'https://images.ctfassets.net/w65k7w0nsb8q/3emm4TojxU8JqpTHOVVmml/72c187180261cb459a32e2ffdf7da467/BL_Retreat_ON_8045_1.png?w=1600'
      : 'https://images.ctfassets.net/w65k7w0nsb8q/6Pd8n9YQMlWGofxkXzNcaN/b48f5146de4882011387b4f75e95768d/IMG_0963.jpg?w=1600'

  return {
    layoutId: 'image',
    layoutImageSrc: screenImage
      ? `${screenImage}?w=1600`
      : flowImage
        ? `${flowImage}?w=1600`
        : fallbackImage,
    leftColumnWidth: admissionType === 'retreat' ? 50 : 60,
  }
}

function getDefaultRateCode(property: Property) {
  switch (property) {
    case Property.Silica:
      return SILICA_STANDARD_PACKAGE_RATECODE
    case Property.Kerlingarfjoll:
      return HIGHLAND_STANDARD_PACKAGE_RATECODE
    default:
      return RETREAT_STANDARD_PACKAGE_RATECODE
  }
}

// Used when editing to get correct price based on package and number of children
export function getRateCodePriceForRoom({
  roomRateCode,
  offer,
  numberOfChildren,
}: {
  roomRateCode: string
  offer: RoomOffer
  numberOfChildren: number
}) {
  if (!offer) {
    return 0
  }

  // We expect this to be the property code
  const property = offer.productId.split('-', 1)?.[0] as Property

  // If for some strange reason we don't get the current ratecode info from the server, use standard instead of crashing
  const rateCode =
    offer.rateCodes?.[roomRateCode] ??
    offer.rateCodes?.[getDefaultRateCode(property)]

  if (rateCode) {
    const adultPrice = rateCode?.reduce(
      (total, cur) => Number(cur.price) + Number(total),
      0
    )
    const childPrice =
      numberOfChildren > 0
        ? rateCode?.reduce(
            (total, cur) => Number(cur.childPrice) + Number(total),
            0
          ) * numberOfChildren
        : 0
    return Number(adultPrice) + Number(childPrice)
  }
  return 0
}
