import { useContext } from 'react'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import styled, { css } from 'styled-components'

import type {
  IAmenitiesCard,
  IProductPanelCard,
  ISectionProductPanelFields,
} from 'contentful-shared'
import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { gradients } from 'bl-common/src/constants/gradients'
import { gridOffsetFrom, gridOffsetTo } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { LinkArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'

import { Arrow, Card } from './Card'

type ProductPanelProps = Pick<
  ISectionProductPanelFields,
  'title' | 'description' | 'gradient' | 'ctaLink' | 'name'
> & {
  cards: ISectionProductPanelFields['card']
}

type CardProps = {
  cards: ISectionProductPanelFields['card']
  imageIsWide: boolean
}

type ContainerProps = {
  gradient?: any
}

const Container = styled.div<ContainerProps>`
  padding-top: ${({ theme }) => theme.spacing[3]};
  margin-bottom: ${({ theme }) => theme.spacing[2]};
  position: relative;

  ${media.md(css`
    display: grid;
    grid-template-columns: 45% 55%;
    margin-bottom: 0;
  `)}

  &::after {
    bottom: 0;
    content: '';
    opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 72%;

    ${props => css`
      background: linear-gradient(
        ${props.gradient.color1 || gradients.supportSilver[0]},
        ${props.gradient.color2 || gradients.supportSilver[1]}
      );
    `};
    ${props =>
      props.gradient.opacity &&
      css`
        opacity: ${props.gradient.opacity};
      `};

    .t-dark & {
      opacity: 0.1;
    }

    ${media.md(css`
      top: ${({ theme }) => theme.spacing[14]};
      right: initial;
      width: 85%;
    `)}
  }
`

const Column = styled.div`
  padding-top: ${({ theme }) => theme.spacing[1]};
  padding-bottom: ${({ theme }) => theme.spacing[1]};
  justify-content: center;

  &:nth-child(odd) {
    padding-left: ${gridOffsetFrom}px;
  }
  &:nth-child(even) {
    padding-left: ${gridOffsetFrom}px;
    padding-right: ${gridOffsetFrom}px;
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }

  ${media.md(css`
    &:nth-child(odd) {
      padding-left: ${between(gridOffsetFrom, gridOffsetTo)};
      padding-right: ${({ theme }) => theme.spacing[2]};
    }

    &:nth-child(even) {
      padding-left: ${between(gridOffsetFrom, gridOffsetTo)};
      padding-right: ${between(gridOffsetFrom, gridOffsetTo)};
      margin-bottom: 0px;
    }
  `)}

  ${media.mlg(css`
    &:nth-child(odd) {
      padding-right: ${({ theme }) => theme.spacing[4]};
    }
  `)}
`

const ContentWrapper = styled.div`
  position: relative;
  z-index: ${zIndex.above2};
  margin-bottom: ${({ theme }) => theme.spacing[0.5]};

  ${media.mlg(css`
    padding-right: ${({ theme }) => theme.spacing[4]};
    margin-bottom: ${({ theme }) => theme.spacing[1.5]};
  `)}
`

const LargeCardWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing[1.5]};
  padding-right: ${({ theme }) => theme.spacing[9]};

  ${media.md(css`
    padding-top: ${({ theme }) => theme.spacing[7]};
    padding-right: 0px;
  `)}
`

const CtaLinkWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[1.5]};
  color: ${colors.deepBlue};

  ${media.md(css`
    margin-bottom: ${({ theme }) => theme.spacing[3]};
  `)}

  .t-dark & {
    color: ${colors.blueOnDark};
  }
`

const CtaLink = styled(props =>
  props.to ? <Link {...props} /> : <div {...props} />
)`
  display: flex;

  font-weight: 700;
  font-size: 16px;

  ${media.md(css`
    font-size: 18px;
  `)}
`

const CardsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  z-index: ${zIndex.above2};
`

const Content = ({ arrowSize, ctaLink, title, description }) => (
  <ContentWrapper>
    <Appear observer>
      <Appear>
        <Type as="h2" preset="headlineExtraLarge" bottom={{ xs: 0.5, md: 1 }}>
          {title}
        </Type>
      </Appear>
      <Appear>
        <Type
          multiline
          preset="subtitle"
          maxWidth={450}
          bottom={{ xs: 1, md: 2.5 }}
        >
          {description}
        </Type>
      </Appear>
      <Appear>
        {ctaLink && (
          <CtaLinkWrapper>
            <CtaLink to={ctaLink.fields.url}>
              {ctaLink.fields.name}
              <Arrow large={false} boldText={true}>
                <LinkArrow {...arrowSize} thick />
              </Arrow>
            </CtaLink>
          </CtaLinkWrapper>
        )}
      </Appear>
    </Appear>
  </ContentWrapper>
)

const isProductPanelCard = (
  card: IProductPanelCard | IAmenitiesCard
): card is IProductPanelCard =>
  card.sys.contentType.sys.id === 'productPanelCard'

export const ProductPanel = ({
  title,
  description,
  cards,
  gradient,
  ctaLink,
  name,
}: ProductPanelProps) => {
  const largeCard = cards[0]
  const imageIsWide = cards.length < 3
  const arrowSize = { width: 11, height: 14 }
  const { exchangeRates } = useContext(CurrencyContext)

  const triggerViewItemEvent = item => {
    triggerEvent({
      event: 'view_item',
      ecommerce: {
        currency: 'EUR',
        value: 1,
        items: [
          {
            item_id: item?.fields?.title.replace(/\s+/g, '_').toLowerCase(),
            item_name: item?.fields?.title,
            currency: 'EUR',
            discount: 0,
            item_brand: 'Silica Hotel',
            item_category: 'Accommodation',
            item_list_id: 'accommodation_silica',
            item_list_name: 'Accommodation Silica',
            price: calcPrice(
              item?.fields?.price?.fields?.isk,
              exchangeRates?.EUR
            ),
            quantity: 1,
          },
        ],
      },
    })
  }

  const Cards = ({ cards, imageIsWide }: CardProps) => {
    return (
      <CardsWrapper>
        {cards.slice(1).map(item => (
          <Card
            key={item.fields.title}
            title={item.fields.title}
            price={item.fields.price}
            description={item.fields.description}
            link={item.fields.link?.fields.url}
            image={
              (isProductPanelCard(item) && item.fields.focalImage) ??
              item.fields.image
            }
            imageRatio={imageIsWide ? 236 / 316 : 216 / 178}
            large={false}
            imageIsWide={imageIsWide}
            onClick={() => {
              if (name === 'Hotel rooms') {
                triggerViewItemEvent(item)
              }
            }}
          />
        ))}
      </CardsWrapper>
    )
  }

  const LargeCard = ({ largeCard, isMobile }) => (
    <LargeCardWrapper>
      <Card
        key={largeCard.fields.title}
        title={largeCard.fields.title}
        price={largeCard.fields.price}
        description={largeCard.fields.description}
        link={largeCard.fields.link?.fields.url}
        image={
          (isProductPanelCard(largeCard) && largeCard.fields.focalImage) ??
          largeCard.fields.image
        }
        imageRatio={isMobile ? 184 / 128 : 416 / 328}
        large
        onClick={() => {
          if (name === 'Hotel rooms') {
            triggerViewItemEvent(largeCard)
          }
        }}
      />
    </LargeCardWrapper>
  )

  // TODO: Switch to useBreakpoints
  const isMobile = useObserver(
    observe(viewportWidth(), width => width < breakpoints.md)
  )

  return isMobile ? (
    <Container gradient={gradient?.fields}>
      <Column>
        <Content
          arrowSize={arrowSize}
          ctaLink={ctaLink}
          title={title}
          description={description}
        />
        <LargeCard largeCard={largeCard} isMobile={isMobile} />
      </Column>
      <Column>
        <Cards cards={cards} imageIsWide={imageIsWide} />
      </Column>
    </Container>
  ) : (
    <Container gradient={gradient?.fields}>
      <Column>
        <LargeCard largeCard={largeCard} isMobile={isMobile} />
      </Column>
      <Column>
        <Content
          arrowSize={arrowSize}
          ctaLink={ctaLink}
          title={title}
          description={description}
        />
        <Cards cards={cards} imageIsWide={imageIsWide} />
      </Column>
    </Container>
  )
}
