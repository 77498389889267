import type {
  IGeneralPageFields,
  IPageBirthdayFields,
  IPageConfig,
  IPagehotelRoomFields,
  IPageMainFields,
  ISplitPageFields,
  ITopicFields,
} from 'contentful-shared'

import * as sectionComponents from './sections'

type UnknownSections =
  | IGeneralPageFields['sections']
  | IPageBirthdayFields['sections']
  | IPagehotelRoomFields['sections']
  | IPageMainFields['sections']
  | ISplitPageFields['sections']
  | ITopicFields['topicSections']

type SectionsRendererProps = {
  sections: UnknownSections
  pageConfig?: IPageConfig
}

const SectionsRenderer = ({ sections, pageConfig }: SectionsRendererProps) => {
  if (!sections?.length) {
    return null
  }

  return (
    <>
      {sections.map((section, index) => {
        let componentId = section.sys.contentType.sys.id

        if (componentId === 'topicImageSection') {
          //todo remove
          componentId = 'sectionImageGrid'
        }
        if (sectionComponents[componentId]) {
          const ReturnedComponent = sectionComponents[componentId]

          return (
            <ReturnedComponent
              key={index}
              section={section}
              pageConfig={pageConfig}
            />
          )
        }
        return null
      })}
    </>
  )
}

export default SectionsRenderer
