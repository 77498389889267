import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import get from 'lodash/get'
import partition from 'lodash/partition'
import { css } from 'styled-components'

import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { colors } from '../../constants/colors'
import { CurrencyContext } from '../../context/Currency/CurrencyProvider'
import { Button } from '../../elements/Button/Button'
import { ConfirmTick } from '../../elements/Icons/ConfirmTick'
import { PersonIcon } from '../../elements/Icons/PersonIcon'
import { Type } from '../../elements/Typography/Typography'
import { useMailListSignup } from '../../hooks/useMailListSignup'
import { widgets } from '../Form/widgets'
import { LocaleSwitcher } from '../LocaleSwitcher'
import * as styles from './styles'

const stripSpaces = str => str.replace(/\s/g, '')

const LinkGroup = ({ linkGroup, linkComponent = undefined }) => {
  const [links1, links2] = partition(linkGroup.fields.links, link => {
    if (!link.fields.tags || !Array.isArray(link.fields.tags)) {
      return true
    }
    return !link.fields.tags.includes('secondary')
  })

  const { title } = linkGroup.fields
  const LinkComponent = linkComponent || styles.StyledLink
  return (
    <styles.Group>
      {title && (
        <styles.GroupHeader
          bottom={{ xs: 0.5 }}
          size={{ xs: 14, md: 14 }}
          case="uppercase"
          weight="medium"
          spacing={1}
        >
          {title}
        </styles.GroupHeader>
      )}
      {links1.map(link => (
        <LinkComponent key={link.fields.label} to={link.fields.url}>
          <Type inline size={{ xs: 16, md: 16 }}>
            {link.fields.label}
          </Type>
        </LinkComponent>
      ))}
      <styles.Secondary>
        {links2.map(link => (
          <LinkComponent key={link.fields.label} to={link.fields.url}>
            <Type inline size={{ xs: 16, md: 16 }}>
              {link.fields.label}
            </Type>
          </LinkComponent>
        ))}
      </styles.Secondary>
    </styles.Group>
  )
}

export const Footer = ({ footer, linkComponent = undefined }) => {
  const { currency } = useContext(CurrencyContext)
  const { i18n } = useTranslation()
  const [langSelectorOpen, setLangSelectorOpen] = useState(false)

  if (!footer) {
    return null
  }
  const fields = get(footer, 'fields')

  const hasMailSignup = fields.mailchimpListId || fields.klaviyoListId

  const { success, error, signUp } = useMailListSignup({
    mailchimpListId: fields.mailchimpListId,
    klaviyoListId: fields.klaviyoListId,
  })

  const footerLinks = get(footer, 'fields.footerLinks', [])
  const contact = get(footer, 'fields.contactInformation.fields')
  const manageBookingLink = get(footer, 'fields.manageBookingLink.fields')

  const currencySelectorIndex = get(
    footer,
    'fields.currencySelectorPosition',
    0
  )
  const hideBottom = currencySelectorIndex === -1

  const form = {
    fields: [
      {
        key: 'footerEmail',
        name: fields.emailLabel,
        type: 'email',
        buttonLabel: fields.formSubmitButtonText || 'Sign up',
        isRequired: true,
      },
      {
        key: 'footerFormTerms',
        name: '',
        type: 'checkbox',
        isRequired: true,
        label: formatHtmlText(fields.formTermsAndConditions),
      },
    ],
  }

  const initialValues = {
    footerEmail: '',
    footerFormTerms: false,
  }

  const submit = (data, { resetForm }) => {
    signUp(
      {
        email: data.footerEmail,
        $consent: data.footerFormTerms ? ['email'] : false,
      },
      resetForm
    )
  }

  const validate = values => {
    const errors: { footerEmail?: string; footerFormTerms?: string } = {}

    if (!values.footerEmail) {
      errors.footerEmail = fields.emailRequiredLabel || 'Email is required'
    }

    if (!values.footerFormTerms) {
      errors.footerFormTerms =
        fields.termsRequiredLabel || 'Please accept the privacy policy'
    }

    return errors
  }

  return (
    <styles.Container data-cy="footer">
      <styles.ContactBox>
        <styles.Logo />
        <Type
          bottom={contact.telephone ? 1 : 2.5}
          textAlign="center"
          multiline
          lineHeight={2}
          size={{ xs: 16, md: 16 }}
          dangerouslySetInnerHTML={{
            __html: formatHtmlText(
              contact.contactBoxText,
              css`
                text-decoration: underline;
              `
            ),
          }}
        />
        {contact.telephone && (
          <Type size={{ xs: 16, md: 16 }} bottom={2.5} underline>
            <a
              href={`tel:${stripSpaces(contact.telephone)}`}
              style={{ position: 'relative', zIndex: 1 }}
            >
              {contact.telephone}
            </a>
          </Type>
        )}
        <Button
          to={contact.buttonLink}
          style={{ fontWeight: 500, width: '100%' }}
          paddingSize="medium"
          linkComponent={linkComponent}
          data-cy="footer-contact-button"
        >
          {contact.buttonText}
        </Button>
      </styles.ContactBox>
      <styles.Content>
        {footerLinks.map(group => (
          <LinkGroup
            linkComponent={linkComponent}
            linkGroup={group}
            key={group.sys.id}
          />
        ))}
        {hasMailSignup && (
          <styles.SignupWrap>
            <Type preset="headlineSmall" bottom={{ xs: 1 }}>
              {fields.formTitle}
            </Type>
            <Formik
              onSubmit={submit}
              validate={validate}
              initialValues={initialValues}
            >
              {({ handleSubmit, submitCount, errors, touched }) => (
                <styles.Form
                  onSubmit={handleSubmit}
                  data-cy="footer-email-form"
                >
                  <styles.selectorWrapper>
                    <widgets.buttonInput
                      key="footerEmail"
                      field={form.fields[0]}
                      hasTriedSubmitting={submitCount > 0}
                      errors={errors}
                      touched={touched}
                    />
                  </styles.selectorWrapper>
                  {!success && (
                    <widgets.checkbox
                      key="footerFormTerms"
                      field={form.fields[1]}
                      hasTriedSubmitting={submitCount > 0}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  {success && (
                    <div data-cy="footer-email-response">
                      <styles.IconLink
                        as="div"
                        style={{ alignItems: 'center' }}
                      >
                        <ConfirmTick
                          height={18}
                          width={18}
                          color={colors.dark}
                        />
                        <Type size={{ xs: 16 }} left={{ xs: 0.5 }}>
                          {fields.formSuccessMessage}
                        </Type>
                      </styles.IconLink>
                    </div>
                  )}
                  <styles.ErrorMessage aria-live="polite">
                    {error && (
                      <div data-cy="footer-email-response">
                        <Type color={colors.errorRed} preset="text">
                          {fields.formErrorMessage}
                        </Type>
                      </div>
                    )}
                  </styles.ErrorMessage>
                </styles.Form>
              )}
            </Formik>
          </styles.SignupWrap>
        )}
      </styles.Content>
      {!hideBottom && (
        <styles.Bottom>
          {manageBookingLink ? (
            <styles.IconLink to={manageBookingLink.url}>
              <PersonIcon />
              <Type
                preset="labelSmall"
                left={{ xs: 0.5 }}
                inline
                color={colors.dark}
              >
                {manageBookingLink.label}
              </Type>
            </styles.IconLink>
          ) : (
            <div />
          )}
          <LocaleSwitcher
            position="bottom"
            isOpen={langSelectorOpen}
            toggleOpen={() => setLangSelectorOpen(!langSelectorOpen)}
            label={
              <Type
                preset="labelSmall"
                left={{ xs: 0.5 }}
                inline
                color={colors.dark}
              >
                {`${i18n.language.toUpperCase()} / ${currency}`}
              </Type>
            }
          />
        </styles.Bottom>
      )}
    </styles.Container>
  )
}
