import { initializeSentry } from 'sentry-utils/initialize'
import { getCookie } from 'bl-utils/src/cookies'

import { getCurrentEnvironment } from './environment'

const SENTRY_TRACE_SAMPLE_RATE = process.env.SENTRY_TRACE_SAMPLE_RATE
  ? Number(process.env.SENTRY_TRACE_SAMPLE_RATE)
  : 0

const correlationId = getCookie('bl-cid')

initializeSentry({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
  environment: getCurrentEnvironment(),
  correlationId,
})
