import styled from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { Hero } from 'bl-common/src/units/Hero'
import { Section } from 'bl-common/src/units/Section/Section'
import { SectionContent as Content } from 'bl-common/src/units/Section/SectionContent'

export const SectionContent = styled(Content)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.spacing[2]};
  text-align: center;
  width: 100%;
`

export const MultilineText = styled(Type)`
  white-space: pre-line;
`

export const SplitWord = styled.span`
  display: inline-block;
  white-space: pre;
`

const animatedHeading = heading => {
  const split = heading.split(' ')
  return (
    split.length &&
    split.map((word, index) => (
      <Appear styleChild key={`${word}-${index}`}>
        <SplitWord>{`${word} `}</SplitWord>
      </Appear>
    ))
  )
}

export const InfoHeroSection = ({
  section: {
    fields: {
      heading,
      multilineText,
      textColor,
      backgroundContrast,
      image,
      config,
    },
  },
}) => {
  const animateHeading = heading && !multilineText
  return (
    <Section
      config={config}
      top={{ xs: 2, md: 6 }}
      bottom={{ xs: 4, md: 6 }}
      noHorizontalPadding
    >
      <Hero
        size="content"
        setHeight={{ xs: '456px', md: '100vh' }}
        image={image}
        backgroundContrast={backgroundContrast}
        fillContainer
      >
        <SectionContent textColor={textColor}>
          <Appear observer>
            <Type
              preset="headlineLarge"
              bottom={multilineText && { xs: 1, md: 1 }}
              maxWidth="840"
            >
              {animateHeading ? (
                animatedHeading(heading)
              ) : (
                <Appear>{heading}</Appear>
              )}
            </Type>
            {multilineText && (
              <Appear>
                <MultilineText preset="subtitle" maxWidth="760">
                  {multilineText}
                </MultilineText>
              </Appear>
            )}
          </Appear>
        </SectionContent>
      </Hero>
    </Section>
  )
}

export default InfoHeroSection
