import {
  type ComponentState,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useViewObserver } from './useViewObserver'

interface State {
  inView: boolean
  inViewBefore: boolean
  entry: IntersectionObserverEntry | null
  observer: IntersectionObserver | null
}

interface UseInViewReturn extends State {
  ref: (node: Element | null) => void
}

interface Options extends IntersectionObserverInit {
  unobserveOnEnter?: boolean
  onChange?: (inView: boolean) => void
}

type UseInView = (
  options?: Options,
  externalState?: ComponentState[]
) => UseInViewReturn

export const useInView: UseInView = (options, externalState) => {
  const inViewBefore = useRef(false)
  const [state, setState] = useState<State>({
    inView: false,
    inViewBefore: false,
    entry: null,
    observer: null,
  })

  useEffect(() => {
    if (options?.onChange) {
      options.onChange(state.inView)
    }
  }, [state.inView])

  const { root, rootMargin, threshold, unobserveOnEnter } = options || {}

  const dependencies = externalState || []

  const callback = useCallback<IntersectionObserverCallback>(
    ([entry], observer) => {
      const inThreshold = observer.thresholds.some(
        t => entry.intersectionRatio >= t
      )
      const inView = inThreshold && entry.isIntersecting

      if (inView) {
        inViewBefore.current = true
      }

      setState({
        inView,
        inViewBefore: inViewBefore.current,
        entry,
        observer,
      })

      // unobserveOnEnter
      if (inView && unobserveOnEnter) {
        observer.unobserve(entry.target)
        observer.disconnect()
      }
    },
    [unobserveOnEnter]
  )

  const setTarget = useViewObserver(callback, { root, rootMargin, threshold }, [
    unobserveOnEnter,
    ...dependencies,
  ])

  return {
    ref: setTarget,
    ...state,
  }
}
