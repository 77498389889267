import dynamic from 'next/dynamic'
import get from 'lodash/get'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import type { ISectionSpaBookingBarFields } from 'contentful-shared'
import { Container } from 'bl-common/src/units/Container'
import { media } from 'bl-common/src/utils/media'

import { formatGradient } from '../../../utils/formatters'

// TODO - Remove dynamic importing
const SpaBookingBar = dynamic(() => import('../../SpaBookingBar'), {
  ssr: false,
})

const HotelBookingBar = dynamic(() => import('../../HotelBookingBar'), {
  ssr: false,
})

type BookingBarSectionProps = {
  section: {
    fields: ISectionSpaBookingBarFields
  }
}

interface ContainerProps {
  background?: { degrees: any; color1: any; color2: any }
}

export const _Container = styled(Container)<ContainerProps>`
  ${({ background }) =>
    media.md(css`
      padding-top: ${({ theme }) => theme.spacing[1.5]};
      padding-bottom: ${({ theme }) => theme.spacing[1.5]};

      ${
        background &&
        css`
        background: linear-gradient(
          ${get(background, 'fields.degrees', background.degrees)},
          ${rgba(get(background, 'fields.color1', background.color1), 0.5)},
          ${rgba(get(background, 'fields.color2', background.color2), 0.5)}
        );
      `
      }
    `)}
`

export const BookingBarSection = ({
  section: {
    fields: { type, config, ctaLabel, property },
  },
}: BookingBarSectionProps) => {
  const formattedGradient = formatGradient(config?.fields?.background)

  return (
    <_Container
      background={formattedGradient}
      id={config?.fields?.id as string}
    >
      {type === 'Hotel' ? (
        <HotelBookingBar ctaLabel={ctaLabel} property={property} />
      ) : (
        <SpaBookingBar property={property} ctaLabel={ctaLabel} />
      )}
    </_Container>
  )
}

export default BookingBarSection
