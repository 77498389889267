import styled, { type CSSObject } from 'styled-components'

import type { IInteractiveItemFields } from 'contentful-shared'
import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { between } from 'bl-common/src/utils/between'
import { mediaObj } from 'bl-common/src/utils/media'

export const DesktopImageWrap = styled.div({
  position: 'relative',
  display: 'none',

  [mediaObj.md]: {
    display: 'block',
  },
})

const interactiveItemBaseStyles: CSSObject = {
  position: 'absolute',
  background: colors.lagoonBlue,
  border: '2px solid',
  borderColor: colors.white,
  borderRadius: '50%',
  cursor: 'pointer',
}

export const InteractiveTooltip = styled.div<{
  position: IInteractiveItemFields['position']
}>(({ position = 'top' }) => ({
  position: 'absolute',

  background: colors.white,
  color: colors.black,
  zIndex: zIndex.behind,
  transform: 'translate(calc(-50% + 20px), calc(-100% + -45px))',
  opacity: 0,
  width: 'max-content',
  maxWidth: 380,
  transition:
    'opacity 0.3s ease-in-out 0.15s, transform 0.3s ease-in-out 0.15s',

  ...(position === 'bottom' && {
    transform: 'translate(calc(-50% + 20px), 65px)',
  }),
  ...(position === 'left' && {
    transform: 'translate(65px, -50%)',
  }),
  ...(position === 'right' && {
    transform: 'translate(calc(-100% - 45px), -50%)',
  }),
  ...(position === 'top-left' && {
    transform: 'translate(-10%, calc(-100% + -45px))',
  }),
  ...(position === 'top-right' && {
    transform: 'translate(-90%, calc(-100% + -45px))',
  }),
  ...(position === 'bottom-left' && {
    transform: 'translate(-10%, 65px)',
  }),
  ...(position === 'bottom-right' && {
    transform: 'translate(-90%, 65px)',
  }),
}))

export const InteractiveItem = styled.div<{
  position: IInteractiveItemFields['position']
}>(({ position = 'top' }) => ({
  // use the base styles
  ...interactiveItemBaseStyles,
  height: 20,
  width: 20,

  '::before': {
    height: 70,
    left: '50%',
    position: 'absolute',
    top: 0,
    transform: 'translate(-50%, -80%)',
    width: 200,

    ...((position === 'bottom' ||
      position === 'bottom-left' ||
      position === 'bottom-right') && {
      bottom: 0,
      transform: 'translate(-50%, 0%)',
    }),
    ...(position === 'left' && {
      height: 200,
      top: -100,
      transform: 'none',
      width: 70,
    }),
    ...(position === 'right' && {
      height: 200,
      left: -50,
      top: -100,
      transform: 'none',
      width: 70,
    }),
  },

  '::after': {
    background: colors.white,
    content: '""',
    height: 50,
    left: '50%',
    position: 'absolute',
    top: 0,
    transform: 'translate(-50%, -100%) scale(0)',
    transformOrigin: '50% 100%',
    transition: 'transform 0.2s ease-in-out',
    width: 2,

    ...((position === 'bottom' ||
      position === 'bottom-left' ||
      position === 'bottom-right') && {
      transform: 'translate(-50%, 20px) scale(0)',
      transformOrigin: '50% 0%',
    }),

    ...(position === 'left' && {
      height: 2,
      left: '100%',
      top: '50%',
      transform: 'scale(0)',
      transformOrigin: '0% 50%',
      width: 50,
    }),
    ...(position === 'right' && {
      height: 2,
      left: -50,
      top: '50%',
      transform: 'scale(0)',
      transformOrigin: '100% 0%',
      width: 50,
    }),
  },

  ':hover, :focus-within': {
    outlineColor: colors.white,
    outlineOffset: 0,

    '::before': {
      content: '""',
    },

    '::after': {
      transform: 'translate(-50%, -100%) scale(1)',

      ...((position === 'bottom' ||
        position === 'bottom-left' ||
        position === 'bottom-right') && {
        transform: 'translate(-50%, 20px) scale(1)',
      }),
      ...((position === 'left' || position === 'right') && {
        transform: 'scale(1)',
      }),
    },

    [InteractiveTooltip]: {
      opacity: 1,
      zIndex: zIndex.above,

      ...(position === 'bottom' && {
        transform: 'translate(calc(-50% + 20px), 70px)',
      }),
      ...(position === 'left' && {
        transform: 'translate(70px, -50%)',
      }),
      ...(position === 'right' && {
        transform: 'translate(calc(-100% - 50px), -50%)',
      }),
      ...(position === 'top-left' && {
        transform: 'translate(-10%, calc(-100% + -50px))',
      }),
      ...(position === 'top-right' && {
        transform: 'translate(-90%, calc(-100% + -50px))',
      }),
      ...(position === 'bottom-left' && {
        transform: 'translate(-10%, 70px)',
      }),
      ...(position === 'bottom-right' && {
        transform: 'translate(-90%, 70px)',
      }),
    },
  },
}))

export const InteractiveTooltipContent = styled.div({
  paddingInline: between(16, 24),
  paddingBlock: between(12, 24),
  cursor: 'default',
})

export const MobileContainer = styled.div({
  position: 'relative',
  display: 'block',

  [mediaObj.md]: {
    display: 'none',
  },
})

export const MobileImageWrap = styled.div({
  position: 'relative',
  overflow: 'hidden',
})

export const Dot = styled.span<{ active: boolean; height?: number }>(
  ({ active, height = 0 }) => ({
    ...interactiveItemBaseStyles,
    display: 'block',
    height: 16,
    width: 16,
    opacity: active ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',

    '::after': {
      background: colors.white,
      content: '""',
      height,
      left: '50%',
      position: 'absolute',
      top: '100%',
      transformOrigin: '50% 0%',
      transform: active
        ? 'translate(-50%, 0) scale(1)'
        : 'translate(-50%, 0) scale(0)',
      transition: 'transform 0.4s ease-in-out 0.2s',
      width: 2,
    },
  })
)

export const Image = styled.div<{ image?: string; imageRatio: number }>(
  ({ image, imageRatio }) => ({
    position: 'relative',
    width: '100%',
    zIndex: zIndex.above,
    overflow: 'hidden',
    paddingBottom: `${imageRatio * 100}%`,

    '::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  })
)

export const TextContent = styled.div({
  position: 'relative',
  paddingBlock: 24,
  background: colors.white,
  color: colors.black,

  /*************************************
   * Nested global slider styles start
   ************************************/
  '.IICarousel': {
    position: 'relative',
  },

  '.IICarousel-button': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'absolute',
    top: 50,
    transform: 'translateY(-50%)',
    height: 32,
    width: 32,

    '&--prev': {
      left: 0,
    },

    '&--next': {
      right: 0,
    },
  },

  '.IICarousel-tray': {
    transition: `transform ${durations.short}ms`,
  },
  /*************************************
   * Nested global slider styles end
   ************************************/
})

export const SlideWrap = styled.div({
  paddingLeft: 48,
  paddingRight: 48,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
})
