import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import type { IGradient } from 'contentful-shared'
import { colors } from 'bl-common/src/constants/colors'
import { gradients } from 'bl-common/src/constants/gradients'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { StyledImage } from 'bl-common/src/units/SmartImage/StyledImage'
import { media } from 'bl-common/src/utils/media'

export const Container = styled.div<{ mobileGradient?: IGradient }>`
  display: flex;
  flex-direction: column;

  ${media.md(css`
    flex-direction: row;
  `)};

  &::after {
    content: '';
    opacity: 1;
    position: absolute;
    z-index: ${zIndex.behind};
    width: 100%;
    height: 80%;
    animation: 3000ms ease-in-out 0s 1 contentSlideInFromBottom;

    .t-dark & {
      opacity: 0.1;
    }

    left: ${({ theme }) => theme.spacing[2]};

    ${({ mobileGradient }) => css<{ mobileGradient?: IGradient }>`
      background: linear-gradient(
        ${mobileGradient?.fields?.color1 || gradients.supportSilver[0]},
        ${mobileGradient?.fields?.color2 || gradients.supportSilver[1]}
      );

      ${media.md(css`
        opacity: 0;
      `)};
    `};
  }
`

export const ContentContainer = styled.div`
  flex: 0 0 50%;
  padding: ${({ theme }) => `${theme.spacing[4.5]} ${theme.spacing[4.5]}`};
  max-width: 800px;

  ${media.lg(css`
    padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[5.5]}`};
  `)}
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const InputContainer = styled.div`
  ${media.lg(css`
    display: flex;
    flex: 1;
    margin-right: 32px;
  `)};
`

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lg(css`
    width: 90%;
    height: 56px;
    flex-direction: row;
  `)};
`

export const ImageContainer = styled.div<{
  gradient?: IGradient
  textContentSide?: 'left' | 'right'
}>`
  flex: 1 0 50%;
  max-width: 1200px;
  position: relative;
  z-index: ${zIndex.above};
  margin-right: ${({ theme }) => theme.spacing[3]};

  &::after {
    ${media.md(css<{ textContentSide?: 'right' | 'left' }>`
      content: '';
      opacity: 1;
      position: absolute;
      z-index: ${zIndex.behind};
      width: 100%;
      height: 100%;
      animation: 3000ms ease-in-out 0s 1 contentSlideInFromBottom;

      .t-dark & {
        opacity: 0.1;
      }

      bottom: ${({ theme }) => theme.spacing[3]};
      left: ${({ theme }) => theme.spacing[3]};
      bottom: ${({ theme }) => theme.spacing[3]};

      ${({ textContentSide }) =>
        textContentSide === 'right'
          ? css`
              right: ${({ theme }) => theme.spacing[3]};
            `
          : css`
              left: ${({ theme }) => theme.spacing[3]};
            `}
    `)};

    ${({ gradient }) => css`
      background: linear-gradient(
        ${gradient?.fields?.color1 || gradients.supportSilver[0]},
        ${gradient?.fields?.color2 || gradients.supportSilver[1]}
      );
    `};
  }
`

export const Image = styled(StyledImage)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: ${zIndex.above};
  width: 100%;
  height: 100%;
  min-height: 325px;
  animation: 3000ms ease-in-out 0s 1 singleImageSlideInFromTop;

  @keyframes singleImageSlideInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 100%;
      transform: translateY(0);
    }
  }
`

export const ErrorMessage = styled(({ ...props }) => (
  <Type size={{ xs: 14, md: 14 }} weight="medium" {...props} />
))`
  color: ${colors.errorRed};
  text-align: right;
  flex: 1;
`

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  ${media.lg(css`
    width: 90%;
  `)};
`

export const BalanceWrapper = styled(motion.div)`
  opacity: 0;
`

export const Label = styled(({ ...props }) => (
  <Type
    as="label"
    size={{ xs: 12, md: 14 }}
    color={colors.dark}
    {...props}
    weight="bold"
  />
))`
  display: block;
  margin-bottom: 8px;
  flex: 0 0 auto;
`
