import { useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css, useTheme } from 'styled-components'

import { replaceLineBreaks } from 'bl-utils/src/replaceLineBreaks'

import { colors } from '../../constants/colors'
import { Caret } from '../../elements/Icons/Caret'
import { Type } from '../../elements/Typography/Typography'
import type { ScreenTheme } from '../../styles/types'

type AccordionProps = {
  withBorder?: boolean
  color?: any
}

const Accordion = styled.div<AccordionProps>`
  position: relative;
  padding-left: ${({ withBorder, theme }) =>
    withBorder ? theme.spacing[1] : 0};
  text-align: left;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'label auto' 'content content';

  ${({ withBorder }) =>
    withBorder &&
    css`
      ::after {
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        width: 2px;
        background: linear-gradient(
          90deg,
          ${colors.lagoonBlue} 0%,
          ${colors.fountainBlue} 97.99%
        );

        ${({ color }: AccordionProps) =>
          color &&
          css`
            background: ${color};
          `}
      }
    `}
`

const Label = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing[0.5]};
  padding-bottom: ${({ theme }) => theme.spacing[0.5]};
  text-align: left;
  grid-area: label;
`

const CaretWrapper = styled.div`
  padding-left: 12px;

  svg {
    display: block;
  }
`

const Content = styled(motion.div)`
  grid-area: content;
`

export const SimpleAccordion = ({
  label,
  children,
  labelColor = colors.deepBlue,
  withBorder = true,
  startExpanded = false,
  renderNextToLabel = null,
  onClick = undefined,
  screenTheme = undefined as ScreenTheme,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(startExpanded)

  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.simpleAccordion

  return (
    <Accordion withBorder={withBorder}>
      <Label
        type="button"
        onClick={() => {
          setExpanded(!expanded)
          onClick?.()
        }}
        aria-controls="accordion-content"
        aria-expanded={expanded}
        aria-label={
          expanded ? 'click to collapse' : `${label}, click to expand`
        }
      >
        <Type
          preset="labelSmall"
          weight="bold"
          {...rest}
          color={
            theme?.bookingEngine?.[screenTheme]?.simpleAccordion?.labelColor ??
            themeStyle?.labelColor ??
            labelColor
          }
        >
          {label}
        </Type>
        <CaretWrapper>
          <Caret
            color={
              theme?.bookingEngine?.[screenTheme]?.simpleAccordion
                ?.labelColor ??
              themeStyle?.labelColor ??
              labelColor
            }
            width="10px"
            style={{
              transform: `rotate(${expanded ? 180 : 0}deg)`,
              transition: 'transform 150ms ease',
            }}
          />
        </CaretWrapper>
      </Label>
      {renderNextToLabel}
      <Content
        id="accordion-content"
        aria-hidden={!expanded}
        variants={{
          collapsed: { opacity: 0, height: 0 },
          expanded: { opacity: 1, height: 'auto' },
        }}
        initial={startExpanded ? 'expanded' : 'collapsed'}
        animate={expanded ? 'expanded' : 'collapsed'}
        exit={expanded ? 'expanded' : 'collapsed'}
        transition={{ damping: 20, stiffness: 200 }}
      >
        {typeof children === 'string' ? (
          <Type
            preset="labelSmall"
            {...rest}
            multiline
            weight="normal"
            top={{ xs: 0.5, md: 1 }}
            color={themeStyle?.contentColor ?? colors.dark}
          >
            {replaceLineBreaks(children)}
          </Type>
        ) : (
          children
        )}
      </Content>
    </Accordion>
  )
}
