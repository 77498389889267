import type { Router } from 'next/router'

import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

// Next.js does not export the TransitionsOption interface,
// but we can get it from where it's used:
export type TransitionOptions = Parameters<Router['push']>[2]

export type HistoryOptions = 'replace' | 'push'

export type Nullable<T> = {
  [K in keyof T]: T[K] | null
}

export type Serializers<T> = {
  parse: (value: string) => T | null
  serialize?: (value: T) => string
}

type SerializersWithDefaultFactory<T> = Serializers<T> & {
  withDefault: (defaultValue: T) => Serializers<T> & {
    readonly defaultValue: T
  }
}

export type UrlStateSchema = {
  [key: string]:
    | Serializers<string | number | boolean | Date | object>
    | SerializersWithDefaultFactory<string | number | boolean | Date | object>
}

export function serializeState(schema: UrlStateSchema, state) {
  const query = new URLSearchParams()

  Object.keys(schema).forEach(key => {
    const value = state[key]

    if (value === undefined || value === null) {
      return
    }

    const serializedValue = schema[key].serialize(value)

    if (
      serializedValue === '' ||
      serializedValue === null ||
      serializedValue === undefined
    )
      return

    query.set(key, serializedValue)
  })

  return query.toString()
}

type QueryTypeMap = Readonly<{
  stringLowerCase: SerializersWithDefaultFactory<string>
  string: SerializersWithDefaultFactory<string>
  integer: SerializersWithDefaultFactory<number>
  float: SerializersWithDefaultFactory<number>
  boolean: SerializersWithDefaultFactory<boolean>
  date: SerializersWithDefaultFactory<Date>
  json<T>(): SerializersWithDefaultFactory<T>

  /**
   * String-based enums provide better type-safety for known sets of values.
   * You will need to pass the stringEnum function a list of your enum values
   * in order to validate the query string. Anything else will return `null`,
   * or your default value if specified.
   *
   * Example:
   * ```ts
   * enum Direction {
   *   up = 'UP',
   *   down = 'DOWN',
   *   left = 'LEFT',
   *   right = 'RIGHT'
   * }
   *
   * const [direction, setDirection] = useQueryState(
   *   'direction',
   *   queryTypes
   *     .stringEnum<Direction>(Object.values(Direction))
   *     .withDefault(Direction.up)
   * )
   * ```
   *
   * Note: the query string value will be the value of the enum, not its name
   * (example above: `direction=UP`).
   *
   * @param validValues The values you want to accept
   */
  stringEnum<Enum extends string>(
    validValues: Enum[]
  ): SerializersWithDefaultFactory<Enum>

  /**
   * A comma-separated list of items.
   * Items are URI-encoded for safety, so they may not look nice in the URL.
   *
   * @param itemSerializers Serializers for each individual item in the array
   * @param separator The character to use to separate items (default ',')
   */
  array<ItemType>(
    itemSerializers: Serializers<ItemType>,
    separator?: string
  ): SerializersWithDefaultFactory<ItemType[]>
}>

export const queryTypes: QueryTypeMap = {
  string: {
    parse: v => v,
    serialize: v => `${v}`,
    withDefault(defaultValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...this,
        defaultValue,
      }
    },
  },
  stringLowerCase: {
    parse: v => v.toLowerCase(),
    serialize: v => `${v.toLowerCase()}`,
    withDefault(defaultValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...this,
        defaultValue,
      }
    },
  },
  integer: {
    parse: v => Number.parseInt(v),
    serialize: v => Math.round(v).toFixed(),
    withDefault(defaultValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...this,
        defaultValue,
      }
    },
  },
  float: {
    parse: v => Number.parseFloat(v),
    serialize: v => v.toString(),
    withDefault(defaultValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...this,
        defaultValue,
      }
    },
  },
  boolean: {
    parse: v => v === 'true',
    serialize: v => (v ? 'true' : 'false'),
    withDefault(defaultValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...this,
        defaultValue,
      }
    },
  },
  date: {
    parse: v => new Date(v),
    serialize: (v: Date) => formatDateInUTC(v, 'yyyy-MM-dd'),
    withDefault(defaultValue) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...this,
        defaultValue,
      }
    },
  },
  stringEnum<Enum extends string>(validValues: Enum[]) {
    return {
      parse: (query: string) => {
        const asEnum = query as unknown as Enum
        if (validValues.includes(asEnum)) {
          return asEnum
        }
        return null
      },
      serialize: (value: Enum) => value.toString(),
      withDefault(defaultValue) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return {
          ...this,
          defaultValue,
        }
      },
    }
  },
  json<T>() {
    return {
      parse: query => {
        try {
          return JSON.parse(decodeURIComponent(query)) as T
        } catch {
          return null
        }
      },
      serialize: value => encodeURIComponent(JSON.stringify(value)),
      withDefault(defaultValue) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return {
          ...this,
          defaultValue,
        }
      },
    }
  },
  array(itemSerializers, separator = ',') {
    return {
      parse: query => {
        type ItemType = NonNullable<ReturnType<typeof itemSerializers.parse>>
        return query
          .split(separator)
          .map(item => decodeURIComponent(item))
          .map(itemSerializers.parse)
          .filter(value => value !== null && value !== undefined) as ItemType[]
      },
      serialize: values =>
        values
          .map<string>(value => {
            if (itemSerializers.serialize) {
              return itemSerializers.serialize(value)
            }
            return JSON.stringify(value)
          })
          .map(encodeURIComponent)
          .join(separator),
      withDefault(defaultValue) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return {
          ...this,
          defaultValue,
        }
      },
    }
  },
}
