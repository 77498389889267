import { useState } from 'react'
import chunk from 'lodash/chunk'
import styled, { css } from 'styled-components'

import type { ISectionGridGalleryFields } from 'contentful-shared'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { CarouselModal } from 'bl-common/src/units/CarouselModal'
import { ResponsiveParallax } from 'bl-common/src/units/ResponsiveParallax'
import { Section } from 'bl-common/src/units/Section/Section'
import { media } from 'bl-common/src/utils/media'

type GridGallerySectionProps = {
  section: {
    fields: ISectionGridGalleryFields
  }
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  ${media.md(css`
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
  `)}

  ${media.lg(css`
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);
  `)}
`

export const ImageCol = styled(ResponsiveParallax)`
  display: flex;
  flex-direction: column;
`

export const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  margin-bottom: 16px;

  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    padding-bottom: 125%;
  }

  ${media.md(css`
    margin-bottom: 24px;
  `)}

  ${media.lg(css`
    margin-bottom: 40px;
  `)}
`

const GridGallerySection = ({
  section: {
    fields: { images, config },
  },
}: GridGallerySectionProps) => {
  const { mediaMd, mediaLg } = useBreakpoints()
  const [showModal, setShowModal] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const rows = images?.length <= 4 ? 1 : images?.length <= 8 ? 2 : 3

  const slicedImages = mediaLg
    ? images
    : images.slice(0, mediaMd ? (rows > 1 ? 6 : 3) : 4)

  const imgChunks = chunk(
    slicedImages,
    mediaLg ? rows : mediaMd ? (rows > 1 ? 2 : 1) : 2
  )

  const speed = 6

  return (
    <>
      <Section config={config} top={{ xs: 6, md: 8 }} bottom={{ xs: 6, md: 8 }}>
        <Container>
          {imgChunks.map((imgChunk, index) => (
            <ImageCol
              key={index}
              from={index % 2 === 0 ? speed : -speed}
              to={index % 2 === 0 ? -speed : speed}
            >
              {imgChunk.map((image, innerIndex) => (
                <ImageWrapper
                  key={`${index}-${innerIndex}`}
                  onClick={() => {
                    setCurrentSlide(images?.indexOf(image) ?? 0)
                    setShowModal(true)
                  }}
                >
                  <ContentfulImage fill image={image} />
                </ImageWrapper>
              ))}
            </ImageCol>
          ))}
        </Container>
      </Section>
      <CarouselModal
        show={showModal}
        onHide={() => setShowModal(false)}
        currentSlide={currentSlide}
        carousel={images}
      />
    </>
  )
}

export default GridGallerySection
