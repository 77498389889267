import type { FC, ReactNode } from 'react'
import { Parallax } from 'react-scroll-parallax'

import { useBreakpoints } from '../hooks/useBreakpoints'
import { useResponsive } from '../hooks/useResponsive'

type ResponsiveParallaxProps = {
  children?: ReactNode
  from?: number
  to?: number
  disabledMobile?: boolean
  disabledDesktop?: boolean
  targetElement?: HTMLElement
  className?: string
}

export const ResponsiveParallax: FC<ResponsiveParallaxProps> = ({
  children,
  from,
  to = 1,
  disabledMobile,
  disabledDesktop,
  targetElement,
  className,
}) => {
  const { mediaMd } = useBreakpoints()

  const fromSpeed = from ? useResponsive(from) : 0
  const toSpeed = useResponsive(to)

  return (
    <Parallax
      translateY={[`${fromSpeed}px`, `${toSpeed}px`]}
      disabled={disabledMobile ? !mediaMd : disabledDesktop ? mediaMd : false}
      targetElement={targetElement}
      className={className}
    >
      {children}
    </Parallax>
  )
}
