import { rgba } from 'polished'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import type { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'

type StyledTextAreaProps = {
  hasError?: boolean
  themeStyle?: PartialBookingEngine['textAreaField']['textArea']
}

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
  border: 1px solid
    ${props => props?.themeStyle?.borderColor ?? colors.formGrey};
  color: ${props => props?.themeStyle?.textColor ?? colors.formDark};

  font-size: 16px;
  min-height: 90px;
  outline: none;
  padding: ${({ theme }) => theme.spacing[1]};
  width: 100%;
  -webkit-appearance: none;
  transition:
    box-shadow 150ms,
    border 150ms;

  border-radius: ${props => props?.themeStyle?.borderRadius ?? 0}px;

  ::placeholder {
    opacity: 0.7;
    font-size: 16px;
    color: ${props => props?.themeStyle?.textColor};
  }

  :hover {
    border: 1px solid
      ${props => props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
    box-shadow: 0 0 12px
      ${props =>
        rgba(props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
  }

  :focus {
    border: 1px solid
      ${props => props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
    box-shadow: 0 0 12px
      ${props =>
        rgba(props?.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
    background: ${props => props?.themeStyle?.backgroundColor ?? colors.white};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      background: rgba(218, 75, 75, 0.15);
      border: 1px solid ${colors.errorRed};

      &:hover,
      &:focus {
        box-shadow: none;
      }
    `}

  ${media.md(css`
    min-height: 112px;
  `)}
`

export const TextArea = ({
  id,
  name,
  value = '',
  label = '',
  placeholder,
  required = false,
  isRequired = false,
  hasError = false,
  onChange = undefined,
  themeStyle = undefined,
  ...rest
}) => {
  return (
    <StyledTextArea
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      required={required}
      hasError={hasError}
      onChange={
        onChange ||
        (() => {
          /* intentionally empty */
        })
      }
      aria-invalid={hasError}
      aria-label={`${label || name}, ${isRequired ? 'Required' : ''}`}
      themeStyle={themeStyle}
      {...rest}
    />
  )
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
}
