import { sentryLogging } from 'sentry-utils/logging'

export const calcPriceWithFractions = (amount: number, rate = 1) => {
  let internalRate = rate
  // biome-ignore lint/suspicious/noGlobalIsNan: TODO: amount is sometimes undefined. This is accidentally being used to display 0 in the UI.
  if (isNaN(amount)) {
    sentryLogging({
      team: 'team-frontend-infrastructure',
      message: 'calcPrice: Amount missing or not a number',
      extras: { rate, amount },
    })
    return null
  }

  // biome-ignore lint/suspicious/noGlobalIsNan: TODO: amount is sometimes undefined. This is accidentally being used to display 0 in the UI.
  if (isNaN(internalRate) || internalRate <= 0) {
    sentryLogging({
      team: 'team-frontend-infrastructure',
      message: 'calcPrice: Rate is not a number or negative',
      extras: { rate },
    })
    internalRate = 1
  }

  if (rate > 1) {
    return amount / internalRate
  }

  return amount * internalRate
}

export const calcPrice = (amount: number, rate = 1) => {
  const result = calcPriceWithFractions(amount, rate)

  return result === null ? null : Number.parseFloat(result.toFixed(0))
}
