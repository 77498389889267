import { Component } from 'react'
import type { NextPage } from 'next'
import PropTypes from 'prop-types'

import { Stagger } from 'bl-common/src/units/Stagger/Stagger'

type PageStateContextType = {
  onPageReady?: () => void
  isFirstLoad?: boolean
}

// TODO: is any of this really used? f.x. the context
export const withTransition = WrappedComponent => {
  class PageLoader extends Component<{ page?: any }> {
    static contextTypes = {
      pageState: PropTypes.object,
    }
    declare context: { pageState?: PageStateContextType }
    componentDidMount() {
      this.context.pageState?.onPageReady?.()
    }

    // TODO: Is this used??
    // Block page re-renders from PageManager changes. Most pages are static.
    // shouldComponentUpdate(newProps, newState, newContext) {
    //   if (shouldComponentUpdate) {
    //     return shouldComponentUpdate(this, newProps, newState, newContext)
    //   }
    //   return false
    // }

    render() {
      const isFirstLoad = this.context.pageState?.isFirstLoad

      return (
        <Stagger appear={!isFirstLoad}>
          {({ value: isVisible }) => {
            return isVisible && <WrappedComponent {...this.props} />
          }}
        </Stagger>
      )
    }
  }

  const PL = PageLoader as NextPage<{ page?: any }>

  PL.getInitialProps = WrappedComponent.getInitialProps

  return PL
}
