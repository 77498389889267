import ldmlNumber from 'ldml-number'

import { sentryLogging } from 'sentry-utils/logging'

const NAN_SYMBOL = 'Unknown'

// currency format config
ldmlNumber.locale.is = {
  thousands_separator: ' ',
  decimal_separator: ',',
  nan_symbol: NAN_SYMBOL,
}
ldmlNumber.locale.eu = {
  thousands_separator: '.',
  decimal_separator: ',',
  nan_symbol: NAN_SYMBOL,
}

const formatIs = ldmlNumber('#,##0', 'is')
const formatEn = ldmlNumber('#,##0.##', 'en')
const formatEur = ldmlNumber('#,##0.##', 'eu')

const validatePrice = (price: string) => {
  if (price === NAN_SYMBOL) {
    sentryLogging({
      message: 'Format price received NaN',
      team: 'team-frontend-infrastructure',
    })
  }
  return price
}

const currencySymbols: { [key: string]: string } = {
  USD: '$',
  EUR: '€',
  ISK: 'kr',
  GBP: '£',
}

const addCurrency = (
  price: string | number,
  currency: string,
  useSymbol?: boolean
) => {
  if (useSymbol && currencySymbols[currency]) {
    const currencySymbol = currencySymbols[currency]

    return `${currency !== 'ISK' ? currencySymbol : ''}${price}${
      currency !== 'ISK' ? '' : ` ${currencySymbol}`
    }`
  }

  return `${currency} ${price}`
}

export const formatPrice = (
  amount: string | number,
  currency: string,
  useSymbol?: boolean
) => {
  switch (currency) {
    case 'EUR':
      return addCurrency(validatePrice(formatEur(amount)), currency, useSymbol)
    case 'ISK':
      return addCurrency(validatePrice(formatIs(amount)), currency, useSymbol)
    default:
      return addCurrency(validatePrice(formatEn(amount)), currency, useSymbol)
  }
}

export const formatPriceWithoutCurrency = (
  amount: string | number,
  currency?: string
): string => {
  switch (currency) {
    case 'EUR':
      return validatePrice(formatEur(amount))
    case 'ISK':
      return validatePrice(formatIs(amount))
    default:
      return validatePrice(formatEn(amount))
  }
}
