import { type FC, useContext } from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import {
  calcPrice,
  calcPriceWithFractions,
} from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import { interpolatePrice } from 'bl-utils/src/currency/interpolatePrice'

import { CurrencyContext } from '../context/Currency/CurrencyProvider'

const AmountValue = styled.span<{ isLoading: boolean }>`
  .js {
    ${({ isLoading }) =>
      isLoading &&
      css`
        display: none;
      `};
  }
`
interface Price {
  isk?: number | undefined
  title: string
  priceFormat?: string | undefined
}
export type PriceValue = number | Price

interface AmountProps {
  value?: PriceValue | PriceValue[] | null
  fallback?: string
  format?: string
  useSymbol?: boolean
  lineThrough?: boolean
  displayFractionalPrices?: boolean
}

export const Amount: FC<AmountProps> = ({
  value,
  fallback,
  format,
  useSymbol = false,
  lineThrough,
  displayFractionalPrices,
  ...rest
}) => {
  const {
    currency,
    exchangeRates,
    isLoading = false,
  } = useContext(CurrencyContext)

  if (value === undefined || value === null) {
    return <>{fallback || ''}</>
  }

  const getDisplayPrice = () => {
    const priceArr = Array.isArray(value) ? value : [value]

    const newPrices = priceArr.map(current => {
      let price = 0
      if (typeof current === 'number') {
        price = current
      } else {
        price = get(current, 'fields.isk', current.isk)
      }

      if (typeof price !== 'number') {
        return format || null
      }

      if (exchangeRates && !isLoading) {
        const calculatedPrice = displayFractionalPrices
          ? calcPriceWithFractions(price, exchangeRates[currency])
          : calcPrice(price, exchangeRates[currency])

        return formatPrice(calculatedPrice, currency, useSymbol)
      }

      return formatPrice(price, 'ISK', useSymbol)
    })
    return newPrices
  }

  const price = getDisplayPrice()

  return format ? (
    <AmountValue
      style={{ textDecoration: lineThrough && 'line-through' }}
      isLoading={isLoading}
      {...rest}
    >
      {interpolatePrice(price, format)}
    </AmountValue>
  ) : (
    <AmountValue
      style={{ textDecoration: lineThrough && 'line-through' }}
      isLoading={isLoading}
      {...rest}
    >
      {price}
    </AmountValue>
  )
}
