import type { FC, PropsWithChildren, ReactElement, RefObject } from 'react'

import { colors } from '../../constants/colors'
import { Type } from '../../elements/Typography/Typography'
import type { BookingBarPanelProps } from './BookingBarPanel'
import * as styles from './styles'

export interface BookingBarItemProps {
  id?: string
  index?: number
  selected?: boolean
  tabIndex?: number
  panelId?: string
  label: string
  value: string
  mobileValue?: string
  onSelected?: (index: number, event: any) => void
  onFocus?: (index: number, event: any) => void
  panel?: ReactElement<PropsWithChildren<BookingBarPanelProps>>
  tabRef?: RefObject<HTMLButtonElement>
  themeColor?: string
  loadingElement?: React.ReactNode
}

export const BookingBarItem: FC<BookingBarItemProps> = ({
  id,
  index,
  selected,
  panelId,
  label,
  value,
  onSelected,
  onFocus,
  tabRef,
  themeColor,
  loadingElement,
}) => {
  if (!label || !value) {
    return null
  }

  return (
    <styles.Item
      role="presentation"
      data-rttab
      selected={selected}
      hasPanel={!!panelId}
      themeColor={themeColor}
    >
      <styles.ItemWrapper
        onClick={event => onSelected(index, event)}
        role="tab"
        id={id}
        aria-selected={selected ? 'true' : 'false'}
        aria-controls={panelId}
        tabIndex={selected ? 0 : undefined}
        ref={tabRef}
        disabled={!panelId}
        onFocus={event => {
          onFocus?.(index, event)
        }}
        type="button"
      >
        <Type
          weight="bold"
          color={themeColor ?? colors.deepBlue}
          bottom={{ md: 0.5 }}
          style={{ fontSize: 14 }}
        >
          {label}
        </Type>
        {loadingElement ? (
          loadingElement
        ) : (
          <styles.Value style={{ fontSize: 16 }} as="dd" color={colors.midGrey}>
            {value}
          </styles.Value>
        )}
      </styles.ItemWrapper>
    </styles.Item>
  )
}
