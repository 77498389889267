import { Type } from 'bl-common/src/elements/Typography/Typography'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'

import { Metadata } from '../../../Metadata'
import Page from '../../../Page'
import LocationBox, { LocationBoxContainer } from './LocationBox'
import * as styles from './styles'

const SelectStore = ({
  content: {
    fields: { title, heading, subheading, sidebarImage, storeLocations },
  },
  metadata,
}) => (
  <Page>
    <Metadata metadata={metadata} title={title} />
    <styles.Outer>
      <styles.Container>
        <styles.Content>
          <Type as="h1" preset="headline" bottom={{ md: 1 }}>
            {heading}
          </Type>
          <Type
            size={{ md: 18, xs: 16 }}
            weight="light"
            bottom={{ md: 5, xs: 3 }}
          >
            {subheading}
          </Type>
          <LocationBoxContainer>
            {storeLocations?.map(store => (
              <LocationBox
                key={store.fields.link}
                text={store.fields.text}
                icon={store.fields.icon}
                link={store.fields.link}
              />
            ))}
          </LocationBoxContainer>
        </styles.Content>
        <SmartImage image={sidebarImage}>
          {imageUrl => <styles.Sidebar image={imageUrl} />}
        </SmartImage>
      </styles.Container>
    </styles.Outer>
  </Page>
)

export default SelectStore
