import { Component, type ReactNode } from 'react'

import { LightBoxConsumer, LightBoxProvider } from './LightBoxProvider'

type LightBoxContextType = {
  registerImage: (images: any[]) => void
  openLightBox: () => void
}

type LightBoxCollectionProps = {
  children: (props: { open: () => void }) => ReactNode
  lightBox?: LightBoxContextType
  images?: any[]
}

class _LightBoxCollection extends Component<LightBoxCollectionProps> {
  componentDidMount() {
    const { lightBox, images } = this.props
    lightBox.registerImage(images)
  }

  render() {
    const { lightBox, children } = this.props

    return children({ open: lightBox.openLightBox })
  }
}

export const LightBoxCollection = props => (
  <LightBoxProvider>
    <LightBoxConsumer>
      {context => <_LightBoxCollection {...props} lightBox={context} />}
    </LightBoxConsumer>
  </LightBoxProvider>
)
