import { useRouter } from 'next/router'
import styled from 'styled-components'

import type { IPagehotelRoom } from 'contentful-shared'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { theme } from 'bl-common/src/styles/theme'
import type { Gradient } from 'bl-common/src/types/custom'
import { Appear } from 'bl-common/src/units/Appear'
import { HotelRoomHero } from 'bl-common/src/units/HotelRoomHero/HotelRoomHero'
import { Section } from 'bl-common/src/units/Section/Section'
import { mediaObj } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'
import { HotelBookingBar } from 'bl-flows/src/flows/hotel/components/HotelBookingBar'
import { HOTEL_BOOKING_BAR_STATE_SCHEMA } from 'bl-flows/src/flows/hotel/constants'
import { Property } from 'bl-graphql'
import { useURLState } from 'flow-builder/src/hooks/useUrlState'
import { queryTypes, serializeState } from 'flow-builder/src/utils/urlState'

import { formatGradient } from 'utils/formatters'
import { isProperty } from 'utils/isProperty'

import { Metadata } from '../../../Metadata'
import Page from '../../../Page'
import SectionsRenderer from '../../SectionsRenderer'
import type { ContentTemplateProps } from '../types'

export const Hero = styled(Section)`
  display: flex;
  flex-direction: column;
`

export const HeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing[2]};
`

export const Feature = styled(Type)`
  ::after {
    content: '|';
    margin: 0 5px;
  }
`

export const ImageAlbumContainer = styled.div`
  position: relative;
`

export const BookingBarSection = styled.div({
  position: 'fixed',
  zIndex: zIndex.behindMenu,
  bottom: 0,
  width: '100%',

  [mediaObj.md]: {
    position: 'relative',
    paddingTop: theme.spacing[2],
    ...mixins.siteGutterObj(),
  },
})

export const BookingBarContainer = styled.div<{ gradient?: Gradient }>(
  ({ gradient }) => ({
    [mediaObj.md]: {
      padding: `${theme.spacing[3]} ${theme.spacing[6.5]}`,
      ...(gradient && {
        background: `linear-gradient(
          ${gradient.degrees},
          ${gradient.color1},
          ${gradient.color2}
        );`,
      }),
    },
  })
)

const DescriptionContainer = styled.div({
  [mediaObj.md]: {
    padding: `0 ${theme.spacing[6.5]}`,
  },
})

const HotelRoomPage = ({
  content: {
    fields: {
      id,
      bookingBar,
      sections,
      title,
      multilineSubtitle,
      imageAlbum,
      features,
      description,
      descriptionTitle,
    },
  },
  metadata,
  config,
}: ContentTemplateProps<IPagehotelRoom>) => {
  const router = useRouter()
  const { mediaMlg } = useBreakpoints()
  const schema = {
    ...HOTEL_BOOKING_BAR_STATE_SCHEMA,
    ...(isProperty(bookingBar.fields.property) && {
      property: queryTypes
        .stringEnum<Property>(Object.values(Property))
        .withDefault(bookingBar.fields.property),
    }),
  }
  const [hotelBookingBarState, setHotelBookingBarState] = useURLState(schema)
  const formattedGradient = formatGradient(bookingBar?.fields.gradient)

  const availabilityMaxDate = bookingBar.fields?.settings?.fields
    ?.availabilityMaxDate
    ? new Date(bookingBar.fields.settings.fields.availabilityMaxDate)
    : undefined

  return (
    <Page
      config={config}
      configOverride={{
        hasAbsolutelyPositionedNavigation: !mediaMlg,
        hasWhiteNavigation: !mediaMlg,
      }}
    >
      <HotelRoomHero
        title={title}
        images={imageAlbum.fields.images}
        subtitle={multilineSubtitle || features.join(' | ')}
      />
      <BookingBarSection>
        <BookingBarContainer
          id={bookingBar.fields.config?.fields?.id as string}
          gradient={formattedGradient}
        >
          <HotelBookingBar
            state={hotelBookingBarState}
            ctaLabel={bookingBar.fields.ctaLabel}
            price={bookingBar.fields.price?.fields}
            maxDate={availabilityMaxDate}
            roomType={id?.split('-')?.[1]}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={newState => {
              setHotelBookingBarState(
                {
                  ...hotelBookingBarState,
                  ...newState,
                  dates: newState.dates?.filter(Boolean),
                },
                'replace',
                {
                  shallow: true,
                }
              )
            }}
            onApply={newState => {
              router.push(
                {
                  pathname: '/book/accommodation',
                  search: `${serializeState(schema, newState)}&roomType=${id}`,
                },
                null,
                { shallow: true }
              )
            }}
            switchBetweenPanels
            mobileBarAsButton
          />
        </BookingBarContainer>
      </BookingBarSection>
      <Section top={{ xs: 1, md: 7.5 }} bottom={{ xs: 0, md: 7.5 }}>
        <Appear observer>
          <DescriptionContainer>
            <Appear delay={1}>
              <Type
                preset="headlineMedium"
                weight="bold"
                bottom={{ xs: 0.5, md: 1 }}
              >
                {descriptionTitle}
              </Type>
            </Appear>
            <Appear delay={200}>
              <Type preset="textExtraLarge">{description}</Type>
            </Appear>
          </DescriptionContainer>
        </Appear>
      </Section>
      <SectionsRenderer sections={sections} pageConfig={config} />
      <Metadata metadata={metadata} />
    </Page>
  )
}

export default HotelRoomPage
