import { type ComponentState, useCallback, useRef } from 'react'

type UseViewObserver = (
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit,
  externalState?: ComponentState[]
) => (node: Element | null) => void

export const useViewObserver: UseViewObserver = (
  callback,
  options,
  externalState
) => {
  const target = useRef<Element | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)
  const { root, rootMargin, threshold } = options || {}
  const dependencies = externalState || []

  const setTarget = useCallback(
    node => {
      if (target.current && observer.current) {
        observer.current.unobserve(target.current)
        observer.current.disconnect()
        observer.current = null
      }

      if (node) {
        observer.current = new IntersectionObserver(callback, {
          root,
          rootMargin,
          threshold,
        })
        observer.current.observe(node)
        target.current = node
      }
    },
    [target, root, rootMargin, threshold, callback, ...dependencies]
  )

  return setTarget
}
