import Script from 'next/script'

const CarRentalSection = () => (
  <>
    <div
      id="carwayFrame-fbc8225d-956c-4f56-ad3d-a8cdc2c29db3"
      style={{ height: '100%' }}
    />
    <Script id="car-rental-script">
      {`(function(d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s);
		js.id = id;
		js.src = "https://www.holdurcarrental.is/static/local/carway/js/widget.js";
		js.onload = function() {
			try {
				carway.widget.init({
					iframeHost: 'https://www.holdurcarrental.is',
					hash: 'fbc8225d-956c-4f56-ad3d-a8cdc2c29db3',
          language: 'en',

				});
			}
			catch(e) {
				console.error(e);
			}
		};
		fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'carway-widget'))`}
    </Script>
  </>
)

export default CarRentalSection
