import { withRouter } from '../context/withRouter'

const isLinkInternal = to => {
  // Asset links from Contentful begin with `//`. For example:
  //
  //    "//images.ctfassets.net/..."
  //
  // Which is done so that the href is independent of the protocol (e.g. `https:`, `http:`).
  if (to.substr(0, 2) === '//') return false

  // If it's a relative url such as '/path', 'path' and does not contain a protocol we can assume it is internal.
  if (to.indexOf('://') === -1) return true

  return false
}

// Next link that can handle external urls
const LinkBase = ({
  to = '/',
  Link: NextLink,
  shallow,
  replace,
  scroll = false,
  active = false,
  children,
  ...linkProps
}) => {
  const isInternal = isLinkInternal(to)

  if (isInternal) {
    return (
      <NextLink
        href={to}
        shallow={shallow}
        replace={replace}
        scroll={scroll}
        data-active={active || undefined}
        {...linkProps}
      >
        {children}
      </NextLink>
    )
  } else {
    return (
      <a
        data-active={active}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...linkProps}
      >
        {children}
      </a>
    )
  }
}

export const Link = withRouter(LinkBase)

const NavLinkBase = ({ router, subNavUrl, to, ...linkProps }) => {
  const isActivePath = router.asPath.indexOf(to) === 0
  const active = subNavUrl ? subNavUrl === to : isActivePath

  return <Link to={to} {...linkProps} active={active} />
}

export const NavLink = withRouter(NavLinkBase)
