import { useEffect, useRef } from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import Player from '@vimeo/player'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { gradients } from 'bl-common/src/constants/gradients'
import { modularScale } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { BackgroundImage } from 'bl-common/src/elements/BackgroundImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Appear } from 'bl-common/src/units/Appear'
import { ResponsiveParallax } from 'bl-common/src/units/ResponsiveParallax'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

type TextContentProps = {
  textContentSide?: string
}

type ImageContentProps = {
  textContentSide?: string
  degrees?: string
  color1?: string
  color2?: string
}

type TwoImagesContentProps = {
  degrees?: string
  color1?: string
  color2?: string
}

type TwoImagesContainerProps = {
  textContentSide?: string
}

type ImageColumnProps = {
  images?: any
  textContentSide?: any
  gradient?: any
  imageIsTall?: boolean
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${media.md(css`
    flex-wrap: nowrap;
  `)}
`

const TextContent = styled.div<TextContentProps>`
  flex: 0 0 100%;
  margin-bottom: 32px;

  ${media.md(css`
    flex: 0 1 50%;
    margin-bottom: 0;
    margin-top: ${({ theme }) => theme.spacing[1]};

    ${({ textContentSide }: TextContentProps) =>
      textContentSide === 'right'
        ? css`
            padding-left: ${between(64 / (modularScale * 2), 64)};
          `
        : css`
            padding-right: ${between(64 / (modularScale * 2), 64)};
          `}
  `)};

  p + p {
    padding-top: ${({ theme }) => theme.spacing[1.5]};
  }
`

const ImageContent = styled.div<ImageContentProps>`
  position: relative;
  flex: 0 0 100%;
  margin-top: ${between(64 / modularScale, 64)};

  ${media.md(css`
    ${({ textContentSide }: ImageContentProps) =>
      textContentSide === 'right' &&
      css`
        order: -1;
      `}
  `)};

  ${media.md(css`
    flex: 0 1 45%;
    margin-left: ${between(64 / modularScale, 64)};
    margin-right: ${between(64 / modularScale, 64)};
    margin-top: 0;
    align-self: flex-start;
  `)};

  &::after {
    content: '';
    height: 100%;
    opacity: 0.5;
    position: absolute;
    top: ${between(-32 / modularScale, -32)};
    width: 100%;

    ${({ textContentSide }) =>
      textContentSide === 'right'
        ? css`
            left: ${between(-64 / modularScale, -64)};
          `
        : css`
            right: ${between(-64 / modularScale, -64)};
          `}
    ${(props: ImageContentProps) => css`
      background: linear-gradient(
        ${props.color1 || gradients.supportSilver[0]},
        ${props.color2 || gradients.supportSilver[1]}
      );
    `};
  }
`

const SingleImageWrapper = styled(ResponsiveParallax)`
  position: relative;
  z-index: ${zIndex.above};
`

const SingleImage = styled(BackgroundImage)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: ${zIndex.above};
  padding-bottom: 133%;
`

const TwoImagesContainer = styled.div<TwoImagesContainerProps>`
  flex: 0 1 100%;
  margin-top: ${between(32 / modularScale, 32)};
  position: relative;

  ${media.md(css`
    ${({ textContentSide }: TwoImagesContainerProps) =>
      textContentSide === 'right'
        ? css`
            margin-left: 0;
            margin-right: ${between(64 / modularScale, 64)};
            order: -1;
          `
        : css`
            margin-left: ${between(64 / modularScale, 64)};
            margin-right: 0;
          `}
  `)};

  ${media.md(css`
    flex: 0 1 50%;

    margin-top: 0;
  `)};
`

const TwoImagesContent = styled.div<TwoImagesContentProps>`
  position: relative;
  height: ${between(520 / modularScale, 520)};

  &::after {
    content: '';
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 65.56%;

    ${props => css`
      background: linear-gradient(
        ${props.color1 || gradients.supportSilver[0]},
        ${props.color2 || gradients.supportSilver[1]}
      );
    `};
  }
`

const ImagePairWrapper = styled(ResponsiveParallax)`
  height: 100%;
  position: absolute;
  width: 45%;
  z-index: ${zIndex.above};

  &:nth-child(1) {
    left: 0;
    top: ${between(-20, -34)};
  }

  &:nth-child(2) {
    bottom: ${between(-20, -34)};
    right: 0;
  }
`

const ImagePair = styled(BackgroundImage)`
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
`

const LogoImage = styled.div`
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  margin-top: ${({ theme }) => theme.spacing[2.5]};

  ${media.md(css`
    width: 50%;
  `)};
`

const VideoWrapper = styled(ResponsiveParallax)`
  position: relative;
  z-index: ${zIndex.above};
`

const VideoWrap = styled.div`
  position: relative;

  > iframe {
    width: 100%;
  }
`

const documentOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type
        preset="headlineLarge"
        as="h2"
        bottom={{ xs: 1, md: 2 }}
        top={{ xs: 1, md: 1 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Type
        preset="headlineMedium"
        as="h3"
        bottom={{ xs: 1, md: 2 }}
        top={{ xs: 1, md: 1 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Type
        preset="labelLarge"
        as="h4"
        bottom={{ xs: 1, md: 1 }}
        top={{ xs: 2, md: 2.5 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <Type
        preset="subtitle"
        as="p"
        bottom={{ xs: 1, md: 1 }}
        top={{ xs: 1, md: 2 }}
        weight="normal"
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <Type
        preset="textSmall"
        as="p"
        bottom={{ xs: 0.5, md: 0.5 }}
        top={{ xs: 0.5, md: 0.5 }}
        weight="normal"
      >
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type preset="textLarge">{children}</Type>
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { fields } = node.data.target
      return (
        <LogoImage style={{ backgroundImage: `url(${fields.file.url})` }} />
      )
    },
  },
}

const TwoImageColumn = ({
  images,
  textContentSide,
  gradient,
}: ImageColumnProps) => (
  <TwoImagesContainer textContentSide={textContentSide}>
    <TwoImagesContent
      color1={get(gradient, 'color1')}
      color2={get(gradient, 'color2')}
      degrees={get(gradient, 'degrees')}
    >
      {images.map((image, index) => (
        <ImagePairWrapper
          key={index}
          from={index === 0 ? -2 : 2}
          to={index === 0 ? 2 : -2}
          disabledMobile
        >
          <ImagePair key={image.sys ? image.sys.id : image} image={image} />
        </ImagePairWrapper>
      ))}
    </TwoImagesContent>
  </TwoImagesContainer>
)

const OneImageColumn = ({
  images,
  textContentSide,
  gradient,
  imageIsTall,
}: ImageColumnProps) => (
  <ImageContent
    textContentSide={textContentSide}
    color1={get(gradient, 'color1')}
    color2={get(gradient, 'color2')}
    degrees={get(gradient, 'degrees')}
  >
    {images.map(image => (
      <SingleImageWrapper
        key={image.sys ? image.sys.id : image}
        from={-1}
        to={3}
        disabledMobile
      >
        <SingleImage image={image} useAspectRatio={!imageIsTall} />
      </SingleImageWrapper>
    ))}
  </ImageContent>
)

const Video = ({ textContentSide, gradient, videoId, videoWithControls }) => {
  const video = useRef(null)
  const player = useRef(null)

  useEffect(() => {
    player.current = new Player(video.current, {
      id: videoId,
      background: !videoWithControls,
      responsive: true,
    })
  })

  return (
    <ImageContent
      textContentSide={textContentSide}
      color1={get(gradient, 'color1')}
      color2={get(gradient, 'color2')}
      degrees={get(gradient, 'degrees')}
    >
      <VideoWrapper from={-1} to={3} disabledMobile>
        <VideoWrap ref={video} />
      </VideoWrapper>
    </ImageContent>
  )
}

export const SplitContent = ({
  images,
  textContentSide,
  gradient,
  content,
  imageIsTall,
  videoId,
  videoWithControls = false,
}) => {
  const ImageColumn = images
    ? images.length === 1
      ? OneImageColumn
      : TwoImageColumn
    : null

  return (
    <Appear observer>
      <Container>
        <TextContent textContentSide={textContentSide}>
          <Appear>
            {!!content && (
              <RichTextRenderer
                document={content}
                customOptions={documentOptions}
              />
            )}
          </Appear>
        </TextContent>
        {!videoId && ImageColumn && (
          <ImageColumn
            images={images}
            textContentSide={textContentSide}
            gradient={gradient}
            imageIsTall={imageIsTall}
          />
        )}
        {!!videoId && (
          <Video
            textContentSide={textContentSide}
            gradient={gradient}
            videoId={videoId}
            videoWithControls={videoWithControls}
          />
        )}
      </Container>
    </Appear>
  )
}

export default SplitContent
