import { enUS } from 'date-fns/locale/en-US'
import { is } from 'date-fns/locale/is'
import { formatInTimeZone } from 'date-fns-tz'

import {
  INTERNAL_STRING_FORMAT,
  parseDateString,
  validateAndFormatDateString,
} from '../date'

interface FormatDateOptions {
  locale?: 'is' | 'en'
  withDayOfMonth?: boolean
  withMonth?: boolean
  withWeekday?: boolean
  withYear?: boolean
  displayLongMonth?: boolean
  displayLongWeekday?: boolean
}

const DEFAULT_FORMAT_DATE_OPTIONS: FormatDateOptions = {
  locale: 'en',
  withWeekday: false,
  withDayOfMonth: true,
  withMonth: true,
  withYear: false,
  displayLongMonth: false,
  displayLongWeekday: false,
}

const getFormatString = (options: FormatDateOptions) => {
  const formatStringParts = []

  if (options.withDayOfMonth) {
    formatStringParts.push(options.locale === 'is' ? 'd.' : 'd')
  }

  if (options.withMonth) {
    formatStringParts.push(options.displayLongMonth ? 'MMMM' : 'MMM')
  }

  if (options.withWeekday) {
    formatStringParts.splice(0, 0, options.displayLongWeekday ? 'iiii' : 'eee')
  }

  if (options.withYear) {
    formatStringParts.push('yyyy')
  }

  return formatStringParts.join(' ')
}

export const displayDate = (
  date: Date | string,
  options = DEFAULT_FORMAT_DATE_OPTIONS
) => {
  const mergedOptions = {
    ...DEFAULT_FORMAT_DATE_OPTIONS,
    ...options,
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date
  const formatString = getFormatString(mergedOptions)

  return formatDateInUTC(dateObj, formatString, mergedOptions.locale)
}

/**
 *
 * @param date string formatted as {INTERNAL_STRING_FORMAT}
 * @param outputFormat output format
 */
export const formatDateString = (
  date: string | Date,
  outputFormat: string = INTERNAL_STRING_FORMAT,
  locale = 'en'
) => {
  if (!date) {
    return
  }

  const parsed = typeof date === 'string' ? parseDateString(date) : date

  return formatDateInUTC(parsed, outputFormat, locale)
}

/**
 *
 * @param date Date or string formatted as 'yyyy-MM-dd', 'yyyy-MM-ddThh:mm:ss', 'yyyy-MM-ddThh:mm:ss.mmm' or 'yyyy-MM-ddThh:mm:ssZ'
 * @param outputFormat output format - defaults to 'yyyy-MM-dd'
 * @param locale locale for formatting - defaults to 'en'
 * @returns Formatted date string in UTC
 */
export const formatDateInUTC = (
  date: Date | string,
  outputFormat = 'yyyy-MM-dd',
  locale = 'en'
): string | undefined => {
  if (!date) {
    return
  }

  const transformedDate =
    typeof date === 'string' ? validateAndFormatDateString(date) : date
  if (!transformedDate) {
    return
  }

  const dateObj =
    typeof transformedDate === 'string'
      ? new Date(transformedDate)
      : transformedDate
  return formatInTimeZone(dateObj, 'UTC', outputFormat, {
    locale: locale === 'en' ? enUS : is,
  })
}

// This is a stricter version of formatDateInUTC that throws an error if the date is invalid
export const formatDateInUTCStrict = (
  date: Date | string,
  outputFormat = 'yyyy-MM-dd',
  locale = 'en'
): string => {
  const formattedDate = formatDateInUTC(date, outputFormat, locale)
  if (!formattedDate) {
    throw new Error('Invalid date')
  }
  return formattedDate
}
