const isServer = typeof window === 'undefined'

type VercelEnvironment = 'development' | 'preview' | 'production'
type MainEnvironments = 'development' | 'production'

export const getCurrentEnvironment = (): MainEnvironments => {
  if (isServer) {
    const vercelEnv = process.env.VERCEL_ENV as VercelEnvironment

    return vercelEnv === 'preview' ? 'development' : vercelEnv
  }

  if (window.location.origin.includes('bluelagoon.com')) {
    return 'production'
  }

  return 'development'
}

// We disable pre-build on development deployments unless specifically configured otherwise
// This is to speed up development deployments by not prebuilding all pages
export const disablePrebuild =
  process.env.NEXT_PUBLIC_PREBUILD_PAGES !== 'true' &&
  getCurrentEnvironment() === 'development'
