import { createContext, type ReactNode, useContext, useMemo } from 'react'
import { useSessionStorageValue } from '@react-hookz/web'
import { v4 as uuidv4 } from 'uuid'

import type { Cart } from 'bl-graphql'

type BookingSessionCart = Cart & {
  package?: string
  bookingNr: string
  primaryGuest: {
    email: string
    firstName: string
    lastName: string
    phoneNo: string
  }
  transportationInfo?: {
    dropoffArea?: string
    dropoffLocationName?: string
    pickupArea?: string
    pickupLocationName?: string
  }
}

const BookingSessionContext = createContext<{
  sessionId: string
  previousConfirmedCart?: BookingSessionCart
  setPreviousConfirmedCart: React.Dispatch<
    React.SetStateAction<BookingSessionCart>
  >
}>({
  sessionId: Date.now().toString(),
  setPreviousConfirmedCart: () => {
    /* intentionally empty */
  },
})

export const useBookingSessionContext = () => useContext(BookingSessionContext)

export const BookingSessionContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [previousConfirmedCart, setPreviousConfirmedCart] =
    useSessionStorageValue<BookingSessionCart>('CONFIRMATION_SCREEN')

  const sessionId = useMemo(() => {
    let id = Date.now().toString()
    if (typeof window !== 'undefined') {
      id = sessionStorage.getItem('session-id')

      if (id == null) {
        id = uuidv4()
        sessionStorage.setItem('session-id', id)
      }
    }

    return id
  }, [])

  const value = useMemo(
    () => ({
      sessionId,
      previousConfirmedCart,
      setPreviousConfirmedCart,
    }),
    [sessionId, previousConfirmedCart]
  )

  return (
    <BookingSessionContext.Provider value={value}>
      {children}
    </BookingSessionContext.Provider>
  )
}
