import { darken, lighten } from 'polished'
import { createGlobalStyle, css } from 'styled-components'

import { colors } from '../../constants/colors'
import type { PartialBookingEngine } from '../../styles/types'
import { media } from '../../utils/media'

export const DatePickerStyles = createGlobalStyle<{
  themeStyle?: PartialBookingEngine['inlineDatePicker']
}>`
.react-datepicker {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${props => props.themeStyle?.backgroundColor ?? colors.white}  ;
  line-height: 1.125em;
  padding: 0;
  border: none;
  color: ${props => props.themeStyle?.textColor ?? '#454647'}   ;
  border-radius: 0;

  ${media.md(css`
    flex-direction: row;
  `)}
}

.react-datepicker__month-container {
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing[2]};

  ${media.md(css`
    width: 50%;

    &:nth-child(odd) {
      padding-left: ${({ theme }) => theme.spacing[2]};
    }
    &:nth-child(even) {
      padding-right: ${({ theme }) => theme.spacing[2]};
    }
  `)}
}

.react-datepicker__header {
  background: ${props => props.themeStyle?.backgroundColor ?? colors.white} ;
  border: none;
  text-align: left;

  ${media.md(css`
    text-align: center;
  `)}
}

.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__navigation-wrapper {
  margin-bottom: ${({ theme }) => theme.spacing[1]};

  ${media.md(css`
    margin-bottom: ${({ theme }) => theme.spacing[1]};
  `)}
}

.react-datepicker__navigation--previousButton,
.react-datepicker__navigation--nextButton {
  display: none;
  cursor: pointer;
  ${media.md(css`
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
  `)}
}

.react-datepicker .react-datepicker__month-container:nth-child(2) .react-datepicker__navigation--previousButton,
.react-datepicker .react-datepicker__month-container:last-child .react-datepicker__navigation--nextButton {
  ${media.md(css`
    visibility: visible;
  `)}
}

.react-datepicker__navigation--previousButton {
    margin-left: -4px;
}
.react-datepicker__navigation--nextButton {
    margin-right: -4px;
}
.react-datepicker__day-names, .react-datepicker__week, .react-datepicker__navigation-wrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: space-between;
}
.react-datepicker__day-name {
  text-transform: uppercase;
  padding: .5em;
  font-size: 12px;
  font-weight: bold;
  min-height: 40px;
  min-width: 40px;
  width: 100%;
  opacity: 0.75;
  color: ${props => props.themeStyle?.textColor ?? colors.midGrey} ;
  margin: 0;

  text-align: center;
}

.react-datepicker__day {
  min-height: 40px;
  min-width: 40px;
  width: 100%;
  padding: .5em;
  margin: 0;

  font-size: 12px;
  color: ${props => props.themeStyle?.textColor ?? colors.midGrey} ;

  &:hover {
    border-radius: 0;
    background-color: ${props =>
      props?.themeStyle?.dayHoverColor ?? colors.lightGrey};
  }
}

.react-datepicker__day--blocked {
  color: ${lighten(0.5, colors.midGrey)};
  pointer-events: none;
  cursor: not-allowed;

  & > span {
    text-decoration: line-through;
  }
}

.react-datepicker__day--checkout-only {
  color: ${lighten(0.2, colors.midGrey)};
  cursor: not-allowed;
  &:active {
    pointer-events: none;
    background-color: ${darken(0.1, colors.lightGrey)};
  }
}

.react-datepicker__day--outside-month,
.react-datepicker__day--in-range.react-datepicker__day--outside-month
{
  opacity: 0;
  background: ${props => props.themeStyle?.backgroundColor ?? colors.white} 
}

.react-datepicker__month {
  text-align: center;
  margin: 0;

  ${media.md(css`
    text-align: center;
  `)}
}

.react-datepicker__current-month {
  font-weight: 700;
  font-size: 14px;
  line-height: 0.5;
  color: ${props => props.themeStyle?.textColor ?? colors.midGrey} ;
  pointer-events: none;
  margin: 0.166rem;
  padding: 0.5em;
  grid-column: 1 / span 7;

  ${media.md(css`
    font-size: 20px;
    line-height: 1.6;
    padding: 0;
    margin: 0;
    grid-column: 2 / span 5;
  `)}
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-range:hover {
  border-radius: 0%;
  background-color: ${props =>
    props.themeStyle?.dayColorDark ?? colors.deepBlue};
  color: ${colors.white};
}

// Datepicker has a bug where it shows the selected day in all months.
// This selector ensures that the day will not be rendered as selected if it's blocked.
.react-datepicker__day--blocked.react-datepicker__day--keyboard-selected {
  color: ${lighten(0.5, colors.midGrey)};
  pointer-events: none;
  cursor: not-allowed;

  & > span {
    text-decoration: line-through;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0%;
  background-color: ${props =>
    props?.themeStyle?.dayHoverColor ?? colors.lightGrey};
  color: ${props => props?.themeStyle?.textColor ?? colors.midGrey};

}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: ${props =>
    props?.themeStyle?.dayHoverColor ?? colors.lightGrey};
  border-radius: 0%;
  color: #454647;
}

.react-datepicker__day.checkoutOnly.react-datepicker__day--selected {
  background-color: green;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--,
.react-datepicker__day--in-range)
 {
  border-radius: 0%;
  background-color: ${props =>
    props?.themeStyle?.dayColorLight ?? colors.fountainBlue};
  color: ${colors.white};

  &:hover {
    background-color: ${props =>
      props?.themeStyle?.dayColorDark ?? colors.deepBlue};
  }
}

.react-datepicker__day--in-selecting-range.react-datepicker__day--:not(
.react-datepicker__day--selected
)
 {
  border-radius: 0%;
  background-color: ${colors.white};
  text-decoration: line-through;
  color: ${lighten(0.5, colors.midGrey)};
  pointer-events: none;
  cursor: not-allowed;
}


.react-datepicker__day--disabled {
  pointer-events: none;
  opacity: 0.3;

  &:hover {
    border-radius: 0;
    background-color: ${colors.white};
  }
}

.react-datepicker__day--excluded {
  pointer-events: none;
  color: ${colors.midGrey};
  text-decoration: line-through;

  &:hover {
    border-radius: 0;
    background-color: ${colors.white};
  }
}

.react-datepicker__day--in-range.react-datepicker__day--excluded {
  background: ${props =>
    props?.themeStyle?.dayColorLight ?? colors.fountainBlue};
}

.react-datepicker__day--selected,
.react-datepicker__day--selected.react-datepicker__day--blocked {
  pointer-events: none;
  border-radius: 0%;
  color: ${colors.white};
  background-color: ${props =>
    props?.themeStyle?.dayColorDark ?? colors.deepBlue};
  &:hover {
    border-radius: 0%;
  }

  & > span {
    text-decoration: unset;
  }
}

.react-datepicker__day--selected, .react-datepicker__day--in-range {
  border-radius: 0%;
  color: ${colors.white};
  background-color: ${props =>
    props?.themeStyle?.dayColorDark ?? colors.deepBlue};
  &:hover {
    border-radius: 0%;
  }
}

.react-datepicker__day--in-range {
  color: ${colors.white};
  background: ${props =>
    props?.themeStyle?.dayColorLight ?? colors.fountainBlue};

  &:hover {
    background: ${props => props?.themeStyle?.dayColorDark ?? colors.deepBlue};
  }
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--range-start.react-datepicker__day--blocked
 {
  background: ${props => props?.themeStyle?.dayColorDark ?? colors.deepBlue};
  color: ${colors.white};

  &:hover {
    color: ${colors.white};
    background: ${props =>
      darken(0.05, props?.themeStyle?.dayColorDark ?? colors.deepBlue)};
  }
}
`
