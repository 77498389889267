import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import {
  gridOffsetFrom,
  gridOffsetTo,
  modularScale,
} from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { between } from '../../utils/between'
import { media } from '../../utils/media'
import { mixins } from '../../utils/mixins'

type NextPreviousProps = {
  pageCount: number
  pageMargin: number
  currentPage: number
  scrollBy: (direction?: number) => void
  touchScrolling: any
  callback: () => void
}

type ButtonProps = {
  isInactive?: boolean
  direction?: string
  pageMargin?: any
}

const Button = styled.button<ButtonProps>`
  appearance: none;
  background: ${colors.white};
  border: 0;
  bottom: 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-sizing: content-box;
  cursor: pointer;
  display: flex;
  margin: auto;
  opacity: 1;
  padding: 0;
  pointer-events: initial;
  position: absolute;
  top: 0;
  transform-origin: center;
  transition:
    transform ${durations.short}ms cubic-bezier(0.27, 0.17, 0.47, 1.43),
    opacity ${durations.short}ms;
  justify-content: center;
  z-index: ${zIndex.above};
  height: ${({ theme }) => theme.spacing[2.5]};
  width: ${({ theme }) => theme.spacing[2.5]};

  &::before {
    content: '';
    ${mixins.increaseClickArea()};
  }

  ${props =>
    props.direction === 'right' &&
    css`
      animation: jumpAroundRight ${durations.long * 2}ms
        cubic-bezier(0.27, 0.17, 0.47, 1.43) 0s infinite normal both running;
    `};

  ${props =>
    !!props.isInactive &&
    css`
      cursor: default;
      opacity: 0;
      pointer-events: none;
    `};

  ${props =>
    props.direction === 'right' &&
    css`
      right: ${between(-gridOffsetFrom * 0.5, -gridOffsetTo * 0.5)};
      transform: translateX(50%);
    `};

  ${props =>
    props.direction === 'left' &&
    css`
      left: ${between(-gridOffsetFrom * 0.5, -gridOffsetTo * 0.5)};
      transform: translateX(-50%);
    `};

  @keyframes jumpAroundRight {
    0% {
      transform: translateX(50%) scale(1);
    }
    50% {
      transform: translateX(50%) scale(1.1);
    }
    100% {
      transform: translateX(50%) scale(1);
    }
  }
`

const CaretSvgContainer = styled.div`
  ${mixins.betweenProperty('width', 24 / modularScale, 24)};
  ${mixins.betweenProperty('height', 12 / modularScale, 12)};
  margin: auto 0;
`

const CaretSvg = styled.svg`
  ${mixins.betweenProperty('width', 24 / modularScale, 24)};
  ${mixins.betweenProperty('height', 12 / modularScale, 12)};
  pointer-events: none;
`

const CaretPath = styled.path`
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke: #50a7ba;
`

const Container = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 42px;
  z-index: ${zIndex.above2};
  pointer-events: none;

  ${media.md(css`
    display: block;
  `)};
`

const Caret = ({ direction = 'right' }) => (
  <CaretSvgContainer
    style={{ transform: direction === 'left' ? 'scale(-1)' : null }}
  >
    <CaretSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14">
      <CaretPath d="M1 1l5.8 5.8L1 12.5" />
    </CaretSvg>
  </CaretSvgContainer>
)

export const NextPrevious = ({
  pageCount,
  pageMargin,
  currentPage,
  scrollBy,
  touchScrolling,
  callback = () => {
    /* intentionally empty */
  },
}: NextPreviousProps) => {
  const leftDisabled = currentPage === 0
  const rightDisabled = currentPage === pageCount - 1

  const onClick = direction => {
    scrollBy(direction)
    callback()
  }

  return (
    <Container style={{ display: touchScrolling ? 'none' : 'block' }}>
      <Button
        type="button"
        direction="left"
        onClick={() => !leftDisabled && onClick(-1)}
        isInactive={leftDisabled}
        pageMargin={pageMargin}
        aria-label="Previous page"
      >
        <Caret direction="left" />
      </Button>

      <Button
        type="button"
        direction="right"
        onClick={() => !rightDisabled && onClick(1)}
        isInactive={rightDisabled}
        pageMargin={pageMargin}
        aria-label="Next page"
      >
        <Caret direction="right" />
      </Button>
    </Container>
  )
}
