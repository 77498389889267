import styled, { css } from 'styled-components'

import type { I404Fields } from 'contentful-shared'
import { HelpfulLinks } from 'bl-common/src/elements/HelpfulLinks'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Section } from 'bl-common/src/units/Section/Section'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'

import { withTransition } from '../utils/withTransition'
import { Metadata } from './Metadata'
import Page from './Page'

type ErrorPageProps = {
  page: {
    fields: I404Fields
  }
}

type ContainerProps = {
  background?: string
}

const Container = styled.div<ContainerProps>`
  padding-top: 120px;
  min-height: 90vh;
  ${({ background }) =>
    background &&
    css`
      background-image: url(${background});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `};
`

const ErrorPageBase = ({
  page: {
    fields: { heading, title, text, backgroundImage, links },
  },
}: ErrorPageProps) => (
  <Page>
    <Metadata title={title} />
    <SmartImage image={backgroundImage}>
      {imageProps => (
        <Container background={imageProps}>
          <Section>
            <Type preset="headlineLarge" bottom={1.5} top={{ md: 8 }}>
              {heading}
            </Type>
            <Type preset="subtitle" maxWidth={700} bottom={6} multiline>
              {text}
            </Type>
            {links && (
              <HelpfulLinks
                title={links.fields.title}
                links={links.fields.links}
              />
            )}
          </Section>
        </Container>
      )}
    </SmartImage>
  </Page>
)

export const ErrorPage = withTransition(ErrorPageBase)
