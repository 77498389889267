import type { Asset } from 'contentful'
import styled from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { between } from 'bl-common/src/utils/between'
import { mediaMaxObj, mediaObj } from 'bl-common/src/utils/media'

type HighlightsThumbnailProps = {
  image: Asset
  name: string
  onClick?: () => void
  link?: string
}

const ThumbnailWrap = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[1],

  [mediaObj.md]: {
    gap: theme.spacing[1.5],
    '& + &': {
      marginLeft: 40,
    },
  },
}))

const ThumbnailClickable = styled.button({
  textAlign: 'left',
})

const Thumbnail = styled.div(({ theme }) => ({
  backgroundSize: 'cover',
  borderRadius: 8,
  height: 220,
  overflow: 'hidden',
  position: 'relative',

  [mediaObj.md]: {
    width: theme.spacing[13],
    height: 'auto',
    paddingBottom: '140%',
    maxWidth: 230,
  },

  [mediaObj.lg]: {
    width: theme.spacing[13],
    height: 'auto',
    paddingBottom: '140%',
    maxWidth: between(230, 300, {
      fromWidth: breakpoints.lg,
      toWidth: breakpoints.xl,
    }),
  },
}))

const Overlay = styled.div({
  background: 'rgba(0, 0, 0, 0.1)',
  borderRadius: 8,
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  transition: `opacity ${durations.short}ms ease-out`,
  zZIndex: zIndex.above,

  [mediaObj.md]: {
    background: 'rgba(0, 0, 0, 0.5)',
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
})

const OverlayIcon = styled.div({
  background: 'rgba(0,0,0,0.6)',
  borderRadius: '50%',
  bottom: 0,
  height: 40,
  left: 0,
  margin: 'auto',
  right: 0,
  position: 'absolute',
  top: 0,
  width: 40,
  zIndex: zIndex.above,

  [mediaObj.md]: {
    width: 64,
    height: 64,
  },

  '> svg': {
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,

    [mediaMaxObj.md]: {
      width: 12,
    },
  },
})

const ThumbnailText = styled(Type)({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
})

const ThumbnailLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    [Arrow]: {
      transform: `translateX(${theme.spacing[0.5]})`,
    },
  },
}))

const Arrow = styled.div(({ theme }) => ({
  color: colors.deepBlue,
  marginLeft: theme.spacing[0.5],
  transition: 'transform 300ms',
  fontWeight: 'var(--font-weight-bold)',
}))

const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 24"
    width="16"
    transform="translate(2,0)"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4.26119 22.3503l10.82841-8.7981c.9851-.8004.9851-2.304 0-3.1044L4.26119 1.64971C2.95416.58775 1 1.51787 1 3.20194V20.7981c0 1.684 1.95416 2.6142 3.26119 1.5522z"
    />
  </svg>
)

const OptionalLinkWrapper = ({
  link,
  children,
}: {
  link: HighlightsThumbnailProps['link']
  children: React.ReactNode
}) =>
  link ? (
    <ThumbnailLink to={link}>
      {children}
      <Arrow>→</Arrow>
    </ThumbnailLink>
  ) : (
    <>{children}</>
  )

export const HighlightsThumbnail = ({
  image,
  name,
  onClick,
  link,
}: HighlightsThumbnailProps) => (
  <ThumbnailWrap>
    <ThumbnailClickable
      as={onClick ? 'button' : 'div'}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
      aria-label={onClick ? `Play ${name}` : undefined}
    >
      <Thumbnail>
        <ContentfulImage image={image} />
        {onClick && (
          <Overlay>
            <OverlayIcon>
              <PlayIcon />
            </OverlayIcon>
          </Overlay>
        )}
      </Thumbnail>
    </ThumbnailClickable>
    <OptionalLinkWrapper link={link}>
      <ThumbnailText preset="text" weight="medium" color={colors.black}>
        {name}
      </ThumbnailText>
    </OptionalLinkWrapper>
  </ThumbnailWrap>
)
