export const formatButton = button =>
  button && {
    id: button.sys.id,
    paddingSize: button.fields.paddingSize,
    preset: button.fields.color,
    to: button.fields.link,
    text: button.fields.text,
    noHover: !button.fields.hoverEffect,
    textColor: button.fields.textColor,
  }

export const formatGradient = gradient =>
  gradient && {
    color1: gradient.fields.color1,
    color2: gradient.fields.color2,
    degrees: gradient.fields.degrees || '0deg',
    opacity: gradient.fields.opacity,
  }

export const formatList = list =>
  list?.fields.listItems && formatListItems(list.fields.listItems)

export const formatListItems = items =>
  items?.map(item => ({
    item: item.fields.item,
    itemStyle: item.fields.itemStyle,
  }))

export const formatPriceRef = priceRef => priceRef?.fields.isk

export const formatInfoColumn = column =>
  column && {
    title: column.fields.title,
    text: column.fields.text,
    gradient: column.fields.gradient && formatGradient(column.fields.gradient),
    image: column.fields.image,
  }
