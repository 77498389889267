import get from 'lodash/get'
import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { modularScale } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Button } from 'bl-common/src/elements/Button/Button'
import { KeyValueList } from 'bl-common/src/elements/KeyValueList/KeyValueList'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Appear } from 'bl-common/src/units/Appear'
import { SmartImage } from 'bl-common/src/units/SmartImage/SmartImage'
import { between } from 'bl-common/src/utils/between'
import { media, mediaMax } from 'bl-common/src/utils/media'
import { arrToKeyValue } from 'bl-utils/src/arrToKeyValue'
import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

type FrameProps = {
  gradient?: any
  offset?: any
}

type PanelProps = {
  image?: string
  isAbove?: boolean
  darkText?: boolean
  gradient?: any
  minHeight?: number
}

const Container = styled.div``

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Frame = styled.div<FrameProps>`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: ${zIndex.above};

  ${media.md(css`
    padding: ${between(40 / modularScale, 40)};
    padding-bottom: ${between(120 / modularScale, 120)};
    margin: ${({ theme }) => `0 ${theme.spacing[-2.5]}`};
    flex-wrap: nowrap;

    &::after {
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      z-index: ${zIndex.behind};
      ${({ gradient }: FrameProps) =>
        gradient &&
        css`
          background: linear-gradient(
            ${gradient.degrees || 'to right'},
            ${gradient.color1},
            ${gradient.color2}
          );
        `};
      ${({ offset, theme }: FrameProps & { theme: any }) =>
        offset && `${offset}: ${theme.spacing[5]};`};

      ${({ offset }: FrameProps) =>
        offset === 'bottom' &&
        css`
          ${Container} {
            padding-bottom: ${({ theme }) => theme.spacing[5]};
          }
        `};
    }
  `)};
`

const Panel = styled.div<PanelProps>`
  background-color: ${colors.white};
  background-image: ${({ image }) => image && `url(${image})`};
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: ${colors.white};
  flex: 0 1 auto;
  position: relative;
  width: 100%;
  z-index: ${({ isAbove }) => (isAbove ? 1 : 0)};
  min-height: 260px;
  padding: 48px 36px;

  ${media.md(css`
    padding: ${({ theme }) => `${theme.spacing[5]} ${theme.spacing[4]}`};
  `)} ${({ darkText }) =>
    darkText &&
    css`
      color: ${colors.dark};
    `};

  ${({ gradient, image }) =>
    !image &&
    gradient &&
    css`
      background: linear-gradient(
        ${gradient.degrees || 'to right'},
        ${gradient.color1},
        ${gradient.color2}
      );
    `};

  &::after {
    ${({ image, gradient }) =>
      image &&
      gradient &&
      css`
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: linear-gradient(
          ${gradient.degrees || 'to right'},
          ${rgba(gradient.color1, 0.36)},
          ${rgba(gradient.color2, 0.36)}
        );
      `};
  }

  &:first-child {
    ${media.md(css`
      ${Content} {
        padding-right: ${({ theme }) => theme.spacing[5]};
      }
    `)};
  }

  &:not(:first-child) {
    ${media.md(css`
      margin-left: ${({ theme }) => theme.spacing[-5]};
      top: ${({ theme }) => theme.spacing[5]};
    `)};

    ${mediaMax.md(css`
      ${({ image }: PanelProps) =>
        image &&
        css`
          order: -1;
        `};
    `)};
  }

  ${media.md(css`
    ${({ minHeight }: PanelProps) =>
      minHeight &&
      `min-height: ${between(minHeight / modularScale, minHeight)}`};
    padding: ${({ theme }) =>
      `${theme.spacing[7.5]} ${theme.spacing[4]} ${theme.spacing[5]}`};
  `)};
`

const ButtonWrap = styled.div`
  display: flex;
  margin-top: auto;
  flex-direction: column;

  ${media.md(css`
    flex-direction: row;
    & > * {
      flex: 0 1 auto;
    }
  `)};
`

const linkStyle = css`
  font-size: 14px;
  text-transform: uppercase;
`

export const PanelPair = ({
  frameGradient,
  panels = [],
  frameOffsetDirection,
  whichPanelHasHigherZindex = 2,
}) => {
  if (!panels.length) return null
  return (
    <Container>
      <Frame gradient={frameGradient} offset={frameOffsetDirection}>
        {panels.map((panel, index) => {
          const hasImage = !!panel.image
          return (
            <Appear styleChild key={panel.id}>
              <SmartImage image={panel.image}>
                {image => (
                  <Panel
                    key={panel.id}
                    minHeight={600}
                    gradient={get(panel, 'gradient')}
                    image={image}
                    darkText={get(panel, 'darkText', true)}
                    isAbove={whichPanelHasHigherZindex === index + 1}
                  >
                    {!hasImage && (
                      <Content>
                        {panel.title && (
                          <Type
                            as="h3"
                            preset={
                              get(panel, 'textSize') === 'small'
                                ? 'headlineSmall'
                                : 'headline'
                            }
                            bottom={{ xs: 1, md: 2 }}
                          >
                            {panel.title}
                          </Type>
                        )}
                        {(panel.subtitle || panel.multilineSubtitle) && (
                          <Type
                            preset={
                              get(panel, 'textSize') === 'small'
                                ? 'textLarge'
                                : 'subtitle'
                            }
                            bottom={{ xs: 2, md: 2.5 }}
                            multiline
                            dangerouslySetInnerHTML={{
                              __html: formatHtmlText(
                                panel.subtitle || panel.multilineSubtitle,
                                linkStyle
                              ),
                            }}
                          />
                        )}
                        {panel.keyValuePair && (
                          <KeyValueList
                            list={arrToKeyValue(panel.keyValuePair)}
                          />
                        )}
                        <ButtonWrap>
                          {panel.buttons?.map(button => (
                            <Button
                              key={button.to}
                              paddingSize="small"
                              to={button.to}
                              preset={button.preset}
                            >
                              {button.text}
                            </Button>
                          ))}
                        </ButtonWrap>
                      </Content>
                    )}
                  </Panel>
                )}
              </SmartImage>
            </Appear>
          )
        })}
      </Frame>
    </Container>
  )
}
