import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import type { Asset } from 'contentful'

import type { ISectionRestaurantMenuFields } from 'contentful-shared'
import { Amount } from 'bl-common/src/elements/Amount'
import { ContentfulImage } from 'bl-common/src/elements/ContentfulImage'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'

import * as css from './styles'

type RestaurantMenuSectionProps = {
  section: {
    fields: ISectionRestaurantMenuFields
  }
}

const menuOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_4]: (_, children) => (
      <Type
        as="p"
        preset="textLarge"
        weight="bold"
        top={{ xs: 2 }}
        size={{ xs: 18, md: 18 }}
      >
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type as="p" multiline size={{ xs: 16, md: 18 }}>
        {children}
      </Type>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'prices':
          return (
            <Type weight="bold" size={{ xs: 16, md: 18 }}>
              <Amount
                value={fields.isk}
                format={fields.priceFormat}
                fallback={null}
                useSymbol
              />
            </Type>
          )
        default:
          return null
      }
    },
    [BLOCKS.DOCUMENT]: (_, children) => children,
  },
}

const MenuImg = ({
  image,
  reverse = false,
}: {
  image: Asset
  reverse?: boolean
}) => {
  return (
    <css.MenuImg from={reverse ? -2 : 2} to={reverse ? 2 : -2}>
      <ContentfulImage image={image} />
    </css.MenuImg>
  )
}

export const RestaurantMenuSection = ({
  section: {
    fields: { textContent, menus, config },
  },
}: RestaurantMenuSectionProps) => {
  return (
    <Section config={config}>
      <Appear observer>
        {!!textContent && (
          <Appear>
            <css.MenuIntro>
              <RichTextRenderer document={textContent} />
            </css.MenuIntro>
          </Appear>
        )}
        <css.MenuWrapper>
          {menus?.map((menu, key) => {
            if (!menu?.fields) return null

            const { title, menuDetails, menuImages } = menu.fields
            const isOdd = key % 2 === 0

            return (
              <css.MenuItem key={menu.sys.id}>
                {menuImages?.[0] && (
                  <MenuImg image={menuImages?.[0]} reverse={isOdd} />
                )}
                <css.MenuDetails>
                  {title && (
                    <Type as="h3" preset="headlineMedium">
                      {title}
                    </Type>
                  )}
                  {!!menuDetails && (
                    <RichTextRenderer
                      document={menuDetails}
                      customOptions={menuOptions}
                    />
                  )}
                </css.MenuDetails>
                {menuImages?.[1] && (
                  <MenuImg image={menuImages[1]} reverse={!isOdd} />
                )}
              </css.MenuItem>
            )
          })}
        </css.MenuWrapper>
      </Appear>
    </Section>
  )
}

export default RestaurantMenuSection
