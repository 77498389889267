import styled from 'styled-components'

import { colors } from '../../constants/colors'
import { theme } from '../../styles/theme'
import type { BreakpointMap } from '../../types/custom'
import { mediaObj } from '../../utils/media'
import { mixins } from '../../utils/mixins'

export const HotelRoomHero = styled.header<{ bottom?: BreakpointMap<number> }>(
  ({ theme }) => ({
    color: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '::after': {
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(217, 217, 217, 0) 100%)',
      content: '""',
      height: 110,
      left: 0,
      opacity: 0.75,
      position: 'absolute',
      right: 0,
      top: 0,
      pointerEvents: 'none',
    },

    [mediaObj.md]: {
      height: 'calc(88vh - 110px)',
      ...mixins.siteGutterObj(),
      marginBottom: theme.spacing[8],
    },
  })
)

export const HeroImage = styled.div({
  display: 'none',
  inset: 0,
  position: 'absolute',

  [mediaObj.md]: {
    display: 'block',
  },
})

export const HeroCarousel = styled.div({
  display: 'block',
  position: 'relative',

  [mediaObj.md]: {
    display: 'none',
  },
})

export const HeroContent = styled.div({
  ...mixins.siteGutterObj(),
  background: colors.white,
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing[2],
  paddingTop: theme.spacing[3],
  position: 'relative',

  [mediaObj.md]: {
    top: '100%',
    width: '100%',
    transform: 'translateY(-50%)',
    padding: `${theme.spacing[3]} ${theme.spacing[6.5]}`,
  },
})
