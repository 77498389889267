import styled, { css } from 'styled-components'

import type { IGradientFields } from 'contentful-shared'
import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { durations } from 'bl-common/src/constants/durations'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Link } from 'bl-common/src/elements/Link'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

export const Container = styled.div`
  display: block;
  position: relative;

  @media (min-width: ${breakpoints.md}px) and (orientation: landscape) {
    width: calc(100% - 32px);
    margin: 0 16px 0 16px;
  }
`

export const ContentFrame = styled.div<{ gradient: IGradientFields }>`
  width: 100%;
  position: relative;
  padding: 32px;

  @media (min-width: ${breakpoints.md}px) and (orientation: landscape) {
    padding: ${({ theme, gradient }) =>
      gradient
        ? `${theme.spacing[8]} ${theme.spacing[6]} ${theme.spacing[8]}`
        : `${theme.spacing[1]} ${theme.spacing[6]} ${theme.spacing[8]}`};
    width: 96%;
    min-height: 90vh;
  }

  &::before {
    content: '';
    position: absolute;
    top: ${({ theme }) => theme.spacing[4]};
    left: 0;
    right: 0;
    height: 100%;
    z-index: ${zIndex.behind};

    ${({ gradient }) =>
      gradient &&
      css`
        background: linear-gradient(
          ${gradient.degrees || 'to right'},
          ${gradient.color1} 0%,
          ${gradient.color2} 100%
        );
        opacity: ${gradient.opacity || 1};
      `}
  }
`

export const ContentContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  ${media.md(css`
    margin-top: ${({ theme }) => theme.spacing[2]};
    margin-bottom: ${({ theme }) => theme.spacing[6]};
  `)}
  @media (min-width: ${breakpoints.md}px) and (orientation: landscape) {
    margin-top: ${({ theme }) => theme.spacing[8]};
    width: 50%;
  }
`

export const SideImage = styled.div<{ image: string }>`
  position: relative;
  width: calc(100% + 32px);
  height: 57vh;
  right: 0;
  display: block;
  background-image: ${({ image }) => image && `url(${image})`};
  background-position: center;
  background-size: cover;

  ${media.md(css`
    margin-bottom: ${({ theme }) => theme.spacing[2]};
  `)}

  @media (min-width: ${breakpoints.mlg}px) and (orientation: landscape) {
    min-height: 67vh;
    position: absolute;
    opacity: 1;
    height: 100%;
    max-width: 43vw;
    top: 0;
    right: -6vw;
    margin-bottom: auto;
  }
`

export const linkStyle = css`
  text-transform: none;
  color: ${colors.deepBlue};
  font-weight: bold;
`

export const ImageLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
`

export const ImageLinkContainer = styled(Link)`
  background: ${({ bgc }) => bgc || '#50555B'};
  color: ${colors.white};
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  height: ${between(108, 184)};
  justify-content: space-between;
  margin-bottom: ${({ theme: { spacing } }) => `${spacing[2.5]}`};
  overflow: hidden;
  padding: ${({ theme: { spacing } }) => `${spacing[2.5]} ${spacing[2]}`};
  position: relative;
  transition: transform ${durations.long}ms;

  ${media.sm(css`
    flex: 0 0 48%;
  `)}

  &::before {
    content: '';
    ${({ image }) => css`
      background: url(${image}) no-repeat;
      background-position: 85% 50%;
      background-size: auto 70%;
    `}
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    transition: transform 0.3s linear;
  }
  &:hover::before {
    transform: scale(1.05);
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const ImageLinkTitle = styled.div`
  font-size: 14px;
  text-transform: uppercase;
`

export const ImageLinkPrompt = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`
