import { useEffect, useReducer, useRef } from 'react'

import { useNavigationEvents } from 'bl-common/src/context/useNavigationEvents'
import { dispatchEvent } from 'bl-utils/src/dispatchEvent'

import { PageWrapper } from './PageWrapper'

const FADE_OUT_DURATION = 500
const LOADING_HAS_NOTHING = 0
const LOADING_HAS_OVERLAY = 1
const LOADING_HAS_DATA = 2
const LOADING_DONE = 3

const initialState = children => ({ currentPage: children, isLoading: false })
const reducer = (state, action) => {
  switch (action.type) {
    case 'updateCurrentPage':
      return {
        ...state,
        currentPage: action.currentPage,
        isLoading: false,
      }
    case 'finish':
      return {
        ...state,
        currentPage: action.newPage,
        isLoading: false,
      }
    case 'start':
      return {
        ...state,
        isLoading: true,
      }
    default:
      return state
  }
}

export const PageManager = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState(children))
  const { current: ref } = useRef({
    latestPage: null,
    loadingTimeout: null,
    loadingStatus: LOADING_DONE,
  })
  ref.latestPage = children

  if (Array.isArray(children)) {
    throw new Error(
      'PageManager can only accept a single child to perform page transitions.'
    )
  }

  if (
    children !== state.currentPage &&
    children.key === state.currentPage.key
  ) {
    dispatch({ type: 'updateCurrentPage', currentPage: children })
  }

  const checkDone = () => {
    if (ref.loadingStatus !== LOADING_DONE) {
      return
    }

    dispatch({ type: 'finish', newPage: ref.latestPage })

    // Let listeners know we've loaded a new page.
    dispatchEvent(window, 'bl:load')
  }

  const overlayReady = () => {
    ref.loadingStatus =
      ref.loadingStatus === LOADING_HAS_DATA
        ? LOADING_DONE
        : LOADING_HAS_OVERLAY
    checkDone()
  }

  useNavigationEvents((event, transition) => {
    if (event === 'routeChangeStart') {
      if (transition.shallow) {
        ref.loadingStatus = LOADING_HAS_OVERLAY
      } else {
        dispatch({ type: 'start' })
        ref.loadingStatus = LOADING_HAS_NOTHING
        clearTimeout(ref.loadingTimeout)
        ref.loadingTimeout = setTimeout(overlayReady, FADE_OUT_DURATION)
      }
    } else if (event === 'routeChangeComplete') {
      ref.loadingStatus =
        ref.loadingStatus === LOADING_HAS_OVERLAY
          ? LOADING_DONE
          : LOADING_HAS_DATA
      checkDone()
    }
  }, [])

  useEffect(
    () => () => {
      clearTimeout(ref.loadingTimeout)
    },
    []
  )

  return (
    <>
      <PageWrapper isActive isFirstLoad={true}>
        {state.currentPage}
      </PageWrapper>
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          backgroundColor: 'var(--color-background, #fff)',
          transition: 'opacity 500ms',
          opacity: state.isLoading ? 1 : 0,
          pointerEvents: 'none',
          zIndex: 10,
        }}
      />
    </>
  )
}
