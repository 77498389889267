import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import type { PartialBookingEngine } from '../../styles/types'

interface SelectProps {
  hasError: boolean
  showingPlaceholder: boolean
}

interface SharedProps {
  isRounded: boolean
  themeStyle: PartialBookingEngine['selectField']['select']
}

export const Arrow = styled.div`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
`
export const TextAreaContainer = styled.div<SharedProps>`
  position: relative;
  width: 100%;

  ${({ isRounded, themeStyle }) =>
    !isRounded &&
    css`
      &::after {
        border-bottom: 2px solid
          ${themeStyle?.hoverBorderColor ?? colors.fountainBlue};
        content: '';
        border-left: 2px solid
          ${themeStyle?.hoverBorderColor ?? colors.fountainBlue};
        display: block;
        height: 10px;
        right: 16px;
        position: absolute;
        top: -4px;
        bottom: 0;
        margin: auto;
        transform: rotate(-45deg);
        width: 10px;
      }
    `}
`

export const StyledSelect = styled.select<SelectProps & SharedProps>`
  background: ${props => props.themeStyle?.backgroundColor ?? colors.white};
  border: 1px solid ${props => props.themeStyle?.borderColor ?? colors.formGrey};
  color: ${props => props.themeStyle?.textColor ?? colors.formDark};
  font-family: inherit;
  font-size: 16px;
  outline: none;
  padding: 0 27px 0 10px;
  width: 100%;
  appearance: none;
  height: 56px;
  cursor: pointer;
  transition:
    box-shadow 150ms,
    border 150ms;
  border-radius: ${props => props.themeStyle?.borderRadius ?? 0}px;

  ${({ isRounded }) =>
    isRounded &&
    css`
      border-radius: 4px;
      height: 30px;
      font-size: 14px;
    `}

  :hover {
    border: 1px solid
      ${props => props.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
    box-shadow: 0 0 12px
      ${props =>
        rgba(props.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
  }

  :disabled :hover {
    border: 1px solid ${colors.formGrey};
    box-shadow: none;
    cursor: default;
  }

  :focus {
    border: 1px solid
      ${props => props.themeStyle?.hoverBorderColor ?? colors.fountainBlue};
    box-shadow: 0 0 12px
      ${props =>
        rgba(props.themeStyle?.hoverBorderColor ?? colors.fountainBlue, 0.4)};
    background: ${props => props.themeStyle?.backgroundColor ?? colors.white};
  }

  ${({ hasError }) =>
    hasError &&
    css`
      background: rgba(218, 75, 75, 0.15);
      border: 1px solid ${colors.errorRed};

      &:hover,
      &:focus {
        box-shadow: none;
      }
    `}

  ${({ showingPlaceholder }) =>
    showingPlaceholder &&
    css`
      opacity: 0.5;
    `}

    > option {
    font-family: inherit;
  }
`
