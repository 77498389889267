import type { IPageAboutMain, ISiteConfigFields } from 'contentful-shared'

import { getEntriesWrapper } from '../services/contentfulClient'
import { getCurrentEnvironment } from './environment'

const environment = getCurrentEnvironment()

const SUPPORTED_LANGUAGES = ['en', 'is']
const DEFAULT_LANGUAGE = 'en'

const getLanguageFromPathname = pathname => {
  const [, firstSlug] = pathname.split(/[/?#]/g)

  return SUPPORTED_LANGUAGES.find(key => key === firstSlug) || DEFAULT_LANGUAGE
}

const fetchSiteConfig = async pathname => {
  const language = getLanguageFromPathname(pathname)

  const siteConfigPromise = getEntriesWrapper<ISiteConfigFields>({
    content_type: 'siteConfig',
    include: 6,
    'fields.language': language,
  })

  // The about us site release date is completely unknown.
  // Only fetch it in development mode so it can be worked on.
  const aboutUsNavigationPromise =
    environment !== 'production'
      ? getEntriesWrapper<IPageAboutMain>({
          content_type: 'pageAboutMain',
          include: 3,
          locale: language,
          select:
            'fields.navigationGroups,fields.homeLink,fields.sideDisclaimer,fields.sideLinks',
        })
      : Promise.resolve(null)

  const [siteConfigRes, aboutUsNavigationRes] = await Promise.all([
    siteConfigPromise,
    aboutUsNavigationPromise,
  ])

  const siteConfig = siteConfigRes.items?.[0]
  const aboutUsNavigation = aboutUsNavigationRes?.items?.[0]?.fields ?? null

  return {
    language,
    navigation: siteConfig.fields.nav,
    footer: siteConfig.fields.footer,
    metadata: siteConfig.fields.metadata,
    alertBanners: siteConfig.fields.alertBanners,
    bannerColor: siteConfig.fields.bannerColor,
    notifications: siteConfig.fields.notifications,
    aboutUsNavigation,
  }
}

export { fetchSiteConfig }
