import { useRef } from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { nestedCalc } from 'bl-utils/src/nestedCalc'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { modularScale } from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { Amount } from '../../elements/Amount'
import { BackgroundLink } from '../../elements/BackgroundLink'
import { Button } from '../../elements/Button/Button'
import { Type } from '../../elements/Typography/Typography'
import { SmartImage } from '../../units/SmartImage/SmartImage'
import { between } from '../../utils/between'
import { media } from '../../utils/media'

type BackgroundImageProps = {
  gradientOverlay?: any
}

const Footer = styled.div`
  bottom: ${between(40 / modularScale, 40)};
  position: absolute;
  transition: transform ${durations.short}ms;
`

const ButtonPositioner = styled.div`
  bottom: ${between(40 / modularScale, 40)};
  display: flex;
  position: absolute;
  transition:
    transform 0.35s,
    opacity 0.05s 0.25s;
  z-index: ${zIndex.above};
  width: 80%;

  ${media.md(css`
    bottom: 0;
    transform: translateY(100%);
  `)};
`

const HoverEffect = css`
  ${ButtonPositioner} {
    opacity: 1;
    transform: translateY(${props => nestedCalc(`${props.theme.spacing[-3]}`)});
    transition:
      transform 0.32s,
      opacity 0.05s;
  }
  ${Footer} {
    transform: translateY(${props => nestedCalc(`${props.theme.spacing[-5]}`)});
  }
`
const CardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-height: ${between(656 / modularScale, 656)};
  padding-top: ${between(80 / modularScale, 80)};
  padding-bottom: ${between(200 / modularScale, 200)};
  padding-left: ${between(40 / modularScale, 40)};
  padding-right: ${between(40 / modularScale, 40)};

  ${media.md(css`
    &:hover,
    &:focus,
    &:focus-within {
      ${HoverEffect};
    }
  `)};
`

const CardContent = styled.div`
  position: relative;
  height: 100%;
  width: 85%;
  max-width: 450px;
`

const BackgroundImage = styled.div<BackgroundImageProps>`
  display: block;
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ gradientOverlay }) =>
    gradientOverlay &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        margin: auto;
        opacity: 0.5;

        mix-blend-mode: multiply;
        background: linear-gradient(
          ${get(gradientOverlay, 'fields.degrees', gradientOverlay.degrees)},
          ${get(gradientOverlay, 'fields.color1', gradientOverlay.color1)},
          ${get(gradientOverlay, 'fields.color2', gradientOverlay.color2)}
        );
      }
    `};
`

export const PackageCard = ({
  tagline,
  title,
  description,
  disclaimer,
  color = 'light',
  footerColor,
  image,
  backgroundPosition = 'center',
  price,
  priceRef,
  priceFormat,
  primaryCtaUrl,
  secondaryCtaUrl,
  primaryCtaTitle = 'Book',
  secondaryCtaTitle = 'Explore',
  gradientOverlay,
}) => {
  const scrollRef = useRef(null)

  const handleFocus = () => {
    // Set scrollTop to zero so that overflow hidden doesn't
    // cause a jump when out-of-view buttons are focused.
    scrollRef.current.scrollTop = 0
  }

  const hasDisclaimer = !!disclaimer
  return (
    <CardWrapper
      key={title}
      style={{
        color:
          String(color).toLowerCase() === 'dark' ? colors.dark : colors.white,
      }}
      onFocus={handleFocus}
      ref={scrollRef}
      data-packagecard="true"
    >
      <BackgroundLink
        to={primaryCtaUrl}
        aria-hidden="true"
        tabIndex={-1}
        zIndex={4}
      />
      <SmartImage image={image}>
        {imageUrl => (
          <BackgroundImage
            gradientOverlay={gradientOverlay}
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundPosition,
            }}
          />
        )}
      </SmartImage>
      <CardContent>
        {tagline && (
          <Type
            preset="subtitle"
            case="uppercase"
            bottom={{ xs: 0.5, md: 0.5 }}
          >
            {tagline}
          </Type>
        )}
        {title && (
          <Type
            preset="headlineMedium"
            weight="bold"
            bottom={{ xs: 0.5, md: 0.5 }}
          >
            {title}
          </Type>
        )}
        {description && <Type preset="subtitle">{description}</Type>}
      </CardContent>
      <Footer
        style={{
          color:
            String(footerColor || color).toLowerCase() === 'dark'
              ? colors.dark
              : colors.white,
        }}
      >
        {(priceRef || price) && (
          <Type preset="text" bottom={{ xs: hasDisclaimer ? 1 : 0 }}>
            <Amount value={priceRef} format={priceFormat} fallback={price} />
          </Type>
        )}
        {hasDisclaimer && (
          <Type as="p" weight="light">
            {disclaimer}
          </Type>
        )}
      </Footer>
      <ButtonPositioner>
        {secondaryCtaUrl && (
          <Button
            preset="dark"
            to={secondaryCtaUrl}
            maxWidth={{ md: 148 }}
            style={{ flex: 1 }}
          >
            {secondaryCtaTitle}
          </Button>
        )}
        <Button
          preset="darker"
          to={primaryCtaUrl}
          maxWidth={{ md: 148 }}
          style={{ flex: 1 }}
        >
          {primaryCtaTitle}
        </Button>
      </ButtonPositioner>
    </CardWrapper>
  )
}
