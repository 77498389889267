import get from 'lodash/get'

import { zIndex } from 'bl-common/src/constants/zIndex'
import { Column } from 'bl-common/src/units/Column'
import { Row } from 'bl-common/src/units/Row'
import { Section } from 'bl-common/src/units/Section/Section'
import { getImageUrl } from 'bl-common/src/units/SmartImage/getImageUrl'

import { InfoPanel } from '../../InfoPanel'

export const InfoPanelSection = ({
  section: {
    fields: { infoPanel, config },
  },
}) => {
  const image = get(infoPanel, 'fields.image')
  const imageUrl = getImageUrl(image, { width: 800 })

  return (
    <Section
      top={{ xs: 4, md: 6 }}
      bottom={{ xs: 4, md: 6 }}
      config={config}
      style={{ zIndex: zIndex.above }}
    >
      <Row>
        <Column
          size={{ xs: 12 / 12, lg: 11 / 12 }}
          $offsetLeft={{ lg: 0.5 / 12 }}
        >
          <InfoPanel
            title={get(infoPanel, 'fields.title')}
            description={get(infoPanel, 'fields.description')}
            url={get(infoPanel, 'fields.bookingUrl')}
            buttonText={get(infoPanel, 'fields.buttonText')}
            infoList={get(infoPanel, 'fields.whatsIncluded')}
            listTitle={get(infoPanel, 'fields.listTitle')}
            price={get(infoPanel, 'fields.price')}
            image={imageUrl}
            priceRef={get(infoPanel, 'fields.priceRef')}
            priceFormat={get(infoPanel, 'fields.priceFormat')}
          />
        </Column>
      </Row>
    </Section>
  )
}

export default InfoPanelSection
