import { init } from '@sentry/nextjs'

const ignorePatterns = [
  {
    message: "reading 'openDialog'",
    pathRegex: /e9690605/,
  },
] as const

export const initializeSentry = ({
  dsn,
  tracesSampleRate,
  environment,
  maxValueLength = 10000,
  correlationId,
}: {
  dsn: string
  tracesSampleRate: number
  environment: 'development' | 'production'
  maxValueLength?: number
  correlationId?: string
}) => {
  if (dsn) {
    init({
      dsn,
      tracesSampleRate: tracesSampleRate ?? 0,
      maxValueLength,
      environment,
      initialScope: {
        tags: {
          correlationId,
        },
      },
      ignoreErrors: [
        'NotAllowedError',
        'AbortError',
        'ResizeObserver loop',
        'SecurityError',
        'InitializationError',
        'Non-Error promise rejection captured',
        'preloadedListeners',
        'elementFromPoint',
        /^Failed to fetch$/,
      ],
      beforeSend(event, hint) {
        if (hint.originalException instanceof Error) {
          const errorMessage = hint.originalException.message || ''
          const stackTrace = hint.originalException.stack || ''

          const isIgnoredError = ignorePatterns.some(
            ({ message, pathRegex }) => {
              return (
                errorMessage.includes(message) || stackTrace.match(pathRegex)
              )
            }
          )

          if (isIgnoredError) {
            return null
          }
        }
        return event
      },
    })
  }
  console.warn('No SENTRY_DSN found. Sentry will not be initialized.')
}
