import { BLOCKS } from '@contentful/rich-text-types'

import type { ISectionDiscoverPanelFields } from 'contentful-shared'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import {
  BulletList,
  ButtonLink,
  RichTextListItem,
} from 'bl-common/src/richText/RichTextBlocks'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Appear } from 'bl-common/src/units/Appear'
import { DiscoverPanel } from 'bl-common/src/units/DiscoverPanel'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'

import { formatGradient } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'

const documentOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Type
        preset="headlineLarge"
        as="h2"
        bottom={{ xs: 1, md: 2.5 }}
        maxWidth={400}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Type preset="headline" as="h3" bottom={{ xs: 1, md: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Type preset="text" as="p" bottom={{ xs: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      // In some cases the embedded entry can be deleted from Contentful which causes the code to crash.
      // This null check prevents that from happening.
      if (node.data?.target == null) {
        console.warn('Embedded entry is null. The entry may have been deleted.')
        return null
      }
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'ctaButton':
          return fields.displayAsLink ? (
            <ButtonLink
              textColor={fields.textColor}
              to={fields.link}
              emailTrigger={fields.emailTrigger}
              weight="bold"
              paddingSize="small"
            >
              {fields.text}
            </ButtonLink>
          ) : (
            <Button
              textColor={fields.textColor}
              paddingSize={fields.paddingSize}
              preset={fields.color}
              to={fields.link}
              maxWidth={373}
              top={{ xs: 1 }}
              emailTrigger={fields.emailTrigger}
            >
              {fields.text}
            </Button>
          )
        case 'list':
          return (
            <BulletList>
              {fields.listItems.map(item => (
                <RichTextListItem {...item.fields} key={item.fields.item}>
                  {item.fields.item}
                </RichTextListItem>
              ))}
            </BulletList>
          )
        default:
          return null
      }
    },
  },
}

const DiscoverPanelSection = (props: any) => {
  const { config, backgroundGradient, offsetDirection, textContent, ...rest } =
    getSectionProps(props) as ISectionDiscoverPanelFields
  const formattedGradient = formatGradient(backgroundGradient)

  return (
    <Appear>
      <OffsetSection
        offsetDirection={offsetDirection}
        background={formattedGradient}
        config={config}
      >
        <DiscoverPanel {...rest} offsetDirection={offsetDirection}>
          {!!textContent && (
            <RichTextRenderer
              document={textContent}
              customOptions={documentOptions}
            />
          )}
        </DiscoverPanel>
      </OffsetSection>
    </Appear>
  )
}

export default DiscoverPanelSection
