import type { ISuiteCommonAreasGallery } from 'contentful-shared'
import { CommonAreasGallery } from 'bl-common/src/units/Gallery/CommonAreasGallery'
import { Section } from 'bl-common/src/units/Section/Section'

type SuiteCommonAreasGallerySectionProps = {
  section: ISuiteCommonAreasGallery
}

const SuiteCommonAreasGallery = ({
  section: {
    fields: {
      heading,
      multilineSubheading,
      fullWidthImage,
      tallImage1,
      tallImage2,
      squareImage1,
      squareImage2,
      config,
    },
  },
}: SuiteCommonAreasGallerySectionProps) => {
  return (
    <Section top={{ xs: 2, md: 4 }} bottom={{ xs: 2, md: 2 }} config={config}>
      <CommonAreasGallery
        heading={heading}
        multilineSubheading={multilineSubheading}
        tallImage1={tallImage1}
        tallImage2={tallImage2}
        squareImage1={squareImage1}
        squareImage2={squareImage2}
        fullWidthImage={fullWidthImage}
      />
    </Section>
  )
}

export default SuiteCommonAreasGallery
