import { addDays } from 'date-fns/addDays'

import { Property, type RoomOffer } from 'bl-graphql'
import { getMyBookingLink } from 'bl-utils/src/routing/getMyBookingLink'
import { queryTypes } from 'flow-builder/src/utils/urlState'

import type { RoomSelection } from './types'

export const INITIAL_PROPERTY = Property.Silica.toLowerCase()
export const INITIAL_ADULTS = 2
export const INITIAL_CHILDREN = 0
export const INITIAL_INFANTS = 0
export const INITIAL_ROOMS = 1
export const INITIAL_ROOM_SELECTION = [
  {
    adults: INITIAL_ADULTS,
    children: INITIAL_CHILDREN,
    infants: INITIAL_INFANTS,
  },
] as RoomSelection
export const INITIAL_CHILDREN_AGE = 5
export const INITIAL_ARRIVAL_DATE = addDays(new Date(), 1)
export const INITIAL_DEPARTURE_DATE = addDays(INITIAL_ARRIVAL_DATE, 1)
export const MAX_ROOMS = 4
export const GUESTS_PER_ROOM = 2

export const HOTEL_BOOKING_BAR_STATE_SCHEMA = {
  dates: queryTypes
    .array(queryTypes.date)
    .withDefault([INITIAL_ARRIVAL_DATE, INITIAL_DEPARTURE_DATE]),
  rooms: queryTypes
    .array(
      queryTypes.json<{
        adults: number
        children: number
        infants: number
        offer?: RoomOffer
      }>()
    )
    .withDefault([
      {
        adults: INITIAL_ADULTS,
        children: INITIAL_CHILDREN,
        infants: INITIAL_INFANTS,
      },
    ]),
}

// Schema for the hotel flow
export const HOTEL_FLOW_STATE_SCHEMA = {
  property: queryTypes.string.withDefault(INITIAL_PROPERTY),
  ...HOTEL_BOOKING_BAR_STATE_SCHEMA,
}

export const INITIAL_HOTEL_BOOKING_FILTERS_STATE_SCHEMA = {
  roomFeatures: queryTypes.array(queryTypes.string),
  roomView: queryTypes.array(queryTypes.string),
}

export const HOTEL_BOOKING_FILTERS_OPTIONS = {
  roomFeatures: ['king_bed', 'twin_bed', 'wheelchair_accessible'],
  roomView: [
    'mountain', // Only applies for SILICA
    'moss', // Only applies for RETREAT
    'lagoon',
    'lava',
  ],
}
// Only apply for The Retreat
export const ROOM_FEATURES_RETREAT_HOTEL_FILTERS_OPTIONS = [
  'lounge',
  'private_lagoon',
  'upper_level',
  'lower_level',
]

export const SORT_METHODS = {
  initialSort: (a, b) => {
    const sortA = a.fields?.sort
    const sortB = b.fields?.sort

    if (sortA && sortB) {
      return sortA - sortB // Sort by sort value, ascending
    } else if (sortA) {
      return -1 // a has sort, b doesn't, a should come first
    } else if (sortB) {
      return 1 // b has sort, a doesn't, b should come first
    } else {
      return a.price - b.price // Neither has sort, fall back to price
    }
  },
  priceHigh: (a, b) => (b?.price || 0) - (a?.price || 0),
  priceLow: (a, b) => (a?.price || 0) - (b?.price || 0),
  alphabetically: (a, b) =>
    (a.fields?.title || '').localeCompare(b.fields?.title || '', 'de', {
      ignorePunctuation: true,
    }),
}

export const SORT_OPTIONS = Object.keys(SORT_METHODS)

export const HOTEL_BOOKING_SORT_STATE_SCHEMA = {
  sort: queryTypes.string,
}

export const HOTEL_FLOW_CONFIRMATION_KEY = 'bl_hotel-confirmation'
export const HOTEL_FLOW_KEY = 'accommodation'
export const HOTEL_PACKAGE_FLOW_KEY = 'accommodation-package'

export const WEBSITE_URL_MY_BLUE_LAGOON = getMyBookingLink()

export const MAX_COMMENT_LENGTH = 80
export const COMMENT_PREFIX_ESTIMATED_ARRIVAL_TIME = 'Estimated arrival time: '
export const COMMENT_PREFIX_FLIGHT_NUMBER = 'Flight number: '

export const SILICA_STANDARD_PACKAGE_RATECODE = 'SILICA_ONLINE_RACK'
export const RETREAT_STANDARD_PACKAGE_RATECODE = 'RETREAT_ONLINE_RACK'
export const HIGHLAND_STANDARD_PACKAGE_RATECODE = 'RACK RATE B&B'

export const HOTEL_CART_EXPIRATION_MS = 19.5 * 60 * 1000 // 19.5 minutes until the cart is reset
export const HOTEL_CART_EXPIRATION_WARNING_MS = 3 * 60 * 1000 // Show warning when 3 minutes are left
export const HOTEL_CHARGE_PRIOR_DAYS = 10
