import { initReactI18next } from 'react-i18next'
import i18next, { type InitOptions } from 'i18next'

import resources from 'bl-localization/src/lib/oldLocalizationResources'

i18next.use(initReactI18next)
i18next.init({
  resources,
  fallbackLng: 'en',
  languages: ['en', 'is'],
  defaultNS: 'translations',
  fallbackNS: 'translations',
  keySeparator: false,
} as InitOptions) // TODO: Some of these items might not be valid for InitOptions

export const geti18n = lang => {
  const i18n = typeof window !== 'undefined' ? i18next : i18next.cloneInstance()
  i18n.changeLanguage(lang)
  return i18n
}
