import { type FC, type RefObject, useEffect } from 'react'

import * as styles from './styles'

export interface SubnavigationItemProps {
  name: string
  link?: string
  anchorTag?: string
  selected?: boolean
  highlighted?: boolean
  itemRef?: RefObject<HTMLButtonElement>
  isButton: boolean
  onClick?: () => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLButtonElement>) => void
  onMouseDown?: () => void
  onTouchStart?: () => void
}

export const SubnavigationItem: FC<SubnavigationItemProps> = ({
  name,
  link,
  selected = false,
  highlighted = false,
  itemRef,
  isButton,
  onClick,
  onKeyPress,
  onMouseDown,
  onTouchStart,
  anchorTag,
}) => {
  useEffect(() => {
    if (itemRef?.current && !selected) {
      itemRef.current.blur()
    }
  }, [selected])

  const handleClick = () => {
    onClick?.()
  }

  return (
    <>
      {isButton ? (
        <styles._Button to={link} onClick={handleClick} preset="blue">
          {name}
        </styles._Button>
      ) : (
        <styles.item
          selected={selected || highlighted}
          data-selected={selected}
        >
          {link ? (
            <styles.link to={link}>{name}</styles.link>
          ) : (
            <styles.anchor
              role="menuitem"
              onClick={handleClick}
              ref={itemRef}
              aria-current={selected ? 'location' : undefined}
              aria-describedby={anchorTag}
              onKeyDown={onKeyPress}
              onMouseDown={onMouseDown}
              onTouchStart={onTouchStart}
            >
              {name}
            </styles.anchor>
          )}
        </styles.item>
      )}
    </>
  )
}
