import { useCallback, useContext } from 'react'
import { Translation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { gridOffsetFrom, gridOffsetTo } from 'bl-common/src/constants/sizes'
import {
  type Breadcrumb,
  BreadcrumbContext,
} from 'bl-common/src/context/BreadcrumbContext'
import { Arrow } from 'bl-common/src/elements/Arrow/Arrow'
import { Link } from 'bl-common/src/elements/Link'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { between } from 'bl-common/src/utils/between'
import { mediaMax } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

type BackLinkProps = {
  inHero?: boolean
  withPadding?: boolean
  top?: string | number
  children: React.ReactNode
  to: string
  onClick: React.DOMAttributes<HTMLAnchorElement>['onClick']
}

const BackLink = styled(({ inHero, withPadding, ...props }: BackLinkProps) => (
  <Link {...props} />
))`
  display: inline-flex;
  ${({ inHero }) =>
    inHero &&
    css<BackLinkProps>`
      position: absolute;
      top: ${props =>
        props.theme.spacing[props.top] ||
        between(gridOffsetFrom, gridOffsetTo)};

      ${mediaMax.md(css`
        top: 12px;
      `)};
    `};

  ${({ withPadding }) =>
    withPadding &&
    css`
      ${mixins.betweenProperty('margin-top', gridOffsetFrom, gridOffsetTo)};
      ${mixins.betweenProperty('padding-left', gridOffsetFrom, gridOffsetTo)};
    `}
`

const BackLinkArrow = styled.div`
  margin-right: 20px;
  display: flex;
  align-self: center;
`

type BreadcrumbLinkBaseProps = {
  name: string
  arrowScale?: number
  color?: string
} & Omit<BackLinkProps, 'children'>

const renderDefault = ({
  to,
  onClick,
  name,
  inHero = true,
  top,
  withPadding = false,
  arrowScale,
  color,
}: BreadcrumbLinkBaseProps) => (
  <BackLink
    to={to}
    onClick={onClick}
    inHero={inHero}
    top={top}
    withPadding={withPadding}
  >
    <BackLinkArrow>
      <Arrow rotate={180} scale={arrowScale} color={color} />
    </BackLinkArrow>{' '}
    <Type preset="text" color={color}>
      <Translation>{t => t('breadcrumbBack', { name })}</Translation>
    </Type>
  </BackLink>
)

type BreadcrumbLinkProps = {
  fallback?: Omit<Breadcrumb, 'delta'>
  children?: (props: any) => JSX.Element
  top?: string | number
  arrowScale?: number
  color?: string
} & Omit<BreadcrumbLinkBaseProps, 'to' | 'name' | 'onClick'>

export const BreadcrumbLink = ({
  fallback,
  children,
  ...rest
}: BreadcrumbLinkProps) => {
  const { breadcrumb } = useContext(BreadcrumbContext)
  const currentBreadcrumb = breadcrumb || fallback

  const clickLink = useCallback(
    event => {
      if (breadcrumb) {
        event.preventDefault()
        history.go(breadcrumb.delta)
      }
    },
    [breadcrumb]
  )

  if (!currentBreadcrumb) {
    return null
  }

  const props = {
    ...rest,
    to: currentBreadcrumb.to,
    name: currentBreadcrumb.name,
    onClick: clickLink,
  }
  return children ? children(props) : renderDefault(props)
}
