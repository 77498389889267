import type { FC, ReactNode } from 'react'
import { FocusOn } from 'react-focus-on'
import Portal from '@reach/portal'
import { AnimatePresence, motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { colors } from '../constants/colors'
import { zIndex } from '../constants/zIndex'
import { CloseButton } from '../elements/CloseButton'
import { media } from '../utils/media'

export enum ModalSize {
  small = 'small',
  medium = 'medium',
}

interface WrapperProps {
  $size: ModalSize
  $noWrapper: boolean
  $maxWidth?: string
  $isPopUp?: boolean
  $fullScreenOnMobile?: boolean
  $backgroundColor?: string
}

interface CloseButtonProps {
  $small: boolean
}

const Container = styled(motion.div)<{ $isAboveEverything?: boolean }>(
  ({ $isAboveEverything }) => ({
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: $isAboveEverything ? 999999 : zIndex.newLayer,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })
)

const Backdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${zIndex.behind};
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
`

const Content = styled.div`
  width: 100%;
  height: 100%;
`

const CloseButtonWrapper = styled(motion.div)<CloseButtonProps>`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: ${zIndex.above + 1};

  ${media.md(css`
    bottom: unset;
    left: unset;
    top: ${({ theme }) => theme.spacing[2.5]};
    right: ${({ theme }) => theme.spacing[2]};
    ${({ $small }: CloseButtonProps) =>
      $small &&
      css`
        top: ${({ theme }) => theme.spacing[1]};
        right: ${({ theme }) => theme.spacing[1]};
      `}
    transform: none;
  `)}

  ${media.mlg(css`
    bottom: unset;
    left: unset;
    top: ${({ theme }) => theme.spacing[2.5]};
    right: ${({ theme }) => theme.spacing[2.5]};
    ${({ $small }: CloseButtonProps) =>
      $small &&
      css`
        top: ${({ theme }) => theme.spacing[1]};
        right: ${({ theme }) => theme.spacing[1]};
      `}
    transform: none;
  `)}
`

const Wrapper = styled(motion.div)<WrapperProps>`
  position: relative;
  width: ${({ $fullScreenOnMobile }) =>
    $fullScreenOnMobile ? '100vw' : 'calc(100vw - 24px)'};
  height: ${({ $fullScreenOnMobile }) =>
    $fullScreenOnMobile ? '100vh' : 'calc(100vh - 24px)'};
  height: ${({ $fullScreenOnMobile }) =>
    $fullScreenOnMobile ? '100dvh' : 'calc(100dvh - 24px)'};
  z-index: ${zIndex.above};
  overflow-y: auto;
  border-radius: var(--border-radius);

  ${({ $noWrapper, $backgroundColor }) =>
    !$noWrapper &&
    css`
      box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.15);
      background: ${$backgroundColor ?? colors.backgroundWhite};
    `}

  ${({ $isPopUp }) =>
    $isPopUp &&
    css`
      height: 97vh;
      width: 94vw;

      ${media.smd(css`
        height: 97vh;
        width: 95vw;
      `)}

      ${media.md(css`
        height: 96vh;
        width: 96vw;
      `)}
    `}

  ${({ $size, $noWrapper, $maxWidth }) =>
    !$noWrapper &&
    media.mlg(css`
      width: 90vw;
      height: 90vh;
      max-height: 90vh;
      max-width: ${$maxWidth || '90vw'};

      ${
        $size === ModalSize.small &&
        css`
        width: auto;
        height: auto;
        max-width: ${$maxWidth || 'unset'};
      `
      }
    `)}

    /* If the modal is of small size we transition to not full screen modal sooner (md instead of mlg) */
  ${({ $size, $noWrapper, $maxWidth }) =>
    !$noWrapper &&
    $size === ModalSize.small &&
    media.md(css`
      width: 90vw;
      height: 90vh;
      max-height: 90vh;
      max-width: ${$maxWidth || '90vw'};
      overflow: auto;

      ${
        $size === ModalSize.small &&
        css`
        width: auto;
        height: auto;
        max-width: ${$maxWidth || 'unset'};
      `
      }
    `)}
`

interface ModalProps {
  show: boolean
  onHide: () => void
  children: ReactNode
  ariaLabel?: string
  closeButton?: ReactNode
  size?: ModalSize
  target?: string
  noWrapper?: boolean
  autoFocus?: boolean
  maxWidth?: string
  isAboveEverything?: boolean
  isPopUp?: boolean
  fullScreenOnMobile?: boolean
  backgroundColor?: string
  closeButtonColor?: string
  animateDirection?: 'top' | 'bottom'
  shards?: Array<React.RefObject<any> | HTMLElement>
}

const Modal: FC<ModalProps> = ({
  show,
  size = ModalSize.medium,
  ariaLabel,
  children,
  onHide,
  noWrapper = false,
  closeButton,
  autoFocus = false,
  maxWidth = '90vw',
  isAboveEverything = false,
  isPopUp = false,
  fullScreenOnMobile = true,
  backgroundColor,
  closeButtonColor,
  animateDirection = 'top',
  shards = [],
}: ModalProps) => {
  const transitionIn = {
    type: 'spring',
    mass: 1,
    damping: 35,
    stiffness: 140,
  }

  const transitionOut = {
    type: 'spring',
    mass: 1,
    damping: 26,
    stiffness: 170,
  }

  const variants = {
    hidden: {
      y: animateDirection === 'bottom' ? '-5vh' : '5vh',
      opacity: 0,
    },
    visible: {
      y: '0',
      opacity: 1,
      transition: transitionIn,
    },
    exit: {
      y: animateDirection === 'bottom' ? '-5vh' : '5vh',
      opacity: 0,
      transition: transitionOut,
    },
  }

  return (
    <AnimatePresence initial={false} exitBeforeEnter={true}>
      {show && (
        <Portal>
          <Container
            aria-modal={true}
            aria-label={ariaLabel}
            $isAboveEverything={isAboveEverything}
          >
            <Backdrop
              onClick={onHide}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: transitionIn }}
              exit={{ opacity: 0, transition: transitionOut }}
            />
            <FocusOn
              key="modal"
              autoFocus={autoFocus}
              returnFocus
              onClickOutside={onHide}
              onEscapeKey={onHide}
              shards={shards}
            >
              <Wrapper
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={variants}
                $noWrapper={noWrapper}
                $size={size}
                $maxWidth={maxWidth}
                $isPopUp={isPopUp}
                $fullScreenOnMobile={fullScreenOnMobile}
                $backgroundColor={backgroundColor}
              >
                <Content>
                  {closeButton}
                  {children}
                </Content>
                {!closeButton && !noWrapper && (
                  <CloseButtonWrapper $small={size === ModalSize.small}>
                    <CloseButton
                      onClose={onHide}
                      small={size === ModalSize.small}
                      color={closeButtonColor}
                    />
                  </CloseButtonWrapper>
                )}
              </Wrapper>
            </FocusOn>
          </Container>
        </Portal>
      )}
    </AnimatePresence>
  )
}

export default Modal
