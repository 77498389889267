import {
  captureException,
  captureMessage,
  withScope,
  getCurrentScope,
} from '@sentry/nextjs'

import type { Extras, Primitive, SeverityLevel } from '@sentry/types'

// TODO: We might want to codegen this using Sentry API
export type SentryTeam =
  | 'team-frontend-infrastructure'
  | 'team-day-visit-svartsengi'
  | 'team-hotels-svartsengi'
  | 'team-highlands'

export type SentryLogOptions =
  | {
      error: Error
      team: SentryTeam
      message?: never
      severity?: SeverityLevel
      extras?: Extras
      tags?: { [key: string]: Primitive }
    }
  | {
      message: string
      team: SentryTeam
      error?: never
      severity?: SeverityLevel
      extras?: Extras
      tags?: { [key: string]: Primitive }
    }

/**
 * Logs custom messages or errors to Sentry with additional context.
 *
 * This function enforces the use of a `context` to make it explicit
 * when performing custom logging. The `context` is critical for:
 * 1. **Easier Management**: By grouping logs and errors based on their
 *    value streams (e.g., payment, flow-builder, bl-day-visit), teams can filter
 *    and analyze relevant issues more efficiently.
 * 2. **Alerting**: Ensuring alerts are tied to specific areas of the
 *    business logic or features.
 * 3. **Error Grouping**: Context-driven grouping allows Sentry to
 *    better correlate similar issues for smoother debugging and root
 *    cause analysis.
 *
 * In development, errors and messages are logged to the console for
 * easier local debugging.
 *
 * @param {SentryLogOptions} options - The logging options.
 * @param {SentryTeam} options.team - The context(s) representing the feature, value stream, or business logic related to the log.
 * @param {Error} [options.error] - The error object to log (if any).
 * @param {string} [options.message] - The message to log (if any).
 * @param {SeverityLevel} [options.severity] - The severity level of the log (default: `info` for messages, `error` for errors).
 * @param {Extras} [options.extras] - Additional data to attach to the log for debugging.
 * @param {{ [key: string]: Primitive }} [options.tags] - Additional tags for categorizing the log in Sentry.
 */
export const sentryLogging = ({
  error,
  message,
  severity,
  team,
  extras = {},
  tags = {},
}: SentryLogOptions) => {
  const currentScope = getCurrentScope()

  // Don't try to log to Sentry if it's not initialized by parent app.
  if (!currentScope) {
    return
  }

  const scopeEnvironment = currentScope.getPropagationContext().dsc?.environment

  // Log to console if in development environment (preview, development or localhost)
  if (scopeEnvironment === 'development') {
    if (error) {
      console.error(error, extras)
    } else if (message) {
      console.log(message, extras)
    }
  }

  withScope(scope => {
    scope.setExtras(extras)
    scope.setTags({
      userDate: new Date().toISOString(),
      team,
      ...tags,
    })
    if (error) {
      scope.setLevel(severity || 'error')
      // Check if the error is an instance of Error
      if (error instanceof Error) {
        captureException(error)
      } else {
        // Capture non-Error objects as messages with additional context
        captureMessage(
          `Non-Error exception captured: ${JSON.stringify(error)}`,
          severity || 'error'
        )
      }
    } else if (message) {
      scope.setLevel(severity || 'info')
      captureMessage(message)
    }
  })
}
