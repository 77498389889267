import type { Asset } from 'contentful'

import type { IExperienceNavigationItemFields } from 'contentful-shared'

import { durations } from '../../constants/durations'
import { Amount } from '../../elements/Amount'
import { LinkArrow } from '../../elements/Arrow/Arrow'
import { ContentfulImage } from '../../elements/ContentfulImage'
import { Type } from '../../elements/Typography/Typography'
import * as styles from './styles'

type CardProps = {
  title: string
  description?: string
  priceRef?: any
  priceFormat?: any
  showPrice?: boolean
  image: Asset
  path?: string
  index?: number
  headingSize?: number
  spacingLeft?: number
  isFlat?: boolean
  imageRatio?: number
  withShadow?: boolean
  arrowSize?: any
  smallArrow?: any
  transitionSpeed?: any
  currentPath?: any
  closeMenu?: any
  onBookClick?: any
  starRating?: number
  subLinks?: IExperienceNavigationItemFields['subLinks']
}

const Card = ({
  title,
  description,
  priceRef,
  priceFormat,
  showPrice,
  image,
  path,
  index,
  headingSize = 24,
  spacingLeft = 5,
  isFlat,
  imageRatio = 1.5,
  withShadow,
  arrowSize = { width: 15, height: 10 },
  smallArrow,
  transitionSpeed = durations.long,
  currentPath,
  closeMenu,
  onBookClick = null,
  starRating,
  subLinks,
}: CardProps) => {
  const handleLinkClick = (
    event: MouseEvent,
    linkPath: string,
    triggerBookClick = false
  ) => {
    if (currentPath === linkPath) {
      event.preventDefault()
      closeMenu()
    } else if (triggerBookClick && onBookClick) {
      onBookClick()
    }
  }

  return (
    <styles.PortalWrapper
      spacingLeft={spacingLeft}
      isFlat={isFlat}
      index={index}
      withShadow={withShadow}
    >
      <styles.PortalContainer
        to={path}
        withShadow={withShadow}
        transitionSpeed={transitionSpeed}
        onClick={(e: MouseEvent) => handleLinkClick(e, path, true)}
      >
        <styles.ImageOverflow $imageRatio={imageRatio}>
          <styles.ImageWrapper transitionSpeed={transitionSpeed}>
            <ContentfulImage image={image} />
          </styles.ImageWrapper>
        </styles.ImageOverflow>
        <styles.ContentWrap spaced={withShadow}>
          <styles.Content>
            {starRating && (
              <styles.Stars aria-label={`Stars: ${starRating} / 5`}>
                {Array.from({ length: Math.floor(starRating) }).map(
                  (_, index) => (
                    <Type key={index} as="li" size={{ xs: 20 }} lineHeight={1}>
                      ⭑
                    </Type>
                  )
                )}
              </styles.Stars>
            )}
            <styles.Heading>
              <Type
                as="h2"
                size={{ xs: 18, md: 22, mlg: headingSize, lg: headingSize }}
                weight="bold"
              >
                {title}
              </Type>
              <styles.Arrow
                isSmall={smallArrow}
                transitionSpeed={transitionSpeed}
              >
                <LinkArrow {...arrowSize} />
              </styles.Arrow>
            </styles.Heading>
            <styles.Description
              size={{ xs: 12, md: 16 }}
              showOnMobile={showPrice}
            >
              {showPrice ? (
                <Amount
                  value={priceRef}
                  format={priceFormat}
                  fallback={description}
                />
              ) : (
                description
              )}
            </styles.Description>
          </styles.Content>
        </styles.ContentWrap>
      </styles.PortalContainer>
      {subLinks?.length && (
        <styles.SubLinks>
          {subLinks.map(
            subLink =>
              subLink.fields && (
                <styles.SubLink
                  key={subLink.sys.id}
                  to={subLink.fields.url}
                  onClick={(e: MouseEvent) =>
                    handleLinkClick(e, subLink.fields.url)
                  }
                >
                  <Type weight="medium" size={{ xs: 16 }}>
                    {subLink.fields.label}
                  </Type>
                  <styles.Arrow isSmall transitionSpeed={durations.medium}>
                    <LinkArrow {...arrowSize} />
                  </styles.Arrow>
                </styles.SubLink>
              )
          )}
        </styles.SubLinks>
      )}
    </styles.PortalWrapper>
  )
}

export default Card
