import get from 'lodash/get'
import styled, { css } from 'styled-components'

import type { IGradientFields } from 'contentful-shared'
import { colors } from 'bl-common/src/constants/colors'
import { gridOffsetFrom, gridOffsetTo } from 'bl-common/src/constants/sizes'
import { BackgroundImage } from 'bl-common/src/elements/BackgroundImage'
import { Button } from 'bl-common/src/elements/Button/Button'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'

type BackgroundProps = {
  background?: IGradientFields
  fullWidthMobile?: boolean
  leftOffset?: boolean
  rightOffset?: boolean
}

type ContentProps = {
  desktopDirection?: string
}

export const Background = styled.div<BackgroundProps>`
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 40px;
    right: 0;
    bottom: 0;
    left: 0;

    ${media.md(css`
      top: 0;
    `)}

    ${({ background }) =>
      background &&
      css`
        background: linear-gradient(
          ${get(background, 'degrees', 'to right')},
          ${background.color1},
          ${background.color2}
        );
      `}

    ${({ leftOffset, fullWidthMobile }) =>
      leftOffset &&
      css`
        left: ${fullWidthMobile ? '0' : 2 * gridOffsetFrom}px;
        ${media.md(
          `left: ${between(2 * gridOffsetFrom, 2 * gridOffsetTo)};
            `
        )}
      `}

    ${({ rightOffset, fullWidthMobile }) =>
      rightOffset &&
      css`
        right: ${fullWidthMobile ? '0' : 2 * gridOffsetFrom}px;
        ${media.md(
          `right: ${between(2 * gridOffsetFrom, 2 * gridOffsetTo)};
            `
        )}
      `}
  }
`

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.spacing[2.5]} ${theme.spacing[4]}`};
  width: 100%;
  position: relative;
  z-index: 1;

  ${media.md(css`
    flex-direction: ${({ desktopDirection }: ContentProps) =>
      desktopDirection === 'left' ? 'row' : 'row-reverse'};
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => `${theme.spacing[5.5]} ${theme.spacing[4.5]}`};
  `)}
`

export const ContentFrame = styled.div`
  max-width: 458px;
`

export const ContentContainer = styled.div`
  padding: ${({ theme }) => `0 ${gridOffsetFrom}px ${theme.spacing[4]}`};
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 16px;
  ${media.md(css`
    padding: ${({ theme }) => `0 ${theme.spacing[4.5]} ${theme.spacing[5.5]}`};
    grid-gap: 40px;
  `)}
  ${media.mlg(css`
    grid-template-columns: 1fr 1fr 1fr;
  `)}
`

export const Media = styled.div`
  width: 100%;
  order: -1;
  ${media.md(css`
    order: initial;
    max-width: 50%;
  `)}
`
export const SideImage = styled(BackgroundImage)`
  background-size: cover;
  background-position: center;
`

export const ArrowWrap = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing[0.5]};
  transition: transform 300ms;
`

export const ButtonLink = styled(Button)`
  padding: ${({ theme }) => `${theme.spacing[0.5]} 0`};
  justify-content: flex-start;

  ${media.md(css`
    &:hover {
      ${ArrowWrap} {
        transform: translateX(${({ theme }) => theme.spacing[0.5]});
      }
    }
  `)}
`

export const FeatureCardWrapper = styled.div`
  height: 100%;
  padding: ${({ theme }) => theme.spacing[2.5]};
  background-color: ${colors.white};

  ${media.md(css`
    padding: ${({ theme }) => theme.spacing[2]};
  `)}
`
