import PropTypes from 'prop-types'

import { colors } from '../../constants/colors'
import { zIndex } from '../../constants/zIndex'
import { Amount } from '../../elements/Amount'
import { Type } from '../../elements/Typography/Typography'
import { Appear } from '../../units/Appear'
import { SmartImage } from '../../units/SmartImage/SmartImage'
import * as styles from './styles'

type ComparisonCardProps = {
  title: string
  subtitle?: string
  image?: any
  items?: any[]
  link?: any
  buttonText?: any
  isRetreat?: any
  text?: any
  textColor?: any
  backgroundImage?: any
  backgroundGradient?: any
  priceRef?: any
  priceFormat?: any
  onBookClick?: () => void
}

export const ComparisonCard = ({
  title,
  subtitle,
  image,
  items,
  link,
  buttonText,
  isRetreat,
  text,
  textColor,
  backgroundImage,
  backgroundGradient,
  priceRef,
  priceFormat,
  onBookClick,
}: ComparisonCardProps) => {
  return (
    <Appear styleChild>
      <styles.Container
        isRetreat={isRetreat}
        backgroundImage={backgroundImage}
        backgroundGradient={backgroundGradient}
        style={{ color: textColor === 'light' ? colors.white : colors.dark }}
      >
        {image && (
          <SmartImage image={image}>
            {imageUrl => (
              <styles.Image style={{ backgroundImage: `url(${imageUrl})` }} />
            )}
          </SmartImage>
        )}
        <styles.Content>
          <styles.Top>
            <Type preset="headlineSmall">{title}</Type>
            {priceRef && (
              <Type preset="text" bottom={{ xs: 0.5, md: 1 }}>
                <Amount
                  value={priceRef}
                  format={priceFormat}
                  fallback={subtitle}
                />
              </Type>
            )}
          </styles.Top>
          {text && (
            <Type
              preset="text"
              bottom={{ xs: 1.5 }}
              style={{ position: 'relative', zIndex: zIndex.above }}
            >
              {text}
            </Type>
          )}
          {items && (
            <styles.List>
              {items.map(item => (
                <styles.ListItem
                  key={item.item}
                  itemStyle={item.itemStyle}
                  color={textColor}
                >
                  {item.itemStyle === 'Title' ? (
                    <Type
                      preset="textSmall"
                      case="uppercase"
                      weight="bold"
                      top={{ xs: 0.5, md: 0.5 }}
                    >
                      {item.item}
                    </Type>
                  ) : (
                    <Type preset="textSmall">{item.item}</Type>
                  )}
                </styles.ListItem>
              ))}
            </styles.List>
          )}
        </styles.Content>
        {link && (
          <styles.Button
            paddingSize="large"
            size={{ xs: 14, md: 14 }}
            onClick={() => {
              if (onBookClick) {
                onBookClick()
              }
            }}
            to={link}
            preset={isRetreat ? 'dark' : 'blue'}
          >
            {buttonText}
          </styles.Button>
        )}
      </styles.Container>
    </Appear>
  )
}

ComparisonCard.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

ComparisonCard.defaultProps = {
  items: [],
}
