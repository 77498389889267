import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { TopicMarkdown } from 'bl-common/src/elements/TopicMarkdown'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ResponsiveParallax } from 'bl-common/src/units/ResponsiveParallax'
import { getImageUrl } from 'bl-common/src/units/SmartImage/getImageUrl'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

type ImageProps = {
  image?: string
  position?: string
}

type ImageContentProps = {
  degrees?: string
  color1?: string
  color2?: string
}

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`

const Image = styled(ResponsiveParallax)<ImageProps>`
  position: relative;
  width: calc(50% - 8px);
  background-image: ${props => props.image && `url(${props.image})`};
  background-position: ${props => props.position || 'center'};
  background-size: cover;

  &::before {
    content: '';
    display: block;
    z-index: ${zIndex.behind};
    padding-top: 150%;

    ${media.mlg(css`
      padding-top: 200%;
    `)};
  }

  &:nth-child(2) {
    top: 57px;
  }

  ${media.mlg(css`
    left: 0;
    width: calc(50% - 16px);
    &:nth-child(2) {
      top: ${({ theme }) => theme.spacing[8]};
    }
  `)};
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  ${media.mlg(css`
    flex-wrap: nowrap;
  `)};
`

const ImageContent = styled.div<ImageContentProps>`
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  justify-content: space-between;
  position: relative;

  &::before {
    content: '';
    opacity: 0.25;
    position: absolute;
    top: 0;
    transform: translateY(29px);
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding-bottom: 50%;
    width: ${mixins.span({ columns: 4, gutters: 3 })};
    ${props => css`
      background: linear-gradient(
        ${props.degrees || '-140deg'},
        ${props.color1 || colors.white},
        ${props.color2 || colors.white}
      );
    `};

    ${media.mlg(css`
      padding-bottom: 70%;
      transform: translateY(${({ theme }) => theme.spacing[4]});
    `)};
  }
`

const TextContent = styled.div`
  flex: 0 0 100%;
  ${media.mlg(css`
    margin-right: ${mixins.span({ columns: 1, gutters: 2 })};
    flex: 1 0 auto;
    width: ${mixins.span({ columns: 5, gutters: 4 })};
  `)};
`

export const TopicImagePillars = ({
  title,
  text,
  leftSideImage,
  rightSideImage,
  leftSideImagePosition,
  rightSideImagePosition,
  gradient,
}) => (
  <Container>
    <Content>
      <TextContent>
        <Type preset="headlineLarge" bottom={{ xs: 1, md: 2 }} top={4}>
          {title}
        </Type>
        <TopicMarkdown>{text}</TopicMarkdown>
      </TextContent>
      <ImageContent
        color1={gradient?.fields?.color1}
        color2={gradient?.fields?.color2}
        degrees={gradient?.fields?.degrees}
      >
        <Image
          image={!!leftSideImage && getImageUrl(leftSideImage, { height: 500 })}
          position={leftSideImagePosition}
          from={3}
          to={-2}
        />
        <Image
          image={
            !!rightSideImage && getImageUrl(rightSideImage, { height: 500 })
          }
          position={rightSideImagePosition}
          from={-3}
          to={2}
        />
      </ImageContent>
    </Content>
  </Container>
)
