import type { ISectionDuoImagePanel } from 'contentful-shared'
import { Appear } from 'bl-common/src/units/Appear'
import { DuoImagePanelSection as _DuoImagePanelSection } from 'bl-common/src/units/DuoImagePanelSection'
import { Section } from 'bl-common/src/units/Section/Section'

import { formatGradient } from '../../../utils/formatters'

type DuoImagePanelSectionProps = {
  section: ISectionDuoImagePanel
}

const DuoImagePanelSection = ({
  section: {
    fields: {
      config,
      backgroundGradient,
      textContent,
      landscapeImage,
      portraitImage,
      textContentSide = 'left',
    },
  },
}: DuoImagePanelSectionProps) => {
  const formattedGradient = formatGradient(backgroundGradient)

  return (
    <Appear>
      <Section config={config} hideOverflowX>
        <Appear observer>
          <_DuoImagePanelSection
            gradient={formattedGradient}
            textContent={textContent}
            landscapeImage={landscapeImage}
            portraitImage={portraitImage}
            textContentSide={textContentSide}
          />
        </Appear>
      </Section>
    </Appear>
  )
}

export default DuoImagePanelSection
