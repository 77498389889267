import axios from 'axios'

import { sentryLogging } from 'sentry-utils/logging'

type LocationInfoData = {
  city?: string
  continentCode?: string
  continentName?: string
  countryCode?: string
  countryName?: string
  ipAddress?: string
  stateProv?: string
}

const apiKey = process.env.NEXT_PUBLIC_DB_IP_API_KEY || 'free'
const url = `https://api.db-ip.com/v2/${apiKey}/self`

export const getUserLocationInfo = async () => {
  try {
    const locationInfo = await axios.get<LocationInfoData>(url)
    return locationInfo.data
  } catch (error) {
    sentryLogging({
      team: 'team-day-visit-svartsengi',
      error: new Error('Failed to fetch location info from db-ip'),
      extras: { error },
    })
    return null
  }
}
