import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import type { IGradient, ISectionIncludedListFields } from 'contentful-shared'

import { Button } from '../../elements/Button/Button'
import { Type } from '../../elements/Typography/Typography'
import { ButtonLink } from '../../richText/RichTextBlocks'
import { RichTextRenderer } from '../../richText/RichTextRenderer'
import * as styles from './styles'

const documentOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Type
        preset="headlineLarge"
        as="h2"
        bottom={{ xs: 1, md: 2.5 }}
        maxWidth={400}
      >
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Type preset="headline" as="h3" bottom={{ xs: 1, md: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => (
      <Type preset="headline" as="h4" bottom={{ xs: 1, md: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Type preset="headlineSmall" as="h5" bottom={{ xs: 1, md: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_6]: (_node, children) => (
      <Type preset="textSmall" as="p" bottom={{ xs: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Type preset="text" as="p" bottom={{ xs: 1 }}>
        {children}
      </Type>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => (
      <styles.UnorderedList>{children}</styles.UnorderedList>
    ),
    [BLOCKS.LIST_ITEM]: (_node, children) => (
      <styles.ListItem>{children}</styles.ListItem>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'ctaButton':
          return fields.displayAsLink ? (
            <ButtonLink
              textColor={fields.textColor}
              to={fields.link}
              emailTrigger={fields.emailTrigger}
              weight="bold"
              paddingSize="small"
            >
              {fields.text}
            </ButtonLink>
          ) : (
            <Button
              textColor={fields.textColor}
              paddingSize={fields.paddingSize}
              preset={fields.color}
              to={fields.link}
              maxWidth={373}
              top={{ xs: 1 }}
              emailTrigger={fields.emailTrigger}
            >
              {fields.text}
            </Button>
          )
      }
    },
  },
}

const formatGradient = (gradient: IGradient | undefined) =>
  gradient && {
    color1: gradient.fields.color1,
    color2: gradient.fields.color2,
    degrees: gradient.fields.degrees || '0deg',
    opacity: gradient.fields.opacity,
  }

export type FormatGradientType = ReturnType<typeof formatGradient>

type Props = { sectionIncludedList: ISectionIncludedListFields }

export const IncludedList = ({
  sectionIncludedList: {
    backgroundGradient,
    title,
    description,
    listTitle,
    list,
  },
}: Props) => {
  const formattedGradient = formatGradient(backgroundGradient)

  return (
    <>
      <styles.StyledContainer gradient={formattedGradient}>
        <div>
          <styles.Title>{title}</styles.Title>
          <styles.Description>{description}</styles.Description>
        </div>
        <styles.Card>
          <styles.ListTitle>{listTitle}</styles.ListTitle>

          {!!list && (
            <RichTextRenderer document={list} customOptions={documentOptions} />
          )}
        </styles.Card>
      </styles.StyledContainer>
    </>
  )
}
