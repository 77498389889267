import { addDays } from 'date-fns/addDays'
import { addMonths } from 'date-fns/addMonths'
import { differenceInDays } from 'date-fns/differenceInDays'
import { differenceInHours } from 'date-fns/differenceInHours'
import { endOfMonth } from 'date-fns/endOfMonth'
import { formatDuration } from 'date-fns/formatDuration'
import { isBefore } from 'date-fns/isBefore'
import { isToday } from 'date-fns/isToday'
import { parse } from 'date-fns/parse'

import { sentryLogging } from 'sentry-utils/logging'

export const dateUtilsLogPrefix = 'Date utils:'

const MONTH_COUNT = 12

export const INTERNAL_STRING_FORMAT = 'yyyy-MM-dd'

/**
 * Creates a new Date object representing the current date with the time set to 00:00:00.000 UTC,
 * but returns the date in the local timezone.
 *
 * This is useful when you want to create a Date object that represents "today" with the time
 * set to the start of the day (midnight) in the UTC timezone, while still reflecting the local
 * timezone offset.
 *
 * For example, if the current local time is 2024-06-11T15:23:00.000Z (UTC), this function will return
 * a Date object representing 2024-06-11T00:00:00.000Z in the local timezone.
 */
export const startOfCurrentUTCDate = () => {
  return new Date(new Date().setUTCHours(0, 0, 0, 0))
}

/**
 * Returns the UTC time for a Date in the format HH:mm.
 */
export const getUTCTimeForDate = (
  date?: Date | string | null,
  defaultTime = '00:00'
): string | undefined => {
  if (!date) return undefined

  const isTodaySelected =
    typeof date === 'string' ? isToday(new Date(date)) : isToday(date)

  return isTodaySelected
    ? `${String(new Date().getUTCHours()).padStart(2, '0')}:${String(
        new Date().getUTCMinutes()
      ).padStart(2, '0')}` // Current UTC time if today
    : defaultTime // Otherwise, default time
}

/**
 *
 * @param date string formatted as 'yyyy-MM-dd'
 * @param time string formatted as HH:mm
 * @returns ISO formatted string for the date and time. Example: 2024-07-07T20:00:00Z
 */

export const setDateTimeISOString = (date: string, time: string): string => {
  if (!date || !time) {
    return ''
  }

  const isoString = `${date}T${time}:00Z`
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/

  if (!isoRegex.test(isoString)) {
    sentryLogging({
      error: new Error(
        `${dateUtilsLogPrefix} Invalid ISO date string: ${isoString}`
      ),
      team: 'team-frontend-infrastructure',
    })
    return ''
  }

  return isoString
}

/**
 * Sets the time for a given date string and returns an ISO string with the specified time.
 * If no date is provided or the date is not a string, returns `undefined`.
 *
 * @param {string | null | undefined} date - The date in string format to which the time will be applied.
 * @param {string} [time='00:00'] - The time to set for the given date (default is '00:00').
 * @returns {string | undefined} The date with the specified time in ISO format, or `undefined` if the input date is invalid.
 */

export const setDateTimeForDate = (
  date?: string | null,
  time = '00:00'
): string | undefined => {
  return typeof date === 'string' ? setDateTimeISOString(date, time) : undefined
}

/**
 *
 * @param dateTime string formatted as yyyy-MM-ddTHH:mm:ss (+ optional ms and timezone)
 * @returns string on the format HH:mm
 */
export const getTimeFromDateTimeString = (
  dateTime: string,
  shouldReturnEmptyStringIfInvalid = true
): string => {
  // Regex that checks for the format yyyy-MM-ddTHH:mm or yyyy/MM/ddTHH:mm
  const dateRegex = /^\d{4}[-/]\d{2}[-/]\d{2}T\d{2}:\d{2}/
  // Check if the string is on the correct format
  if (!dateRegex.test(dateTime)) {
    return shouldReturnEmptyStringIfInvalid ? '' : dateTime
  }
  const timeInHours = dateTime.substring(11, 16)

  return timeInHours
}

/**
 *
 * @param date string formatted as {INTERNAL_STRING_FORMAT}
 */
export const parseDateString = (date: string) =>
  parse(date, INTERNAL_STRING_FORMAT, new Date())

/**
 * Check if the arrival date is before today, the function returns an array containing today's date and either tomorrow's date or the departure date, depending on whether the departure date is before or after tomorrow.
 * If the arrival date is today or later, the function returns an empty array.
 * If either the arrival date or departure date is not provided, an empty array is returned.
 * @param {Date} [arrivalDate] - The arrival date.
 * @param {Date} [departureDate] - The departure date.
 * @returns {Date[]} - An array of valid dates, or an empty array.
 */
export const getValidStayDates = (
  arrivalDate?: Date | string,
  departureDate?: Date | string
) => {
  if (!arrivalDate || !departureDate) {
    return []
  }

  const parsedArrivalDate =
    typeof arrivalDate === 'string' ? new Date(arrivalDate) : arrivalDate
  const parsedDepartureDate =
    typeof departureDate === 'string' ? new Date(departureDate) : departureDate

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const tomorrow = addDays(today, 1)
  if (isBefore(parsedArrivalDate, today)) {
    if (isBefore(parsedDepartureDate, tomorrow)) {
      return [today, tomorrow]
    } else {
      return [today, parsedDepartureDate]
    }
  }

  return []
}

/**
 * @param startDate Start Date
 * @returns end of the month that is MONTH_COUNT after start date month
 */
export const getEndDate = (startDate: Date): Date =>
  endOfMonth(addMonths(startDate, MONTH_COUNT))

/**
 * @param date  Target date
 * @param  delimiter used as a delimiter to format, f.x 'and' in 18 days and 2 hours.
 * @returns formatted days and hours until target date. Fx: '18 days and 2 hours'
 */
export const formatDaysAndHoursUntil = (date: Date, delimiter: string) => {
  const durationInDays = differenceInDays(date, new Date())
  const remainingHours = differenceInHours(date, new Date()) % 24

  const duration = formatDuration(
    {
      days: durationInDays,
      hours: remainingHours,
    },
    { delimiter: ` ${delimiter} ` }
  )

  return duration
}

/**
 * Validates and formats an ISO date string.
 *
 * @param dateTimeString string formatted as 'yyyy-MM-dd', 'yyyy-MM-ddThh:mm:ss', 'yyyy-MM-ddThh:mm:ss.mmm' or 'yyyy-MM-ddThh:mm:ssZ'
 * @returns Valid ISO formatted string or null if invalid
 */
export const validateAndFormatDateString = (
  dateTimeString: string
): string | null => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/
  const dateTimeWithoutTZRegex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?$/
  const dateTimeWithTZRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/
  const dateTimeWithOffsetRegex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?([+-]\d{2}:\d{2})$/

  if (dateTimeWithoutTZRegex.test(dateTimeString)) {
    return `${dateTimeString}Z`
  } else if (
    dateRegex.test(dateTimeString) ||
    dateTimeWithTZRegex.test(dateTimeString) ||
    dateTimeWithOffsetRegex.test(dateTimeString)
  ) {
    return dateTimeString
  } else {
    sentryLogging({
      error: new Error(
        `${dateUtilsLogPrefix} Invalid date string format: ${dateTimeString}`
      ),
      team: 'team-frontend-infrastructure',
    })
    return null
  }
}
