import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import {
  base,
  gridOffsetFrom,
  gridOffsetTo,
} from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import type { BreakpointMap, Gradient } from 'bl-common/src/types/custom'
import { between } from 'bl-common/src/utils/between'
import { mediaMaxObj, mediaObj } from 'bl-common/src/utils/media'

export const bookingBarHero = styled.header<{
  bottom?: BreakpointMap<number>
  offset?: number
}>(({ bottom = { xs: 2, md: 9 }, offset, theme }) => ({
  color: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  ...(bottom?.xs && { marginBottom: theme.spacing[bottom.xs] }),

  '.t-dark &': {
    color: colors.white,
  },

  [mediaObj.mlg]: {
    flexDirection: 'row',
    height: `calc(100vh - ${offset}px)`,
    overflow: 'hidden',
  },

  [mediaMaxObj.mlg]: {
    ...(bottom?.xs && { marginBottom: bottom.xs * base }),
  },
}))

export const left = styled.div(({ theme }) => ({
  display: 'grid',
  flexDirection: 'column',
  padding: `${theme.spacing[2.5]} ${gridOffsetFrom}px`,

  [mediaObj.md]: {
    rowGap: theme.spacing[6],
    paddingLeft: between(gridOffsetFrom, gridOffsetTo),
    paddingRight: between(gridOffsetFrom, gridOffsetTo),
  },

  [mediaObj.mlg]: {
    flex: '1 0 50vw',
    rowGap: 'unset',
    paddingTop: theme.spacing[8],
    paddingLeft: between(gridOffsetFrom, gridOffsetTo),
    paddingRight: between(gridOffsetFrom, gridOffsetTo),

    '@media (min-height: 700px)': {
      [text]: {
        alignSelf: 'center',
      },

      [bookingBarWrap]: {
        alignSelf: 'end',
      },
    },
  },
}))

export const right = styled.div<{ imageOverlay: 'dark' | 'light' }>(
  ({ imageOverlay = 'light' }) => ({
    flex: '1 0 50vh',
    order: -1,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',

    '::after': {
      background:
        imageOverlay === 'dark'
          ? 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)'
          : 'linear-gradient(180deg, #FFFFFF 0%, rgba(217, 217, 217, 0) 100%)',
      content: '""',
      height: 375,
      left: 0,
      opacity: 0.75,
      position: 'absolute',
      right: 0,
      top: 0,
    },

    [mediaObj.mlg]: {
      flex: '1 0 50vw',
      order: 1,
      width: 'auto',
    },
  })
)

export const text = styled.div({})

export const bookingBarWrap = styled.div({
  position: 'relative',
  flex: 1,
})

export const bookingBar = styled.div<{
  gradient?: Gradient
  background?: string
  offset?: number
}>(({ theme, gradient, background }) => ({
  background: 'white',
  bottom: 0,
  left: 0,
  position: 'fixed',
  right: 0,
  zIndex: zIndex.behindMenu,

  '.t-dark &': {
    color: colors.dark,
  },

  [mediaObj.md]: {
    position: 'relative',

    ...((gradient || background) && {
      '::before': {
        background: background
          ? colors[background]
          : `linear-gradient(
          ${gradient.degrees},
          ${gradient.color1},
          ${gradient.color2}
        );`,
        content: '""',
        position: 'absolute',
        inset: theme.spacing[-2.5],
        opacity: 0.9,
      },
    }),
  },

  [mediaObj.mlg]: {
    background: 'transparent',
    bottom: theme.spacing[4],
    display: 'block',
    flex: '1 0 50vw',
    left: 0,
    padding: 0,
    position: 'absolute',
    width: `calc(100vw - 2 * ${between(gridOffsetFrom, gridOffsetTo)})`,
  },
}))
