import { createContext, useContext } from 'react'

import type { IPageAboutMainFields } from 'contentful-shared'

interface SiteContext {
  language: string
  footer: any
  navigation: any
  metadata: any
  aboutUsNavigation: IPageAboutMainFields
}

export const SiteConfigContext = createContext<SiteContext>({
  language: 'en',
  footer: null,
  navigation: null,
  metadata: null,
  aboutUsNavigation: null,
})

export const SiteConfig = SiteConfigContext.Consumer

export const useSiteConfig = () => {
  const context = useContext(SiteConfigContext)

  if (context === undefined) {
    throw new Error('useSiteConfig must be used within a SiteConfigProvider')
  }

  return context
}
