import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { durations } from '../../constants/durations'
import { mixins } from '../../utils/mixins'
import { Link } from '../Link'

const SVG = styled.svg`
  display: block;
  margin-left: 1rem;
  flex: 0 0 12px;

  .no-js & {
    display: none;
  }
`

const Plus = ({ color = colors.fountainBlue }) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 24 24"
    height="12"
    width="12"
  >
    <path d="M23 10h-8.5c-.3 0-.5-.2-.5-.5V1c0-.6-.4-1-1-1h-2c-.6 0-1 .4-1 1v8.5c0 .3-.2.5-.5.5H1c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h8.5c.3 0 .5.2.5.5V23c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-8.5c0-.3.2-.5.5-.5H23c.6 0 1-.4 1-1v-2c0-.6-.4-1-1-1z" />
  </SVG>
)

const Minus = ({ color = colors.fountainBlue }) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    viewBox="0 0 24 24"
    height="12"
    width="12"
  >
    <path d="M24 11c0-.6-.4-1-1-1H1c-.6 0-1 .4-1 1v2c0 .6.4 1 1 1h22c.6 0 1-.4 1-1v-2z" />
  </SVG>
)

const OpenLinkWrapper = styled(({ isOpen, ...rest }) => <Link {...rest} />)`
  opacity: 0;
  transition: opacity ${durations.medium}ms;
  pointer-events: none;
  position: relative;
  &::before {
    ${mixins.increaseClickArea()};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      pointer-events: initial;
    `};
`

export const OpenLinkIcon = ({ isOpen, to, onClick }) => (
  <OpenLinkWrapper isOpen={isOpen} to={to} onClick={onClick}>
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      width="14"
      height="14"
      fill={colors.fountainBlue}
    >
      <path
        d="M11.5,0H7.1C6.8,0,6.5,0.2,6.5,0.5s0.2,0.5,0.5,0.5h3L4.5,6.7c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2
	c0.1,0,0.3-0.1,0.4-0.2l5.6-5.6v3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5V0.5C12,0.2,11.8,0,11.5,0z"
      />
      <path
        d="M9.3,5.5C9,5.5,8.7,5.7,8.7,6v4.9H1.1V3.3H6c0.3,0,0.5-0.2,0.5-0.5S6.3,2.2,6,2.2H0.5C0.2,2.2,0,2.4,0,2.7v8.7
	C0,11.8,0.2,12,0.5,12h8.7c0.3,0,0.5-0.2,0.5-0.5V6C9.8,5.7,9.6,5.5,9.3,5.5L9.3,5.5z"
      />
    </SVG>
  </OpenLinkWrapper>
)

export const Icon = ({ isActive, color = undefined }) => {
  return isActive ? <Minus color={color} /> : <Plus color={color} />
}
