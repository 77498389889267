import { createContext, useState } from 'react'

type BannerHeightContextType = {
  bannerHeight: number
  setBannerHeight: React.Dispatch<React.SetStateAction<number>>
}

export const BannerHeightContext = createContext<BannerHeightContextType>({
  bannerHeight: 0,
  setBannerHeight: () => {
    /* intentionally empty */
  },
})

export const BannerHeightContextProvider = ({ children }) => {
  const [bannerHeight, setBannerHeight] = useState(0)

  return (
    <BannerHeightContext.Provider value={{ bannerHeight, setBannerHeight }}>
      {children}
    </BannerHeightContext.Provider>
  )
}

export const Banner = BannerHeightContext.Consumer
