export const dispatchEvent = (
  element: HTMLElement | Window,
  eventName: string
) => {
  let event: Event
  if (typeof Event === 'function') {
    event = new Event(eventName)
  } else {
    event = document.createEvent('Event')
    event.initEvent(eventName, false, false)
  }
  element.dispatchEvent(event)
}
