import type { ISectionImageHeaderFields } from 'contentful-shared'
import { ImageHeader } from 'bl-common/src/units/ImageHeader/ImageHeader'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'

import { formatGradient } from '../../../utils/formatters'

type ImageHeaderSectionProps = {
  section: {
    fields: ISectionImageHeaderFields
  }
}

const ImageHeaderSection = ({
  section: {
    fields: {
      content,
      gradient,
      images,
      imagesWithFocalPoint,
      textContentSide,
      withDownArrow,
      config,
    },
  },
}: ImageHeaderSectionProps) => {
  return (
    <OffsetSection
      bottom={{ xs: 2, md: 10, lg: 14 }}
      top={{ xs: 2, md: 4 }}
      config={config}
      fullWidthMobile={true}
      spacingFromEdge={{ xs: 0, md: 1 }}
    >
      <ImageHeader
        images={images}
        imagesWithFocalPoint={imagesWithFocalPoint}
        gradient={formatGradient(gradient)}
        textContentSide={textContentSide}
        content={content}
        withDownArrow={withDownArrow}
      />
    </OffsetSection>
  )
}

export default ImageHeaderSection
