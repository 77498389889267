import { useEffect, useRef, useState } from 'react'
import { FocusOn } from 'react-focus-on'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@react-hookz/web'
import get from 'lodash.get'
import styled, { css } from 'styled-components'
import root from 'window-or-global'

import { zIndex } from 'bl-common/src/constants/zIndex'
import { CloseIcon } from 'bl-common/src/elements/Icons/CloseIcon'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ClientOnlyModal } from 'bl-common/src/units/ClientOnlyModal'
import { VisuallyHidden } from 'bl-common/src/units/VisuallyHidden'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

import { LeftChevron } from './LeftChevron'
import { MediaProgress } from './MediaProgress'
import { RightChevron } from './RightChevron'
import { useInterval } from './useInterval'

type VideoProps = {
  $hide?: any
  $minimize?: any
}

type MediaWrapProps = {
  isLoaded?: boolean
  isLoading?: boolean
}

type ArrowProps = {
  isVisible?: boolean
}

const StyledVideo = styled.video<VideoProps>`
  bottom: 0;
  display: block;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: ${zIndex.above};

  ${({ $hide }) =>
    $hide &&
    css`
      display: none;
    `}

  ${({ $minimize }) =>
    $minimize &&
    css`
      top: 0;
      left: 0;
      opacity: 0.1;
      right: 1px;
      bottom: 1px;
    `}
`

const StyledImage = styled.img`
  bottom: 0;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${zIndex.above};
`
const MediaOuter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  ${media.md(css`
    max-height: 960px;
    max-width: 540px;
    bottom: 24px;
    top: 24px;
  `)}
`

const MediaWrap = styled.div<MediaWrapProps>`
  background: ${({ isLoaded }) => (isLoaded ? 'transparent' : 'black')};
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  left: 0;
  margin: auto;
  padding: 32px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  width: 100%;

  &::after {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.2), transparent);
    bottom: 0;
    content: '';
    height: 134px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }
`

const ContentWrap = styled.div`
  z-index: ${zIndex.above2};
  position: relative;
  padding: 16px;
  margin-top: auto;
`

const TextContent = styled.div`
  margin-bottom: 18px;
`

const CloseButton = styled.button`
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  z-index: 2;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 32px;
  top: 32px;
  z-index: ${zIndex.newLayer};

  &::before {
    content: '';
    position: relative;
    ${mixins.increaseClickArea('50%')};
  }

  > svg {
    width: 12px;
    height: 12px;
  }
`

const ClickableOverlay = styled.button`
  bottom: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: ${zIndex.above2};
`

const ClickableOverlayRight = styled(ClickableOverlay)`
  right: 0;
`

const ClickableOverlayLeft = styled(ClickableOverlay)`
  left: 0;
`

const Arrow = styled.button<ArrowProps>`
  appareance: none;
  background: white;
  border: 0;
  bottom: 0;
  color: white;
  display: none;
  height: 42px;
  margin: auto;
  position: absolute;
  top: 0;
  width: 42px;
  cursor: pointer;

  ${({ isVisible }) =>
    isVisible &&
    media.md(css`
      display: block;
    `)}

  > svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`

const NextButton = styled(Arrow)`
  right: 0;
  transform: translateX(100%) translateX(20px);
`

const PrevButton = styled(Arrow)`
  left: 0;
  transform: translateX(-100%) translateX(-20px);
`

const IMAGE_DURATION = 4000
const IMAGE_TICK = 200

const Video = ({ media, onEnded, onCanPlay, setProgress, index }) => {
  const ref = useRef(null)
  const { height } = useWindowSize()

  useEffect(() => {
    if (!ref.current) {
      return
    }
    ref.current.style.height = `${height}px`
  }, [height])

  const shouldPlay = media.index === index
  const shouldPreload = media.index === index + 1

  // video is ready when we know its duration
  const [isReady, setReady] = useState(false)

  // next video should load
  useEffect(() => {
    if (shouldPreload) {
      ref.current.load()
    }
  }, [index])

  // current video should play
  useEffect(() => {
    if (!isReady) {
      return
    }

    if (shouldPlay) {
      // onCanPlay also sets media duration
      onCanPlay(ref.current.duration * 1000)
      // Restart video in case it had been started before
      ref.current.currentTime = 0
      // Then play it
      ref.current.play()
    }
  }, [index, isReady])

  const handleEnded = () => {
    if (shouldPlay) {
      onEnded()
    }
  }

  const handleProgressUpdate = () => {
    if (shouldPlay) {
      setProgress(ref.current.currentTime * 1000)
    }
  }

  return (
    <>
      <StyledVideo
        ref={ref}
        playsInline
        autoPlay
        preload="auto"
        onLoadedMetadata={() => setReady(true)}
        poster={media.poster}
        muted
        onTimeUpdate={handleProgressUpdate}
        onEnded={handleEnded}
        style={
          !shouldPlay && !shouldPreload
            ? { display: 'none' }
            : shouldPreload
              ? { zIndex: -10 }
              : {}
        }
      >
        <source
          src={media.index <= index + 1 ? media.src : ''}
          type="video/mp4"
        />
      </StyledVideo>
    </>
  )
}

const Image = ({ media, onEnded, onCanPlay, index, setProgress }) => {
  const shouldPlay = media.index === index
  const shouldPreload = media.index === index + 1
  const [progress, updateProgress] = useState(0)
  const [isReady, setReady] = useState(false)

  // fake progress
  useInterval(
    () => updateProgress(progress + IMAGE_TICK),
    shouldPlay ? IMAGE_TICK : null
  )

  useEffect(() => {
    if (!isReady) {
      return
    }

    if (shouldPlay) {
      onCanPlay(IMAGE_DURATION)
    }
  }, [index, isReady])

  useEffect(() => {
    if (progress >= IMAGE_DURATION) {
      onEnded()
    } else {
      setProgress(progress)
    }
  }, [progress])

  return (
    <StyledImage
      src={media.index <= index + 1 ? media.src : ''}
      onLoad={() => setReady(true)}
      style={
        !shouldPlay && !shouldPreload
          ? { display: 'none' }
          : shouldPreload
            ? { zIndex: -10 }
            : {}
      }
    />
  )
}

export const HighlightsPlayer = ({ show, onHide, highlights, name }) => {
  const { t } = useTranslation()
  const total = highlights.length

  const [index, setIndex] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [mediaProgress, setMediaProgress] = useState(0)
  const [mediaDuration, setMediaDuration] = useState(0)
  const [media, setMedia] = useState([])

  useEffect(() => {
    root.addEventListener('keydown', handleKeyDown)
    return () => {
      root.removeEventListener('keydown', handleKeyDown)
    }
  }, [index])

  useEffect(() => {
    setMedia(
      highlights.map((highlight, index) => {
        const mediaType = highlight.fields.media
          ? highlight.fields.media.fields.file.contentType
          : highlight.fields.file.url

        return {
          mediaType,
          src: highlight.fields.media.fields.file.url,
          poster: `${highlight.fields.poster.fields.file.url}?w=100`,
          index,
          id: highlight.sys.id,
          title: highlight.fields.title,
        }
      })
    )
  }, [])

  const handleGoForward = () => {
    setPlaying(false)
    if (index === media.length - 1) {
      // Current index is the maximum
      onHide()
    } else {
      // We can safely forward the index
      setIndex(index + 1)
      setMediaProgress(0)
    }
  }

  const handleGoBack = () => {
    const nextIndex = index - 1
    if (nextIndex !== -1) {
      setPlaying(false)
      setIndex(nextIndex)
    }
  }

  const onCanPlay = duration => {
    setMediaDuration(duration)
    setPlaying(true)
  }

  const handleKeyDown = e => {
    if (e.key === 'ArrowRight') {
      handleGoForward()
    }
    if (e.key === 'ArrowLeft') {
      handleGoBack()
    }
  }

  const title = get(highlights[index], 'fields.title', 'Blue Lagoon')

  if (!media[index]) {
    return null
  }

  return (
    <ClientOnlyModal show={show} onHide={onHide} noWrapper>
      <FocusOn onClickOutside={onHide}>
        <MediaOuter>
          <NextButton
            onClick={handleGoForward}
            isVisible={index + 1 !== media.length}
          >
            <RightChevron />
          </NextButton>
          <PrevButton onClick={handleGoBack} isVisible={index - 1 !== -1}>
            <LeftChevron />
          </PrevButton>
          <MediaWrap isLoading={false}>
            <CloseButton onClick={onHide}>
              <VisuallyHidden>{t('close')}</VisuallyHidden>
              <CloseIcon color="white" />
            </CloseButton>
            <ClickableOverlayRight onClick={handleGoForward} />
            <ClickableOverlayLeft onClick={handleGoBack} />
            <ContentWrap>
              <TextContent>
                <Type color="#FFF" size={{ xs: 16 }} weight="bold">
                  {name}
                </Type>
                <Type color="#FFF" size={{ xs: 14 }}>
                  {title}
                </Type>
              </TextContent>
              <MediaProgress
                current={index}
                total={total}
                isPlaying={playing}
                mediaDuration={mediaProgress}
              />
            </ContentWrap>
            {media.map(m =>
              m.mediaType.includes('image') ? (
                <Image
                  key={m.id}
                  media={m}
                  onEnded={handleGoForward}
                  onCanPlay={onCanPlay}
                  index={index}
                  setProgress={time => {
                    if (mediaDuration > 0) {
                      setMediaProgress(time / mediaDuration)
                    }
                  }}
                />
              ) : (
                <Video
                  key={m.id}
                  media={m}
                  onEnded={handleGoForward}
                  onCanPlay={onCanPlay}
                  index={index}
                  setProgress={time => {
                    if (mediaDuration > 0) {
                      setMediaProgress(time / mediaDuration)
                    }
                  }}
                />
              )
            )}
          </MediaWrap>
        </MediaOuter>
      </FocusOn>
    </ClientOnlyModal>
  )
}
