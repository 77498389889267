import { rgba } from 'polished'
import styled, { css, keyframes } from 'styled-components'

type SvgProps = {
  hideUntilAnimating?: boolean
  shouldAnimate?: boolean
}

const RotateKeyframes = keyframes`
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(540deg);
    }
`

const DashKeyframes = keyframes`
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
`

const Track = styled.circle``

const Path = styled.circle<{ color?: string }>`
  stroke: ${props => props.color ?? '#50a7ba'};
  stroke-linecap: round;
`

const Svg = styled.svg<SvgProps>`
  z-index: 2;
  width: ${({ width }) => `${width}px`};
  height: ${({ width }) => `${width}px`};

  ${({ hideUntilAnimating }) =>
    hideUntilAnimating &&
    css`
      opacity: 0;
    `}

  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      opacity: 1;
      animation: ${RotateKeyframes} 3s ease-in-out infinite;
      ${Path} {
        animation: ${DashKeyframes} 1s ease-in-out infinite;
      }
    `}
`

type SpinnerProps = {
  shouldAnimate: boolean
  radius?: number
  strokeWidth?: number
  hideUntilAnimating?: boolean
  color?: string
}

export const Spinner = ({
  shouldAnimate,
  radius = 20,
  strokeWidth = radius / 4,
  hideUntilAnimating = false,
  color = undefined,
}: SpinnerProps) => {
  const diameter = radius + strokeWidth

  return (
    <Svg
      viewBox={`0 0 ${diameter * 2} ${diameter * 2}`}
      shouldAnimate={shouldAnimate}
      hideUntilAnimating={hideUntilAnimating}
      width={radius * 2}
    >
      <Track
        cx={diameter}
        cy={diameter}
        r={radius}
        fill="none"
        stroke={color ? rgba(color, 0.2) : '#DEE9EF'}
        strokeWidth={strokeWidth}
      />
      <Path
        cx={diameter}
        cy={diameter}
        r={radius}
        fill="none"
        strokeWidth={strokeWidth}
        color={color}
      />
    </Svg>
  )
}
