import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line
import { format } from 'date-fns'
import { is } from 'date-fns/locale'

import { colors } from '../../constants/colors'
import { NavArrow } from '../../elements/Arrow/Arrow'
import { useInView } from '../../hooks/useInView'
import type { PartialBookingEngine } from '../../styles/types'
import { getMinAndMaxMonthsShown } from '../../utils/calendar'
import { DatePickerStyles } from './styles'

interface InlineDatePickerProps {
  onChange: (val: Date[]) => void
  startDate?: Date
  endDate?: Date
  minDate?: Date
  maxDate?: Date
  selectsRange?: boolean
  monthsShown: number
  loadMonthsOnScroll?: boolean
  openToDate?: Date
  dayClassName?: (date: Date) => string | undefined
  renderDayContents?: (day: number, date: Date) => JSX.Element
  themeStyle?: PartialBookingEngine['inlineDatePicker']
}

// HACK: This is a workaround for the datepicker not being able to handle UTC dates
// https://github.com/Hacker0x01/react-datepicker/issues/1787#issuecomment-770313939
const convertUTCToLocalDate = (date: Date | null) => {
  if (!date) {
    return date
  }
  // biome-ignore lint/style/noParameterAssign: This is a hack, I don't want to change
  date = new Date(date)
  // biome-ignore lint/style/noParameterAssign: This is a hack, I don't want to change
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date
}

const convertLocalToUTCDate = (date: Date | Date[] | null) => {
  if (!date) {
    return date
  }
  if (Array.isArray(date)) {
    return date.map(d => convertLocalToUTCDate(d))
  }
  // biome-ignore lint/style/noParameterAssign: This is a hack, I don't want to change
  date = new Date(date)
  // biome-ignore lint/style/noParameterAssign: This is a hack, I don't want to change
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date
}

export const InlineDatePicker = ({
  onChange,
  minDate,
  maxDate,
  startDate,
  endDate,
  selectsRange = false,
  monthsShown = 1,
  loadMonthsOnScroll = false,
  openToDate,
  dayClassName,
  renderDayContents: _renderDayContents = date => <>{date}</>,
  themeStyle,
}: InlineDatePickerProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  const _onChange = (value: Date | Date[]) => {
    if (Array.isArray(value)) {
      onChange?.(value)
    } else {
      onChange?.([value])
    }
  }

  const { minMonthsShown, maxMonthsShown } = getMinAndMaxMonthsShown(
    monthsShown,
    openToDate,
    endDate,
    maxDate
  )
  const [monthsShownCurrent, setMonthsShownCurrent] = useState(minMonthsShown)
  const { ref } = useInView({
    onChange: inView => {
      if (loadMonthsOnScroll && inView) {
        const isCloseToMax = monthsShownCurrent >= maxMonthsShown - 2
        setMonthsShownCurrent(monthsShownCurrent + (isCloseToMax ? 1 : 2))
      }
    },
  })
  // Poor man's unobserve, we don't want to load more months than we need
  const isObserving = monthsShownCurrent < maxMonthsShown
  // TODO: Revisit this, maybe create useFiniteScroll that takes in an unobserve condition

  return (
    <>
      <DatePickerStyles themeStyle={themeStyle} />
      <DatePicker
        startDate={convertUTCToLocalDate(startDate)}
        endDate={convertUTCToLocalDate(endDate)}
        selected={convertUTCToLocalDate(startDate)}
        onChange={date => _onChange(convertLocalToUTCDate(date))}
        locale={language}
        minDate={convertUTCToLocalDate(minDate)}
        maxDate={convertUTCToLocalDate(maxDate)}
        selectsRange={selectsRange as any} // TODO: Revert this type cast when react-datepicker has fixed this issue: https://github.com/Hacker0x01/react-datepicker/issues/5029
        selectsDisabledDaysInRange
        inline
        monthsShown={loadMonthsOnScroll ? monthsShownCurrent : monthsShown}
        openToDate={convertUTCToLocalDate(openToDate)}
        dayClassName={(date: Date) => {
          return dayClassName?.(date)
        }}
        renderDayContents={(day: number, date: Date) => {
          return _renderDayContents(day, date)
        }}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => {
          return (
            <div className="react-datepicker__navigation-wrapper">
              <button
                aria-label="Previous Month"
                className="react-datepicker__navigation--previousButton"
                onClick={decreaseMonth}
                type="button"
              >
                <NavArrow rotate={0} scale={1} color={colors.deepBlue} />
              </button>
              <span className="react-datepicker__current-month">
                {/* format is needed because the DatePicker is creating date in the local timezone
                    If we would use formatDateInUTC then the months would shift by one in a tz such as Kiritimati */}
                {format(monthDate, 'MMMM yyyy', {
                  locale: language === 'is' ? is : undefined,
                })}
              </span>
              <button
                aria-label="Next Month"
                className="react-datepicker__navigation--nextButton"
                onClick={increaseMonth}
                type="button"
              >
                <NavArrow rotate={180} scale={1} color={colors.deepBlue} />
              </button>
            </div>
          )
        }}
      />
      {loadMonthsOnScroll && isObserving && <div ref={ref} />}
    </>
  )
}
