import type { CSSProperties, FC, MouseEvent, ReactNode } from 'react'
import axios from 'axios'
import queryString from 'query-string'

import type { IEmailTrigger } from 'contentful-shared'

import { colors } from '../../constants/colors'
import type { TypeProps } from '../../elements/Typography/Typography'
import { Link } from '../Link'
import * as styles from './styles'

export type ButtonPreset = keyof typeof styles.buttonPresets
export type ButtonPaddingSize = keyof typeof styles.buttonPadding
export type ButtonTextColor = keyof typeof colors

export interface ButtonProps {
  children?: ReactNode
  preset?: ButtonPreset
  size?: TypeProps['size']
  to?: string
  noHover?: boolean
  weight?: TypeProps['weight']
  emailTrigger?: IEmailTrigger | undefined
  onClick?: (ev: MouseEvent | undefined) => void
  linkComponent?: JSX.Element
  textColor?: ButtonTextColor
  paddingSize?: ButtonPaddingSize
  [css: string]: unknown // TODO: this is allowing all any prop to be accepted
  type?: string
  disabled?: boolean | Promise<boolean>
  style?: CSSProperties
}

const handleEmailTrigger = (trigger: IEmailTrigger) => {
  const parsed = queryString.parse(window?.location?.search ?? '')
  const url = new URL(trigger.fields.path)

  Object.keys(parsed)
    .filter(key => trigger.fields.queryParameters?.includes(key))
    .forEach(key => {
      url.searchParams.append(key, parsed[key] as string)
    })

  try {
    axios.get(url.href)
  } catch (error) {
    console.log('Email trigger failed', error)
  }
}

export const Button: FC<ButtonProps> = ({
  preset = 'blue',
  size = { xs: 14, md: 16 },
  to,
  children,
  noHover = false,
  weight = 'bold',
  emailTrigger,
  onClick = () => {
    /* intentionally empty */
  },
  linkComponent = Link,
  textColor,
  paddingSize = 'medium',
  ...rest
}) => {
  const handleMouseMove = (
    event: MouseEvent & { target: HTMLButtonElement }
  ) => {
    event.target.style.setProperty('--x', `${event.nativeEvent.offsetX}px`)
    event.target.style.setProperty('--y', `${event.nativeEvent.offsetY}px`)
  }

  const handleClick = (ev: MouseEvent & { target: HTMLButtonElement }) => {
    if (emailTrigger) {
      handleEmailTrigger(emailTrigger)
    }

    onClick(ev)
  }

  const Component = noHover ? styles.BaseButton : styles.HoverButton

  return (
    <Component
      to={to}
      onMouseMove={!noHover ? handleMouseMove : undefined}
      preset={preset}
      paddingSize={paddingSize}
      innerComponent={linkComponent}
      onClick={handleClick}
      {...rest}
    >
      <styles.Text
        as="span"
        size={size}
        weight={weight}
        style={{ color: textColor ? colors[textColor] : 'inherit' }}
      >
        {children}
      </styles.Text>
    </Component>
  )
}
