import type { Entry } from 'contentful'

import type { ISectionComparisonCards20Fields } from 'contentful-shared'
import { Section } from 'bl-common/src/units/Section/Section'

import { ComparisonCards2 } from '../../ComparisonCards2'

type ComparisonCards2SectionProps = {
  section: Entry<ISectionComparisonCards20Fields>
}

const ComparisonCards2Section = ({
  section: {
    fields: { title, description, cards, gradient, config },
  },
}: ComparisonCards2SectionProps) => (
  <Section noHorizontalPadding config={config} hideOverflowX>
    <ComparisonCards2
      title={title}
      description={description}
      cards={cards}
      gradient={gradient}
    />
  </Section>
)

export default ComparisonCards2Section
