import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { SimpleAccordion } from '../booking/SimpleAccordion/SimpleAccordion'
import { breakpoints } from '../constants/breakpoints'
import { colors } from '../constants/colors'
import { Amount } from '../elements/Amount'
import { Button } from '../elements/Button/Button'
import { Link } from '../elements/Link'
import { Type } from '../elements/Typography/Typography'
import { LightBoxCollection } from '../units/LightBox/LightBoxCollection'
import { media } from '../utils/media'

type InfoColumnWrapperProps = {
  background?: string
}

type HyperLinkProps = {
  children?: React.ReactNode
  color?: string
  to?: string
  target?: string
  style?: React.CSSProperties
}

export const BulletList = styled.ul(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing[1],
  marginTop: theme.spacing[1],
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  p: {
    flex: 1,
    padding: 0,
  },

  [RichTextListItem]: {
    ':before': {
      content: '""',
      marginTop: `calc(${theme.spacing[0.5]} * 1.2)`,
      marginRight: theme.spacing[1],
      height: 6,
      width: 6,
      backgroundColor: 'var(--color-listMarker)',
      borderRadius: '50%',
      alignSelf: 'flex-start',
    },
  },

  [`@media (min-width: ${breakpoints.md}px)`]: {
    [RichTextListItem]: {
      ':before': {
        height: `calc(${theme.spacing[0.5]} * .9)`,
        width: `calc(${theme.spacing[0.5]} * .9)`,
        marginTop: `calc(${theme.spacing[0.5]} * 1.1)`,
      },
    },
  },
}))

export const MinimalList = styled.ol(({ theme }) => ({
  marginTop: theme.spacing[1],
  marginBottom: theme.spacing[2],
  marginRight: theme.spacing[1],
}))

export const RichTextListItem = styled.li({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
})

const InfoColumnWrapper = styled.div<InfoColumnWrapperProps>`
  background: ${props =>
    String(props.background).toLowerCase() === 'light'
      ? colors.lightGrey
      : colors.dark};
  color: ${props =>
    String(props.background).toLowerCase() === 'light'
      ? colors.dark
      : colors.white};
  flex: 0 0 100%;
  margin-bottom: ${({ theme }) => theme.spacing[2.5]};
  margin-top: ${({ theme }) => theme.spacing[2.5]};
  padding: ${({ theme }) => theme.spacing[2.5]};
  width: 100%;

  ${media.md(css`
    width: 70%;
  `)};
`

const AccordionWrapper = styled.div`
  :first-of-type {
    margin-top: ${({ theme }) => theme.spacing[2.5]};
  }

  :last-of-type {
    margin-bottom: ${({ theme }) => theme.spacing[2.5]};
  }

  & + & {
    padding-top: ${({ theme }) => theme.spacing[1.5]};
  }
`

const Prices = styled.dl`
  width: 100%;
  display: flex;
  margin: 0;
  padding: ${({ theme }) => theme.spacing[1.5]};

  &:first-of-type {
    margin-top: ${({ theme }) => theme.spacing[2.5]};
  }
  &:last-of-type {
    margin-bottom: ${({ theme }) => theme.spacing[2.5]};
  }

  &:nth-of-type(odd) {
    background: rgba(0, 0, 0, 0.03);
  }
`

const StyledLink = styled(Link)<Pick<HyperLinkProps, 'color'>>`
  cursor: pointer;
  font-weight: 800;
  color: ${({ color }) => color || `var(--color-link, ${colors.deepBlue})`};
`

const Key = styled.dt`
  flex: 0 1 auto;
  margin-right: 1rem;
  max-width: 70%;
`

const Value = styled.dd`
  text-align: right;
  margin-left: auto;
  flex: 1 0 auto;
`

const ListWrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing[2.5]};
  margin-bottom: ${({ theme }) => theme.spacing[2.5]};
`

const ListBlockItem = styled.li`
  padding: ${({ theme }) => theme.spacing[1.5]};
  &:nth-of-type(odd) {
    background: rgba(0, 0, 0, 0.03);
  }
`

const ListItemInner = styled.p`
  flex: 0 1 auto;
  margin-right: 1rem;
  max-width: 70%;
`

const ArrowWrapper = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing[0.5]};
  transition: transform 300ms;
`

const ButtonLinkWrapper = styled(Button)`
  padding: ${({ theme }) => `${theme.spacing[0.5]} 0`};
  justify-content: flex-start;
  overflow: visible;

  ${media.md(css`
    &:hover {
      ${ArrowWrapper} {
        transform: translateX(${({ theme }) => theme.spacing[0.5]});
      }
    }
  `)}
`

export const StyledTableWrapper = styled.div`
  overflow-x: auto;
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: ${({ theme }) => `${theme.spacing[2]} 0`};
  min-width: 700px;

  th,
  td {
    padding: ${({ theme }) => theme.spacing[1.5]};
    border-bottom: 1px solid var(--border-color);

    & a {
      display: inline-block;
      padding: 0;
      font-size: inherit;
    }
  }

  tr td:nth-child(even) {
    background: var(--color-tableRow);
  }

  th {
    text-align: left;
  }
`

export const InfoColumn = ({ background, title, text, lightbox }) => (
  <InfoColumnWrapper background={background}>
    {title && (
      <Type
        preset="label"
        weight="bold"
        bottom={{ xs: 0.5, md: 0.5 }}
        case="uppercase"
      >
        {title}
      </Type>
    )}
    <Type
      preset="label"
      weight="normal"
      multiline
      dangerouslySetInnerHTML={{
        __html: formatHtmlText(text),
      }}
    />
    {lightbox && (
      <LightBoxCollection images={get(lightbox, 'fields.images')}>
        {({ open }) => (
          <button onClick={open} style={{ cursor: 'pointer' }} type="button">
            <Type
              preset="label"
              case="uppercase"
              weight="medium"
              top={{ xs: 2.5, md: 2.5 }}
              style={{ color: colors.deepBlue }}
            >
              {get(lightbox, 'fields.label')}
            </Type>
          </button>
        )}
      </LightBoxCollection>
    )}
  </InfoColumnWrapper>
)

export const AccordionBlock = ({ label, content }) => (
  <AccordionWrapper>
    <SimpleAccordion label={label}>{content}</SimpleAccordion>
  </AccordionWrapper>
)

export const KeyValuePair = ({ pair }) => (
  <Prices key={pair.key}>
    <Key>{pair.key}</Key>
    <Value>
      <Amount value={pair.priceRef} fallback={pair.value} />
    </Value>
  </Prices>
)

export const List = ({ listItems }) => (
  <ListWrapper>
    {listItems.map(item => (
      <ListBlockItem key={item.fields.item}>
        <ListItemInner>{item.fields.item}</ListItemInner>
      </ListBlockItem>
    ))}
  </ListWrapper>
)

export const LightBoxLink = ({ images, label }) => (
  <LightBoxCollection images={images}>
    {({ open }) => (
      <button onClick={open} style={{ cursor: 'pointer' }} type="button">
        <Type
          preset="label"
          case="uppercase"
          weight="medium"
          top={{ xs: 2.5, md: 2.5 }}
          style={{ color: colors.deepBlue }}
        >
          {label}
        </Type>
      </button>
    )}
  </LightBoxCollection>
)

export const ButtonLink = ({ children, weight, ...rest }) => (
  <ButtonLinkWrapper
    {...rest}
    transparent
    noHover
    weight={weight}
    preset="transparent"
  >
    {children}
    <ArrowWrapper>→</ArrowWrapper>
  </ButtonLinkWrapper>
)

export const HyperLink = ({
  children,
  color,
  to,
  target,
  style,
}: HyperLinkProps) => (
  <StyledLink to={to} color={color} {...(target && { target })} style={style}>
    {children}
  </StyledLink>
)

export const Table = ({ children }) => (
  <StyledTableWrapper>
    <StyledTable>{children}</StyledTable>
  </StyledTableWrapper>
)
