import { useEffect, useMemo, useState } from 'react'
import Router from 'next/router'

import { PageStateContext } from 'bl-common/src/context/PageStateContext'
import { sessionStorage } from 'bl-utils/src/storage/sessionStorage'

import { NavigationEventsProvider } from '../components/NavigationEventsProvider'

const initialKey =
  typeof window === 'undefined' ? null : history.state?.options?.key

const fixState = key => {
  const newState = {
    ...history.state,
    options: {
      ...history.state.options,
      key,
    },
  }
  history.replaceState(newState, null)
}

const createKey = (N = 6) =>
  `${Math.random().toString(36)}00000000000000000`.slice(2, N + 2)

const useNavigationKey = appProps => {
  const [state] = useState({
    lastProps: appProps,
    key: initialKey || createKey(),
    newKey: null,
  })

  const didNavigate =
    state.lastProps.Component !== appProps.Component ||
    state.lastProps.pageProps !== appProps.pageProps

  useEffect(() => {
    const realChangeState = Router.router.changeState // eslint-disable-line @typescript-eslint/unbound-method
    Router.router.changeState = (method, url, as, options = {}) => {
      if (method === 'pushState' && !options.shallow) {
        // New page, create a new key.
        ;(options as any).key = state.newKey = createKey()
      } else if (!(options as any).key) {
        // shallow or replaceState, keep using existing key.
        ;(options as any).key = state.key
      } else if ((options as any).key !== state.key) {
        // Next is doing replaceState following a popState. The old key should
        // be passed in from the history state.
        state.newKey = (options as any).key
      }
      realChangeState.call(Router.router, method, url, as, options)
    }

    fixState(state.key)

    return () => {
      Router.router.changeState = realChangeState
    }
  }, [])

  if (didNavigate && state.newKey) {
    state.lastProps = appProps
    state.key = state.newKey
    state.newKey = null
  }

  return state.key
}

const withNavigationService = App => {
  const AppWrapper = appProps => {
    const key = useNavigationKey(appProps)

    const context = useMemo(
      () => ({
        key,
        getState(stateKey) {
          if (typeof window === 'undefined') {
            return null
          }
          const storageKey = `page:${key}:${stateKey}`
          const item = sessionStorage.getItem(storageKey)
          return item ? JSON.parse(item) : null
        },
        setState(stateKey, value) {
          const storageKey = `page:${key}:${stateKey}`
          return sessionStorage.setItem(storageKey, JSON.stringify(value))
        },
      }),
      [key]
    )

    if (appProps.pageProps) {
      appProps.pageProps.key = key
    }
    return (
      <NavigationEventsProvider>
        <PageStateContext.Provider value={context}>
          <App {...appProps} />
        </PageStateContext.Provider>
      </NavigationEventsProvider>
    )
  }
  AppWrapper.getInitialProps = async ctx => {
    return App.getInitialProps ? await App.getInitialProps(ctx) : {}
  }

  return AppWrapper
}

export { withNavigationService }
