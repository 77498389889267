// TODO: Move to web project?
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { ArrowLink } from '../elements/ArrowLink'
import { Type } from '../elements/Typography/Typography'
import { Appear } from '../units/Appear'
import { media } from '../utils/media'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${media.md(css`
    flex-direction: row;
  `)}
`

const Content = styled.div`
  flex: 0 0 40%;
  padding: 64px 40px;
  max-width: 800px;

  ${media.lg(css`
    padding: ${({ theme }) => `${theme.spacing[6]} ${theme.spacing[5.5]}`};
  `)}
`

const ArrowWrap = styled.div`
  flex: 0 0 40%;
  padding: 32px 64px;
  max-width: 800px;

  ${media.md(css`
    padding: 0;
    padding-top: ${({ theme }) => `${theme.spacing[2]}`};
  `)}
`

const ImageContainer = styled.div`
  flex: 1 0 60%;
  max-width: 1200px;
  padding-right: ${({ theme }) => theme.spacing[2.5]};
  transform: translateX(${({ theme }) => `${theme.spacing[-2.5]}`});

  ${media.md(css`
    padding: ${({ theme }) => `0px ${theme.spacing[5]}`};
    transform: none;
    margin-left: auto;
  `)}

  ${media.xl(css`
    max-width: 1200px;
  `)}
`

export const DownloadableImagePanel = ({
  title,
  description,
  image,
  imageLinkText = 'Download image',
}) => {
  return (
    <Container>
      <Content>
        <Appear observer>
          <Appear>
            <Type as="h2" preset="headlineLarge" bottom={{ xs: 1, md: 2 }}>
              {title}
            </Type>
            {description && (
              <Type preset="subtitle" multiline>
                {description}
              </Type>
            )}
          </Appear>
        </Appear>
      </Content>
      <ImageContainer>
        <img src={get(image, 'fields.file.url')} alt="Downloadable" />
        <ArrowWrap>
          <ArrowLink
            to={get(image, 'fields.file.url')}
            weight="bold"
            paddingSize="small"
            target="_blank"
          >
            {imageLinkText}
          </ArrowLink>
        </ArrowWrap>
      </ImageContainer>
    </Container>
  )
}
