import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import styled from 'styled-components'

import type { ISectionQuoteFields } from 'contentful-shared'

import { colors } from '../constants/colors'
import { mediaObj } from '../utils/media'
import { Type } from './../elements/Typography/Typography'
import { RichTextRenderer } from './../richText/RichTextRenderer'
import { Appear } from './Appear'

type QuoteProps = Pick<ISectionQuoteFields, 'quote' | 'source'>

const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type size={{ xs: 20, md: 32 }} mTop={{ xs: 1 }}>
        {children}
      </Type>
    ),
  },
}

const QuoteWrap = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '40px 1fr',
  rowGap: theme.spacing[1],
  position: 'relative',

  [mediaObj.md]: {
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: 'unset',
    columnGap: 40,
  },
}))

const QuotationMark = styled(Type)({
  gridColumn: '1 / -1',
  fontFamily: 'serif',
  display: 'block',

  [mediaObj.md]: {
    position: 'absolute',
    top: 0,
    gridColumn: '1 / span 1',
    transform: 'translateY(-20%)',
  },
})

const _Quote = styled.div({
  gridColumn: '1 / -1',
  paddingTop: 16,

  [mediaObj.md]: {
    paddingTop: 0,
    gridColumn: '2 / span 11',
  },
})

const Source = styled(Type)(({ theme }) => ({
  gridColumn: '1 / -1',
  position: 'relative',
  paddingLeft: `calc(${theme.spacing[2]} + ${theme.spacing[1]})`,
  display: 'flex',
  alignItems: 'center',

  '::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    width: theme.spacing[2],
    height: 2,
    background: colors.deepBlue,
  },

  [mediaObj.md]: {
    gridColumn: '2 / span 11',
  },
}))

export const Quote = ({ quote, source }: QuoteProps) => (
  <Appear observer>
    <Appear>
      <QuoteWrap>
        <QuotationMark size={{ xs: 64, md: 120 }} color="#52A5B8" as="span">
          “
        </QuotationMark>
        <_Quote>
          <RichTextRenderer document={quote} customOptions={options} />
        </_Quote>
        <Source preset="textLarge" weight="bold">
          {source}
        </Source>
      </QuoteWrap>
    </Appear>
  </Appear>
)
