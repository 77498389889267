import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import posthog, { type JsonType, type PostHogConfig } from 'posthog-js'

const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

type PosthogFeatureFlagPayload = {
  session_recording?: boolean
  allowedUrlSegments?: string[]
}

export const isPayloadObject = (
  payload: JsonType
): payload is PosthogFeatureFlagPayload => {
  return typeof payload === 'object' && payload !== null
}

export const useSessionRecording = (
  recordSession: boolean,
  allowedUrlSegments?: string[]
) => {
  const { asPath } = useRouter()

  useEffect(() => {
    if (
      recordSession &&
      (!allowedUrlSegments ||
        allowedUrlSegments.length === 0 ||
        allowedUrlSegments.some(segment => asPath.includes(segment)))
    ) {
      posthog.startSessionRecording()
      posthog.config.capture_pageview = true
    }
  }, [recordSession, allowedUrlSegments, asPath])
}

export const usePosthogPageview = (shouldTrack: boolean) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      if (shouldTrack) {
        posthog.capture('$pageview')
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [shouldTrack, router.events])
}

const isValidPersistenceType = (
  persistence?: string
): persistence is PostHogConfig['persistence'] => {
  const validTypes = [
    'localStorage',
    'cookie',
    'memory',
    'localStorage+cookie',
    'sessionStorage',
  ]
  return validTypes.includes(persistence)
}

const defaultPersistence = isProduction ? 'memory' : 'localStorage+cookie'

export const useInitPosthog = ({
  config,
  correlationId,
  persistence,
}: {
  config?: Partial<Omit<PostHogConfig, 'api_host' | 'persistence' | 'loaded'>>
  correlationId?: string
  persistence?: string
} = {}) => {
  const [sessionRecording, setSessionRecording] = useState<{
    enabled: boolean
    allowedUrlSegments: string[]
  }>({
    enabled: false,
    allowedUrlSegments: [],
  })
  const api_host = '/ingest' // Proxy through /ingest path. See next.config for rewrite

  if (typeof window !== 'undefined') {
    const validPersistence = isValidPersistenceType(persistence)
      ? persistence
      : defaultPersistence

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_PROJECT_KEY, {
      ...config,
      api_host,
      disable_session_recording: true,
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: false,
      // This makes posthog cookie-less in production so we do not have to add posthog to cookiehub
      // On feature deployments and locally we use localStorage to persist toolbar changes between loads
      persistence: validPersistence,
      loaded: posthog => {
        // Enable debug mode in development
        posthog.config.debug = Boolean(
          process.env.NEXT_PUBLIC_POSTHOG_DEBUG === 'true' && !isProduction
        )

        // Enable session recording if feature flag is enabled
        posthog.onFeatureFlags(() => {
          if (posthog.isFeatureEnabled('[ALL]session-recording')) {
            const sessionRecordingPayload = posthog.getFeatureFlagPayload(
              '[ALL]session-recording'
            )
            const allowedUrlSegments =
              isPayloadObject(sessionRecordingPayload) &&
              sessionRecordingPayload.allowedUrlSegments

            posthog.config.capture_pageview = true
            setSessionRecording({ enabled: true, allowedUrlSegments })
          }
        })
      },
    })

    if (correlationId) {
      posthog.register({ 'correlation-id': correlationId })
    }
  }

  useSessionRecording(
    sessionRecording.enabled,
    sessionRecording.allowedUrlSegments
  )

  const shouldTrackPageView = posthog.config.capture_pageview
  usePosthogPageview(shouldTrackPageView)

  return posthog
}
